// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// eslint-disable-next-line import/prefer-default-export
export const prodFirebaseConfig = {
  apiKey: 'AIzaSyDAsSbwb91Q3qtUTHY53hSLIDPPcibYseM',
  authDomain: 'trident-funding.firebaseapp.com',
  databaseURL: 'https://trident-funding-default-rtdb.firebaseio.com',
  projectId: 'trident-funding',
  storageBucket: 'trident-funding.appspot.com',
  messagingSenderId: '850567740544',
  appId: '1:850567740544:web:c390da6ab2217e0f61ba65',
  measurementId: 'G-SY0BN8P37K',
};

export const stageFirebaseConfig = {
  apiKey: 'AIzaSyAhpnobXSSI9RjZ2OsucV6FIISqzQONShE',
  authDomain: 'stage-trident.firebaseapp.com',
  projectId: 'stage-trident',
  storageBucket: 'stage-trident.appspot.com',
  messagingSenderId: '212581744217',
  appId: '1:212581744217:web:9412b4d3fd6b83bb3f3b48',
  measurementId: 'G-D6RHF04PBH',
};
