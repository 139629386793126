export const pushToDataLayer = (event: string, data: any) => {
  const dataLayer = (window as any).dataLayer || [];
  dataLayer.push({ event, ...data });
};

type StepMap = {
  [key: string]: string;
};

const stepMap: StepMap = {
  details: '01 loan details',
  collateral: '02 vehicle details',
  refinance: '03 refinance',
  seller: '04 seller info',
  trade: '05 trade in information',
  finance: '06 loan amount',
  borrower: '07 borrower id',
  contact: '08 borrower contact',
  address: '09 borrower address',
  'previous-address': '10 borrower previous address',
  'mailing-address': '11 borrower mailing address',
  income: '12 borrower income',
  assets: '13 assets',
  'real-estate': '14 real estate',
  'other-assets': '15 other assets',
  liabilities: '16 liabilities',
  'statement-summary': '17 financial statement summary',
  employer: '18 borrower employer',
  'previous-employer': '19 borrower previous employer',
  coborrower: '20 coborrower id',
  'coborrower-contact': '21 coborrower contact',
  'coborrower-address': '22 coborrower address',
  'coborrower-previous-address': '23 borrower previous address',
  'coborrower-employer': '24 coborrower employer',
  'coborrower-previous-employer': '25 coborrower previous employer',
  review: '26 review application',
  submit: '27 submit application',
};

export const getStepLabel = (step: string) => stepMap[step] || 'untracked step';
