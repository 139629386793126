import React from 'react';
import styles from '../loan.module.scss';
import { DEFAULT_LOGO_URL } from '../../constants';

type Props = {
  imgSrc: string;
  linkUrl: string;
  urlQueryParams: string;
};

function Logo({ imgSrc, linkUrl, urlQueryParams }: Props) {
  const href = `${linkUrl || 'https://www.tridentfunding.com'}${urlQueryParams}`;

  return (
    <>
      <a href={href} data-testid="logo">
        <img
          alt="logo"
          className={styles.logoMobile}
          src={imgSrc || DEFAULT_LOGO_URL}
          style={{
            height: 'auto',
          }}
          width="187"
        />
      </a>
    </>
  );
}

export default Logo;
