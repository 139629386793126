import { Collateral, collateralHasRVEngine, Config, LoanPurpose } from '../schema';

export function collateralMinYear(
  config: Config,
  purpose: LoanPurpose,
  fuel: Collateral['fuel'],
  type: Collateral['type']
): number {
  const currentYear = new Date().getFullYear();
  let numYearsLimit;

  if (purpose === LoanPurpose.rv) {
    if (collateralHasRVEngine(type)) {
      numYearsLimit = type && fuel && config.settings?.rv?.[type]?.[fuel]?.numYearsLimit;
    } else {
      numYearsLimit = type && config.settings?.rv?.[type]?.numYearsLimit;
    }
  } else {
    numYearsLimit = config.settings?.[purpose.toLowerCase()]?.numYearsLimit;
  }

  return currentYear - (numYearsLimit ?? 20);
}
