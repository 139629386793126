import React from 'react';
import styles from './SectionHeader.module.scss';

type Props = {
  header: HeaderProps;
};

export type HeaderProps = {
  title: String;
  subtitle: String;
  content: String | JSX.Element;
};

const SectionHeader: React.FC<Props> = (props) => {
  const { title, subtitle, content } = props.header;

  return (
    <div className={styles.sectionHeader}>
      <div className="title">{title}</div>
      <div className="subtitle">{subtitle}</div>
      <div className="content">{content}</div>
    </div>
  );
};

export default SectionHeader;
