import React from 'react';
import Cleave from 'cleave.js/react';

import 'cleave.js/dist/addons/cleave-phone.us';

type Props = {
  placeholder?: string;
  className?: string;
};

export function PhoneInput({ placeholder, className, ...props }: Props) {
  return (
    <Cleave
      inputMode="numeric"
      placeholder={placeholder}
      options={{
        delimiter: '-',
        phone: true,
        phoneRegionCode: 'US',
      }}
      className={className || 'ant-input'}
      {...props}
    />
  );
}
