import React from 'react';

type Props = {
  fill?: string;
  width?: string;
  height?: string;
};

const LockIcon: React.FC<Props> = ({ fill = '#000000', width = '1em', height = '1em' }) => (
  <svg
    version="1.1"
    enableBackground="new 0 0 12 14"
    width={width}
    height={height}
    viewBox="0 0 12 14"
    fill={fill}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.6665 4.08325C4.6665 2.79459 5.71117 1.74992 6.99984 1.74992C8.2885 1.74992 9.33317 2.79459 9.33317 4.08325V5.83325H4.6665V4.08325ZM3.49984 5.83325V4.08325C3.49984 2.15026 5.06684 0.583252 6.99984 0.583252C8.93283 0.583252 10.4998 2.15026 10.4998 4.08325V5.83325H11.0832C12.0497 5.83325 12.8332 6.61675 12.8332 7.58325V11.6666C12.8332 12.6331 12.0497 13.4166 11.0832 13.4166H2.9165C1.95001 13.4166 1.1665 12.6331 1.1665 11.6666V7.58325C1.1665 6.61675 1.95001 5.83325 2.9165 5.83325H3.49984ZM9.9165 6.99992H4.08317H2.9165C2.59434 6.99992 2.33317 7.26109 2.33317 7.58325V11.6666C2.33317 11.9888 2.59434 12.2499 2.9165 12.2499H11.0832C11.4053 12.2499 11.6665 11.9888 11.6665 11.6666V7.58325C11.6665 7.26109 11.4053 6.99992 11.0832 6.99992H9.9165Z"
    />
  </svg>
);

export default LockIcon;
