import { findFeatureFlagActive } from '../utils';
import { getABTestConfiguration } from '../utils/kameleoonHelper';

export type KameleoonFeatureFlag = string;

type UseFeatureFlagParams = {
  flag: KameleoonFeatureFlag;
  variation?: string;
};

/**
 * @deprecated Please avoid; use `useFeatureFlags` instead of this hook. *See `SellerStep.tsx` or `EmployerForm.tsx` for examples*
 */
export const useFeatureFlag = ({ flag, variation = 'on' }: UseFeatureFlagParams) => {
  const abTestConfiguration = getABTestConfiguration();
  const flagKey = findFeatureFlagActive(abTestConfiguration, flag);

  return flagKey?.variation === variation;
};
