import React, { useEffect, useState } from 'react';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import BlankLayout from '../BlankLayout';
import { useFirestore } from '../../hooks/useFirestore';
import { useUser } from '../../hooks/useUser';
import { Link } from 'react-router-dom';
import useConfig from '../../stores/config';

import styles from './MyApps.module.scss';
import { Loan } from '../../schema';
import { DEFAULT_SOURCE_ID, isProduction } from '../../constants';
import { pushToDataLayer } from '../../utils';

type Props = {};

const MyApps: React.FC<Props> = observer(function MyApps() {
  const [apps, setApps] = useState<Array<Loan> | null>(null);
  const user = useUser();
  const firestore = useFirestore();
  const config = useConfig();

  useEffect(() => {
    if (!user || !config) return;
    const query = firestore
      .collection('loans')
      .where('userId', '==', user?.uid)
      .orderBy('createdAt', 'desc');
    query.get().then((snapshot) => {
      const results: Array<Loan> = [];

      const validSources = config?.getValidSourcesFromLocation(window.location.href) || [];
      const validSourceIds = validSources.map((source) => source.id);

      if (validSourceIds.length === 0) {
        validSourceIds.push(DEFAULT_SOURCE_ID);
      }

      snapshot.forEach((doc) => {
        const loan = doc.data() as Loan;
        loan.id = doc.id;

        if (validSourceIds.includes(loan.source)) {
          results.push(loan);
        }
      });
      setApps(results);
    });
  }, [firestore, user, config]);

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: '“my applications',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  let content = null;
  if (apps === null || user === null) {
    content = <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />;
  } else if (!apps.length) {
    content = (
      <p style={{ textAlign: 'center' }}>
        None found! <Link to="/">Click here to start a new application.</Link>
      </p>
    );
  } else {
    content = (
      <table className={styles.table}>
        <thead>
          <tr>
            <th>ID</th>
            <th>Status</th>
            <th>Last Modified</th>
            <th>Link</th>
          </tr>
        </thead>
        <tbody>
          {apps.map((loan) => {
            const step = loan.workflow === 'submitted' ? 'success' : loan.currentStep;
            return (
              <tr key={loan.id}>
                <td>{loan.id}</td>
                <td>{loan.workflow || 'draft'}</td>
                <td>{loan.updatedAt ? new Date(loan.updatedAt).toLocaleString() : ''}</td>
                <td key={loan.id}>
                  <Link
                    to={`/${loan.id}/${step || 'details'}`}
                    onClick={() => {
                      localStorage.removeItem('loanId');
                    }}
                  >
                    Continue your {loan.purpose} loan
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  }

  return <BlankLayout title="Your Applications">{content}</BlankLayout>;
});

export default MyApps;
