import { computed } from 'mobx';
import { message } from 'antd';
import { PrequalStore, getPrequalStore } from './stores/prequalStore';
import useHistory from './hooks/useHistory';
import { getUrlCurrentStep } from './utils';
import { savePrequal } from './utils/prequalHelper';
import { Marketing, Prequal, VALID_UTM_PARAMS, ValidUTMUrlParams } from './schema';

export const ALL_ROUTES = [
  'purchase-timeframe',
  'boat-price',
  'down-payment',
  'about-you',
  'credit',
  'results',
] as const;

export type Route = typeof ALL_ROUTES[number];
type Dest = { to: Route; skip: SkipStep };

const states: { [index: string]: Array<Dest> } = {};

type SkipStep = (prequal: Prequal) => boolean;

// always allow this transition
const neverSkip = () => false;

const combineSkip = (...skips: Array<SkipStep>) => {
  const combined: SkipStep = (prequal) => {
    for (let i = 0; i < skips.length; i++) {
      if (skips[i].apply(this, [prequal])) return true;
    }
    return false;
  };
  return combined;
};

function transition(from: Route, dest: Dest) {
  if (states[from]) {
    states[from].push(dest);
  } else {
    states[from] = [dest];
  }
}

transition('purchase-timeframe', { to: 'boat-price', skip: neverSkip });
transition('boat-price', { to: 'down-payment', skip: neverSkip });
transition('down-payment', { to: 'about-you', skip: neverSkip });
transition('about-you', { to: 'credit', skip: neverSkip });
transition('credit', { to: 'results', skip: neverSkip });

export const requiredSteps = computed((): Route[] => {
  const prequalStore = getPrequalStore();
  if (!prequalStore || !prequalStore.prequal) return [];

  const steps: Route[] = ['purchase-timeframe'];
  const startSteps = Object.keys(states);

  for (let i = 0; i < startSteps.length; i++) {
    const dests = states[startSteps[i]];
    for (let j = 0; j < dests.length; j++) {
      const dest = dests[j];
      if (!dest.skip(prequalStore.prequal as Prequal)) {
        steps.push(dest.to);
      }
    }
  }
  return steps;
});

export function getNextStep(): Route {
  const prequalStore = getPrequalStore();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  if (!prequalStore || !prequalStore.prequal) {
    throw new Error("Loan hasn't loaded yet");
  }
  const steps = requiredSteps.get();
  const currentStep = (getUrlCurrentStep(history.location.pathname) ||
    'purchase-timeframe') as Route;
  if (prequalStore.navDirection === 'other') {
    throw new Error('other direction');
  }
  const nextIndex = steps.indexOf(currentStep) + prequalStore.navDirection;

  if (steps[nextIndex]) {
    return steps[nextIndex];
  }
  return 'purchase-timeframe';
}

export function buildMarketingUrlParams(prequalMarketingParams: Marketing) {
  let urlParams = '';

  if (!prequalMarketingParams) {
    return urlParams;
  }

  Object.keys(prequalMarketingParams).forEach((key) => {
    if (prequalMarketingParams[key as keyof Marketing]) {
      let urlParamName = key;

      if (key === 'campaignName') {
        urlParamName = 'campaign';
      }

      const value = prequalMarketingParams[key as keyof Marketing];
      urlParams += value ? `&${urlParamName.toLowerCase()}=${value}` : '';
    }
  });

  return urlParams;
}

export const convertSnakeCaseToCamelCase = (string: string) =>
  string
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

export function buildUtmParamsToInsert(prequalStore: PrequalStore) {
  const params = Array.from(VALID_UTM_PARAMS);
  const prequalUTM = prequalStore?.utm || null;
  let url = '';

  if (!prequalUTM) {
    return url;
  }

  params.forEach((paramInUrl) => {
    const keyInPreQualStore = convertSnakeCaseToCamelCase(paramInUrl);

    const value = prequalUTM[keyInPreQualStore as keyof PrequalStore['utm']];
    url += value ? `&${paramInUrl}=${value}` : '';
  });

  return url;
}

export function getParamsToAddToUrl(prequalStore: PrequalStore) {
  if (!prequalStore) {
    return '';
  }

  const subsource = prequalStore?.subsource ? `&subsource=${prequalStore?.subsource}` : '';
  const keyCode = prequalStore?.keyCode ? `&keycode=${prequalStore?.keyCode}` : '';
  const promoCode = prequalStore?.promoCode ? `&promocode=${prequalStore?.promoCode}` : '';
  const repId = prequalStore?.repCode ? `&repid=${prequalStore?.repCode}` : '';

  const urlWithUtmParams = buildUtmParamsToInsert(prequalStore);
  const urlWithMarketingParams = buildMarketingUrlParams(prequalStore?.marketing || {});

  return subsource + keyCode + promoCode + repId + urlWithUtmParams + urlWithMarketingParams;
}

export function gotoNextStep(history: any, nextStep: string) {
  const prequalStore = getPrequalStore();
  if (!prequalStore) return;

  const additionalUrlParams = getParamsToAddToUrl(prequalStore);

  const stepUrl = prequalStore.boatInfo
    ? `/pre-qualify/${nextStep}?source=${prequalStore.source}${additionalUrlParams}&boatInfo=${prequalStore.boatInfo}`
    : `/pre-qualify/${nextStep}?source=${prequalStore.source}${additionalUrlParams}`;
  history.push(stepUrl);
  window.scroll(0, 0);
}

export function getStepUrl(step: Route) {
  const prequalStore = getPrequalStore();
  if (!prequalStore) return '';

  const additionalUrlParams = getParamsToAddToUrl(prequalStore);

  const stepUrl = prequalStore.boatInfo
    ? `/pre-qualify/${step}?source=${prequalStore.source}${additionalUrlParams}&boatInfo=${prequalStore.boatInfo}`
    : `/pre-qualify/${step}?source=${prequalStore.source}${additionalUrlParams}`;
  return stepUrl;
}

export async function gotoStep(history: any, step: Route) {
  const prequalStore = getPrequalStore();
  if (!prequalStore) return;

  const additionalUrlParams = getParamsToAddToUrl(prequalStore);

  const stepUrl = prequalStore.boatInfo
    ? `/pre-qualify/${step}?source=${prequalStore.source}${additionalUrlParams}&boatInfo=${prequalStore.boatInfo}`
    : `/pre-qualify/${step}?source=${prequalStore.source}${additionalUrlParams}`;

  history.push(stepUrl);
  window.scroll(0, 0);
}

export async function stepFinished(nextStep?: Route) {
  const prequalStore = getPrequalStore();
  if (!prequalStore) return;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();

    if (prequalStore.navDirection === 'other') {
      return;
    }

    nextStep = nextStep || getNextStep();
    savePrequal(prequalStore.prequal as Prequal);
    gotoNextStep(history, nextStep);
  } catch (e: any) {
    message.error(`Save failed: ${e.message || 'unknown error'}`);
    console.error('failed', e);
  }
}
