import React, { lazy, Suspense, useEffect } from 'react';
import { Spin } from 'antd';
import { Route, Router, Switch } from 'react-router-dom';
import useHistory from './hooks/useHistory';
import LoanIndex from './loan';
import LogoutStep from './loan/components/LogoutStep';
import MyApps from './loan/components/MyApps';
import { observer } from 'mobx-react-lite';
import PartnerLoan from './loan/components/PartnerLoan/PartnerLoan';
import PreQualFunnelIndex from './prequal';

import { gotoStep, Route as StepRoute } from './nav';

type Props = {
  basePath?: string;
  transtionsLoaded?: boolean;
};

const Admin = lazy(() => import(/* webpackChunkName: "admin" */ './admin/index'));

export default observer(function Routes({ basePath = '/apply', transtionsLoaded = false }: Props) {
  const history = useHistory(basePath);

  if (!window.location.pathname.includes(basePath)) {
    // Using history will prefix the pathname with the basePath
    history.push(`${window.location.pathname}${window.location.search}`);
  }

  useEffect(() => {
    const w: any = window;
    w.TF = w.TF || {};
    // add some navigation utilities for test
    w.TF.gotoStep = (step: StepRoute) => gotoStep(history, step);
    w.TF.history = history;
  }, [history]);

  return (
    <Router history={history}>
      <Switch>
        <Route path="/admin">
          <Suspense fallback={<Spin />}>
            <Admin />
          </Suspense>
        </Route>
        <Route path="/logout">
          <LogoutStep />
        </Route>
        <Route path="/profile/apps">
          <MyApps />
        </Route>
        <Route path="/partner-loan/:partnerLoanId">
          <PartnerLoan />
        </Route>
        <Route path="/pre-qualify/*" exact>
          <PreQualFunnelIndex />
        </Route>
        <Route path="/">
          <LoanIndex transtionsLoaded={transtionsLoaded} />
        </Route>
      </Switch>
    </Router>
  );
});
