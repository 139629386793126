import firebase from 'firebase/compat/app';

export const sendDataToSFMC = async ({
  externalKey,
  primaryKey,
  payload,
}: {
  externalKey: string;
  primaryKey: string;
  payload: any;
}): Promise<any> => {
  const functions = firebase.functions();
  const pushToSFMCDataExtension = functions.httpsCallable('pushToSFMCDataExtension');
  await pushToSFMCDataExtension({ externalKey, primaryKey, payload });
};
