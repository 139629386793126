import React, { useEffect, useState } from 'react';
import { Button, Col, Dropdown, Layout, Row, Space, Spin, Typography } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { Route } from '../nav';
import { useLoan } from '../stores/store';
import { useUser } from '../hooks/useUser';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import styles from './loan.module.scss';
import { ProfileDropdownMenu } from './components/ProfileDropdownMenu';
import { getUrlCurrentStep, generateUrlParams, unloadPrequalOverrides } from '../utils';
import { AppFooter } from './components/AppFooter/AppFooter';
import LockIcon from '../icons/LockIcon';
import { useHistory, useParams } from 'react-router-dom';
import useConfig from '../stores/config';
import { useTheme } from '../hooks/useTheme';
import GoodSamDisclaimer from './components/GoodSamDisclaimer';
import ProgressBar from './components/ProgressBar/ProgressBar';
import Logo from './components/Logo';
import { LoanPurpose } from '../schema';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../constants';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default observer(function LoanLayout({ children, className }: Props) {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const user = useUser();
  const config = useConfig();
  const history = useHistory();
  let urlParams: any = {};
  const urlQueryParams = generateUrlParams(loanStore.loan);
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  const excludedSteps = isNewFunnel ? ['', 'type'] : ['', 'details', 'type'];
  const shouldShowProgressBar = !excludedSteps.includes(
    getUrlCurrentStep(history.location.pathname) as Route
  );

  try {
    // this errors if there's no match
    // eslint-disable-next-line react-hooks/rules-of-hooks
    urlParams = useParams();
  } catch (e) {
    // don't need this
  }

  const theme = useTheme(loanStore, config, urlParams.loanId == null);

  const [loanId] = useState(loan?.id); // To prevent the loan id to pop in

  if (loanId && window.location.href.indexOf(loanId) < 0) {
    window.location.reload();
  }

  if (loanStore.status === 'not_found') {
    loanStore.setStatus('init');

    const authenticated = user && !user.isAnonymous;
    if (authenticated && !loanStore.userIsAdmin) {
      history.push('/profile/apps');
    } else if (!authenticated) {
      loanStore
        .showAuthModal(true)
        .then(() => {
          // The user has logged in so display the contents
          history.go(0);
        })
        .catch(() => {
          // The user cancelled the authentication so start at the beginning
          const refinedParams = new URLSearchParams(urlQueryParams);
          refinedParams.delete('loanId');
          refinedParams.delete('currentStep');
          history.push(`/?canceledLogin=true&${refinedParams}`);
        });
    } else {
      history.push('/notFound');
    }
  }

  if (theme == null) {
    return (
      <Layout
        className={classNames(styles.mainLayout, {
          [styles.onlyForBoat]: loan?.purpose === LoanPurpose.boat,
          [styles.newFunnelV1]: isNewFunnel,
        })}
      >
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Layout>
    );
  }

  unloadPrequalOverrides(theme, loan);

  return (
    <Layout
      className={classNames(styles.mainLayout, {
        [styles.onlyForBoat]: loan?.purpose === LoanPurpose.boat,
        [styles.newFunnelV1]: isNewFunnel,
      })}
    >
      <Header className={styles.header}>
        <Row className={styles.headerRow}>
          <Col>
            <Logo
              imgSrc={isNewFunnel ? '/static/trident/boattrader-trident-logo-blue.png' : theme.logo}
              linkUrl={theme.marketingWebsiteUrl}
              urlQueryParams={urlQueryParams}
            />
          </Col>
          <Col flex="auto" className={styles.title}>
            <span className={styles.secured} style={{ display: 'none' }}>
              <LockIcon height="1.1em" width="1.1em" />
              Secured Connection
            </span>
          </Col>
          <Col>
            {!user ||
              (user?.isAnonymous && (
                <Button
                  type="default"
                  onClick={() => loanStore.showAuthModal(true)}
                  className="btn-login"
                >
                  Login
                </Button>
              ))}
            {!user?.isAnonymous && (
              <Dropdown overlay={<ProfileDropdownMenu />}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a className="ant-dropdown-link btn-user" onClick={(e) => e.preventDefault()}>
                  <UserOutlined style={{ fontSize: '1.5rem' }} /> {user?.displayName}{' '}
                  <DownOutlined />
                </a>
              </Dropdown>
            )}
          </Col>
        </Row>
        {shouldShowProgressBar && <ProgressBar />}
      </Header>
      <Content className={classNames(styles.content, className)}>
        <div className="form-container">{children}</div>
        {loanId && loan?.purpose !== LoanPurpose.boat && (
          <Row className={styles.applicationId}>
            <p>
              <b>Your Application ID: </b> {loanId}
            </p>
          </Row>
        )}
      </Content>
      <GoodSamDisclaimer />
      <AppFooter isNewFunnel={isNewFunnel} />
    </Layout>
  );
});
