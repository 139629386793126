import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { z } from 'zod';
import { useForm } from 'antd/es/form/Form';
import { stepFinished } from '../../prequalNav';
import { usePrequal } from '../../stores/prequalStore';
import { pushToDataLayer, formatEpochDate } from '../../utils';
import { sendDataToSFMC } from '../../utils/sendDataToSFMC';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import TextInput from '../components/TextInput/TextInput';
import PhoneInput from '../components/PhoneInput/PhoneInput';
import FooterCard from '../components/FooterCard/FooterCard';
import { isProduction } from '../../constants';
import { PHONE_NUMBER_REGEX, Prequal } from '../../schema';
import styles from './AboutYouStep.module.scss';
import { emailValidator } from '../../validators/emailValidator';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const AboutYouStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [form] = useForm();

  const formatSFMCPayload = () => {
    const payload = Object.fromEntries(
      Object.entries({
        'customer-email': prequal.email,
        'approval-status': prequal.prequalApprovalStatus,
        'application-status': prequal.fullApplicationStatus,
        startedAt: prequal.createdAt ? formatEpochDate(prequal.createdAt) : undefined,
        completedAt: prequal.completedAt ? formatEpochDate(prequal.completedAt) : undefined,
        'boat-make': prequal.boatMake,
        'boat-model': prequal.boatModel,
        'seller-name': prequal.sellerName,
        'seller-email': prequal.sellerEmail,
        'seller-party-id': prequal.partyId,
        'boat-imt-id': prequal.imtId,
        'seller-type': prequal.sellerType,
      }).filter(([, v]) => v !== null && v !== undefined)
    );

    return payload;
  };

  const update = (values: any) => {
    prequalStore.setAboutYou(values);
    sendDataToSFMC({
      externalKey: isProduction() ? 'TRIDENT-Prequal-PRODUCTION' : 'TRIDENT-Prequal-QA',
      primaryKey: `prequal-id:${prequal.prequalApplicationId}`,
      payload: formatSFMCPayload(),
    });
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'About you - Continue',
    });
    stepFinished();
  };

  const checkValues = (changed: any, values: any) => {
    const { firstName, lastName, email, phone } = values;
    const phoneLength = phone?.length ?? 0;

    if (!firstName || !lastName || !email) {
      setCanContinue(false);
    } else {
      setCanContinue(true);
    }
  };

  const validatePhone = async (_: any, value: any) => {
    const isValid = PHONE_NUMBER_REGEX.test(value);
    const isEmpty = (value?.length ?? 0) === 0;

    if (!isValid && !isEmpty) {
      setCanContinue(false);
      throw new Error('Phone number is not valid');
    }
  };

  const validator = async (rule: any, value: any) => {
    if (!value) {
      setCanContinue(false);
      throw new Error('This field is required');
    }
    if (rule.field === 'email') {
      try {
        const emailSchema = z.string().email().refine(emailValidator);
        emailSchema.parse(value);
      } catch {
        setCanContinue(false);
        throw new Error('Email is not valid');
      }
    }
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'about you',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    checkValues(null, values);
  }, [prequal, form]);

  const header = {
    title: 'About you',
    subtitle: 'Tell us a little about you',
    content: '',
  };

  return (
    <PreQualFunnelLayout>
      <SectionHeader header={header} />
      <Form
        {...formItemLayout}
        form={form}
        initialValues={prequal}
        onFinish={update}
        onValuesChange={checkValues}
      >
        <div className={styles.aboutYouContainer}>
          <div className={styles.aboutYouTextItem}>
            <TextInput labelText="First Name" name="firstName" validator={validator} />
          </div>
          <div className={styles.aboutYouTextItem}>
            <TextInput labelText="Last Name" name="lastName" validator={validator} />
          </div>
          <div className={styles.aboutYouTextItem}>
            <TextInput labelText="Email" name="email" validator={validator} />
          </div>
          <div className={styles.aboutYouTextItem}>
            <PhoneInput labelText="Phone" name="phone" validator={validatePhone} />
          </div>
        </div>

        <NavigationRow canContinue={canContinue} form={form} />
      </Form>

      <FooterCard showFirstItem />
    </PreQualFunnelLayout>
  );
});

export default AboutYouStep;
