export class FeatureFlag {
  /**
   * @description The Kameleoon flag name as it appears in the Kameleoon dashboard
   */
  name: string;

  /**
   * @description The list of possible active variations for the flag (e.g. 'on', 'v1', 'v2' etc.)
   */
  variations: string[];

  /**
   * @description The current variation for the flag
   */
  currentVariation?: string;

  constructor(name: string, variations: string[], currentVariation?: string) {
    this.name = name;
    this.variations = variations;
    this.currentVariation = currentVariation;
  }

  setCurrentVariation(currentVariation?: string) {
    this.currentVariation = currentVariation;
  }

  isActive(value: string) {
    return this.variations.includes(value);
  }
}

export const FullAppOptimizeV1Flag = new FeatureFlag('ps-625-trident-fullapplication-optimizev1', [
  'on',
  'v2',
]);
export const FullAppStylingFlag = new FeatureFlag('ps-625-trident-fullapplication-new-styling', [
  'on',
]);

export const ALL_FEATURE_FLAGS = [FullAppOptimizeV1Flag, FullAppStylingFlag];
