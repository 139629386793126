import React, { useEffect } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import EmployerForm from '../components/EmployerForm';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import { isProduction } from '../../constants';
import { pushToDataLayer } from '../../utils';

type Props = {};

const PreviousEmployerStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'previous employer page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const employer = loanStore.getPreviousEmployer();

  const update = (values: any) => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      <h1>Your Previous Employment</h1>
      <Form form={form} onFinish={update} initialValues={employer}>
        <EmployerForm employer={employer} form={form} />

        <ButtonNav />
      </Form>
    </LoanLayout>
  );
});

export default PreviousEmployerStep;
