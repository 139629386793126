import React from 'react';
import { Button, Col, Dropdown, Layout, Row, Spin } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useUser } from '../hooks/useUser';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import styles from './loan.module.scss';
import { ProfileDropdownMenu } from './components/ProfileDropdownMenu';
import { AppFooter } from './components/AppFooter/AppFooter';
import { useLoan } from '../stores/store';
import useConfig from '../stores/config';
import { useParams } from 'react-router-dom';
import { useTheme } from '../hooks/useTheme';
import { generateUrlParams } from '../utils/generateUrlParams';
import Logo from './components/Logo';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING } from '../constants';

type Props = {
  children: React.ReactNode;
  title?: string;
  className?: string;
  showLogin?: boolean;
};

export default observer(function BlankLayout({
  children,
  className,
  title,
  showLogin = true,
}: Props) {
  const user = useUser();
  const loanStore = useLoan();
  const config = useConfig();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });
  let urlParams: any = {};
  const urlQueryParams = generateUrlParams(loanStore.loan);

  try {
    // this errors if there's no match
    // eslint-disable-next-line react-hooks/rules-of-hooks
    urlParams = useParams();
  } catch (e) {
    // don't need this
  }

  const theme = useTheme(loanStore, config, urlParams.loanId == null);

  if (theme == null) {
    return (
      <Layout className={styles.mainLayout}>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Layout>
    );
  }

  return (
    <Layout
      className={classNames(styles.mainLayout, {
        [styles.newFunnelV1]: isNewFunnel,
      })}
    >
      <Header className={styles.header}>
        <Row className={styles.headerRow}>
          <Col>
            <Logo
              imgSrc={isNewFunnel ? '/static/trident/boattrader-trident-logo-blue.png' : theme.logo}
              linkUrl={theme.marketingWebsiteUrl}
              urlQueryParams={urlQueryParams}
            />
          </Col>
          <Col flex="auto" className={styles.title}>
            {title || ''}
          </Col>
          <Col>
            {(user === null || user?.isAnonymous) && showLogin && (
              <Button
                type="default"
                className="btn-login"
                onClick={() => loanStore.showAuthModal()}
              >
                Login
              </Button>
            )}
            {user !== null && !user?.isAnonymous && (
              <Dropdown overlay={<ProfileDropdownMenu />}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <a className="ant-dropdown-link btn-user" onClick={(e) => e.preventDefault()}>
                  <UserOutlined style={{ fontSize: '1.5rem' }} /> {user?.displayName}{' '}
                  <DownOutlined />
                </a>
              </Dropdown>
            )}
          </Col>
        </Row>
      </Header>
      <Content className={classNames(styles.content, className)}>
        <div className="form-container">{children}</div>
      </Content>
      <AppFooter />
    </Layout>
  );
});
