import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { useLoan } from '../../stores/store';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import AppFormItem from './AppFormItem';
import { LoanPurpose, AddOnService } from '../../schema';
import styles from '../steps/FinanceStep.module.scss';

export const AddOnServices = () => {
  const loanStore = useLoan();
  const addOnServices = loanStore.getServices();
  const { loan } = loanStore;

  const serviceItemsRV: { label: string; value: string; text: string }[] = [
    {
      label: 'Extended Service Plan',
      value: AddOnService.extendedServicePlan,
      text: 'An extended service plan pays for parts and labor of unexpected mechanical/electrical failures on all covered RV components.',
    },
    {
      label: 'RV Road Hazard Wheel & Tire',
      value: AddOnService.roadHazard,
      text: 'Tire & Wheel protection covers everything for your RV’s tires and wheels. This plan includes flat tire repair, mounting & balancing, wheel replacement, and extended coverage for connected tow vehicles.',
    },
    {
      label: 'Unlimited RV Roadside Assistance',
      value: AddOnService.roadsideAssistance,
      text: 'SafeRide RV Motor Club’s membership includes emergency roadside assistance and concierge services that you can use both on and off the road including battery & charging, jump starts, lost keys, locked out, a 24/7 master mechanic support line, and more.',
    },
    {
      label: 'GAP Protection',
      value: AddOnService.gapInsurance,
      text: 'GAP DEFENSE™ provides coverage in the event of a total loss due to accident or theft. Coverage up to 120 months for GAP Terms on RVs including motorhome, Class A, B, C, 5th wheel, travel trailer, pop-up camper, trailer, and slide-in. Coverage up to RVs 20 years old (current year minus 19 years prior). Coverage also pays your deductible up to $1000 for totaled units.',
    },
  ];

  const serviceItemsMarine: { label: string; value: string; text: string }[] = [
    {
      label: 'Extended Service Plan',
      value: AddOnService.extendedServicePlan,
      text: 'An extended service plan pays for parts and labor of unexpected mechanical/electrical failures on all covered boat components.',
    },
    {
      label: 'Marine Road Hazard Wheel & Tire',
      value: AddOnService.roadHazard,
      text: 'Tire & Wheel protection covers everything for your boat trailer’s tires and wheels. This plan includes flat tire repair, mounting & balancing, wheel replacement, and extended coverage for connected tow vehicles.',
    },
    {
      label: 'Unlimited Roadside Assistance',
      value: AddOnService.roadsideAssistance,
      text: 'SafeRide Motor Club’s membership covers anything you drive (car, ATV, RV, etc.) and covers anybody in your household.',
    },
    {
      label: 'GAP Protection',
      value: AddOnService.gapInsurance,
      text: 'GAP DEFENSE™ provides coverage in the event of a total loss due to accident or theft. Coverage up to 120 months for GAP Terms on Marine vessels. Coverage on boats 20 years old (current year minus 19 years prior).',
    },
  ];

  const serviceItems: { label: string; value: string; text: string }[] =
    loan?.purpose === LoanPurpose.rv ? serviceItemsRV : serviceItemsMarine;

  const tooltipStyle = {
    color: '#555555',
    fontSize: '0.625rem',
    padding: '0.7rem',
    minWidth: '19.68rem',
  };

  return (
    <AppFormItem
      name={['loan', 'addOnServices']}
      hasFeedback={false}
      initialValue={addOnServices}
      className={styles.addOnServices}
    >
      <ManualLabel
        className={styles.addOnServicesLabel}
        label="Select coverage you’d like more information on from our team (optional):"
      >
        <Checkbox.Group className={styles.addOnServicesCheckboxGroup}>
          {serviceItems.map((item) => (
            <>
              <Checkbox value={item.value} id={item.value} />
              <span>{item.label}</span>
              <Tooltip
                placement="right"
                title={item.text}
                trigger="click"
                overlayInnerStyle={tooltipStyle}
                color="#F5FAFF"
              >
                <span className={styles.addOnServicesInfoicon}>
                  <QuestionCircleOutlined />
                </span>
              </Tooltip>
              <br />
            </>
          ))}
        </Checkbox.Group>
        <div className={styles.addOnServicesDisclaimer}>
          (Add-on coverage <span>will not be added to your loan</span> until you speak with a team
          member.)
        </div>
      </ManualLabel>
    </AppFormItem>
  );
};
