import React from 'react';
import firebase from 'firebase/compat/app';

export const FirestoreContext = React.createContext<null | firebase.firestore.Firestore>(null);

export function useFirestore(): firebase.firestore.Firestore {
  const firestore = React.useContext(FirestoreContext);
  if (firestore === null) throw new Error('missing firestore');
  return firestore;
}
