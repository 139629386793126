import React, { useEffect, useState, useMemo } from 'react';
import { Col, Input, InputNumber, Radio, Row, Select, FormInstance, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import Cleave from 'cleave.js/react';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { rowLayout } from '../layout';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import {
  createSchemaValidator,
  createOptionalSchemaValidator,
} from '../../validators/schemaValidator';
import AppFormItem from './AppFormItem';
import { pickFields } from '../steps/meta';
import {
  AirplaneType,
  BoatType,
  Collateral,
  collateralHasRVEngine,
  EngineCount,
  Loan,
  LoanPurpose,
  RVType,
  TrailerAxles,
  Make,
  Model,
  mapNADACategory,
} from '../../schema';
import { useFirestore } from '../../hooks/useFirestore';
import { doc, getDoc } from 'firebase/firestore';
import { useMakesModelsStore } from '../../stores/makesModels';
import useConfig, { ConfigStore } from '../../stores/config';
import { betterParseInt, collateralMinYear } from '../../utils';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';

const { Text } = Typography;

const largeSixteen = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const largeTwo = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };
const largeThird = { xs: 24, sm: 24, md: 8, lg: 8, xl: 8 };
const smallThird = { xs: 16, sm: 16, md: 12, lg: 12, xl: 12 };

const schemaValidator = createSchemaValidator(pickFields('collateral'));
const optionalSchemaValidator = createOptionalSchemaValidator(schemaValidator);

function yearValidator(
  config: ConfigStore | null,
  purpose: LoanPurpose,
  fuel: Collateral['fuel'],
  type: Collateral['type']
) {
  return {
    validator: async (_rule: any, value: any) => {
      if (config && value) {
        const year = betterParseInt(value);
        if (year) {
          const minYear = collateralMinYear(config, purpose, fuel, type);
          if (year < minYear) {
            throw new Error(`The year is below the minimum of ${minYear}`);
          }
        }
      }
    },
  };
}

function areFieldsRequired(tradeCollateral: boolean, loan: Loan) {
  let required = true;
  if (
    !tradeCollateral &&
    loan?.purpose === LoanPurpose.boat &&
    loan.collateral &&
    // explicitly check that the user has selected false
    loan.collateral.specificCollateralKnown === false
  ) {
    required = false;
  }
  return required;
}

function disableYearField(collateral: Collateral) {
  return !collateral.type;
}

function disableManufacturerField(collateral: Collateral) {
  return !collateral.type || !collateral.year;
}

function disableField(collateral: Collateral) {
  let disabled;

  // XX Check for an exlicit false and not undefined
  if (collateral?.specificCollateralKnown === false) {
    disabled = false;
  } else {
    disabled = !collateral.type || !collateral.year || !collateral.manufacturer;
  }

  return disabled;
}

function resetFields(form: FormInstance, ...fieldNames: Array<string>) {
  const fields: any = [];
  fieldNames.forEach((fieldName) => {
    fields.push({
      name: fieldName,
      value: null,
    });
  });

  form.setFields(fields);
}

function resetFieldErrors(form: FormInstance) {
  const fieldErrors = form
    .getFieldsError()
    .filter((f) => f.errors.length > 0)
    .map((f) => {
      f.errors = [];
      return f;
    });

  form.setFields(fieldErrors);
  resetFields(
    form,
    'type',
    'year',
    'manufacturer',
    'manufacturerName',
    'engineManufacturer',
    'engineManufacturerName',
    'engineCount',
    'mileage',
    'horsepower',
    'loa',
    'model',
    'modelNumber',
    'fuel',
    'trailer',
    'trailerAxles'
  );
}

function clearCollateralNADAId(collateral: Collateral) {
  runInAction(() => {
    if (collateral?.modelNADAId) {
      delete collateral.modelNADAId;
    }
  });
}

function updateFields(collateral: Collateral, makeData: any, form: FormInstance) {
  const category = mapNADACategory(collateral.type);
  let data;
  if (makeData && category) {
    data = makeData[category];
  }
  const mfg = data?.find((m: Make) => m.make === collateral.manufacturer);
  const series = mfg?.models?.find((m: Model) => m.seriesName === collateral.model);
  let model;
  if (series) {
    model = mfg?.models?.find(
      (m: Model) => m.seriesName === series && m.name === collateral.modelNumber
    );
  } else {
    model = mfg?.models?.find((m: Model) => m.name === collateral.modelNumber);
  }

  const fieldNames = [];
  if (!mfg) {
    fieldNames.push('manufacturer');
  }

  if (!model) {
    fieldNames.push('model');
    fieldNames.push('modelNumber');
    clearCollateralNADAId(collateral);
  }

  resetFields(form, ...fieldNames);
}

function createModelSeriesOptions(models?: Model[]) {
  const modelSeries: string[] = [];
  if (models) {
    for (let i = 0; i < models.length; i++) {
      const seriesName = models[i]?.seriesName;
      if (seriesName && modelSeries.indexOf(seriesName) < 0) {
        modelSeries.push(seriesName);
      }
    }
    modelSeries.sort();
  }

  return modelSeries;
}

function createModelOptions(models: Model[], seriesName: string | undefined | null) {
  let result: Model[] = models;
  if (models && seriesName && seriesName !== 'None of these') {
    result = models.filter((m) => m.seriesName === seriesName);
  }

  return result;
}

type CollateralFormProps = {
  form: FormInstance;
  yearRange: number[];
  collateral: Collateral;
  loan: Loan;
  tradeCollateral?: boolean;
};

const CollateralForm: React.FC<CollateralFormProps> = observer(function CollateralForm({
  form,
  yearRange,
  collateral,
  loan,
  tradeCollateral = false,
}: CollateralFormProps) {
  const firestore = useFirestore();
  const config = useConfig();
  const [category, setCategory] = useState<string | undefined>(mapNADACategory(collateral.type));
  const [year, setYear] = useState<number | undefined | null>(collateral.year);
  const [priorYear, setPriorYear] = useState<number | undefined>();

  const makesModelsStore = useMakesModelsStore();
  const makesModelsValues = year ? makesModelsStore.get(loan.purpose, year) || null : null;
  const [makes, setMakes] = useState<any>(makesModelsValues);
  const makesIsEmpty = year && Object.keys(makes || {}).length === 0;

  const makesOptions: Make[] = [
    {
      id: -1,
      make: 'None of these',
    },
  ];

  if (category && makes && makes[category]) {
    makesOptions.push(...makes[category]);
  }
  const [make, setMake] = useState<string | undefined | null>(collateral.manufacturer);
  const isManufacturersDisabled = !(category && year);
  const [modelSeries, setModelSeries] = useState<string | undefined | null>(collateral.model);
  const [model, setModel] = useState<string | null | undefined>(collateral.modelNumber);

  const modelOptions: Model[] = [
    {
      id: -1,
      name: 'None of these',
      lengthInches: -1,
    },
  ];

  const modelSeriesOptions: string[] = ['None of these'];

  const isModelsDisabled = make === undefined;
  let makeData: Make;
  if (category && makes && makes[category] && make) {
    makeData = makes[category].find((m: Make) => m.make === make);
    if (makeData?.models) {
      modelSeriesOptions.push(...createModelSeriesOptions(makeData?.models));
      modelOptions.push(...createModelOptions(makeData?.models, modelSeries));
    }
  }

  const requiredField = { required: areFieldsRequired(tradeCollateral, loan) };

  // NADA doesn't have data before 2008
  const haveMakesData = makesOptions.length > 1;
  const needMakeName = make === 'None of these';

  const haveModelSeriesData = modelSeriesOptions.length > 1;
  const haveModelsData = modelOptions.length > 1;
  const needModelName = model === 'None of these';

  let isLoanPurposeRV = false;
  let isLoanPurposeBoat = false;
  let collateralOptions = null;

  switch (loan.purpose) {
    case LoanPurpose.rv:
      collateralOptions = Object.values(RVType);
      isLoanPurposeRV = true;
      break;
    case LoanPurpose.boat:
      collateralOptions = Object.values(BoatType);
      isLoanPurposeBoat = true;
      break;
    case LoanPurpose.aircraft:
      collateralOptions = Object.values(AirplaneType);
      break;
    default:
      throw new Error(`invalid purpose ${loan.purpose}`);
  }

  const showSpecificBoatQuestion = isLoanPurposeBoat && !tradeCollateral;

  const showModelSeriesDropdown = isLoanPurposeRV && haveModelSeriesData && !needMakeName;
  const showModelDropdown =
    (isLoanPurposeRV || isLoanPurposeBoat) && haveModelsData && !needMakeName;
  const showLengthAndFuel =
    tradeCollateral ||
    !isLoanPurposeRV ||
    makesIsEmpty ||
    (!isModelsDisabled && (!showModelDropdown || needModelName));

  const memoGetMakesModels = useMemo(() => {
    const fetch = (fetchYear: number | undefined, previousYear: number | undefined) => {
      if (fetchYear) {
        const data = makesModelsStore.get(loan.purpose, fetchYear);
        if (!data) {
          const purpose = loan.purpose.toLowerCase();
          const docRef = doc(firestore, `${purpose}-makes-models`, fetchYear.toString());
          getDoc(docRef)
            .then((snapshot) => {
              const makesModels = (snapshot.data() as any) || {};
              makesModelsStore.put(loan.purpose, fetchYear, makesModels);
              setMakes(makesModels);

              if (previousYear && previousYear !== fetchYear) {
                updateFields(collateral, makesModels, form);
              }
            })
            .catch((e) => console.error(e));
        } else {
          setMakes(data);
          if (previousYear && previousYear !== fetchYear) {
            updateFields(collateral, data, form);
          }
        }
      }
    };
    return fetch;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasCollateralKnown: boolean =
    typeof collateral?.specificCollateralKnown !== undefined &&
    collateral?.specificCollateralKnown !== false;
  const userHasNoBoatPickedOut: boolean = loan?.purpose === LoanPurpose.boat && !hasCollateralKnown;

  useEffect(() => {
    if (isLoanPurposeRV || isLoanPurposeBoat) {
      const fetchYear = year ? Math.min(year, new Date().getFullYear()) : undefined;
      memoGetMakesModels(fetchYear, priorYear);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memoGetMakesModels, year, isLoanPurposeRV]);

  useEffect(() => {
    if (userHasNoBoatPickedOut) {
      resetFields(
        form,
        'type',
        'year',
        'manufacturer',
        'manufacturerName',
        'engineManufacturer',
        'engineManufacturerName',
        'engineCount',
        'mileage',
        'horsepower',
        'loa',
        'model',
        'modelNumber',
        'fuel',
        'trailer',
        'trailerAxles'
      );
    }
  }, [form, userHasNoBoatPickedOut]);

  const handleMakesModelsCategory = (value: string) => {
    setCategory(mapNADACategory(value));
    resetFields(form, 'manufacturer', 'model', 'modelNumber');
    clearCollateralNADAId(collateral);
  };

  const handleMakesModelsYear = (value: number) => {
    // Handle case when the year is greater than the current year
    const yearValue = Math.min(value, new Date().getFullYear());
    if (yearValue !== year) {
      setModelSeries(null);
      setModel(null);
      if (year) {
        setPriorYear(year);
      }
      setYear(yearValue);
    }
  };

  const handleMake = (value: string) => {
    if (value !== make) {
      setMake(value);
      setModelSeries(null);
      setModel(null);
      resetFields(form, 'model', 'modelNumber');
      clearCollateralNADAId(collateral);
    }
  };

  const handleModelSeries = (value: string) => {
    if (value !== modelSeries) {
      setModelSeries(value);
      setModel(null);
      resetFields(form, 'modelNumber');
      clearCollateralNADAId(collateral);
    }
  };

  const handleModelSeriesClear = () => {
    setModelSeries(null);
  };

  const handleModel = (value: string) => {
    setModel(value);
    if (makeData) {
      const modelData = makeData.models?.find((m: Model) => m.name === value);
      if (modelData) {
        runInAction(() => {
          collateral.modelNADAId = modelData.id;

          if (modelData.fuel) {
            const GAS = 'gas';
            const fuel = modelData.fuel.toLowerCase() as Collateral['fuel'];
            collateral.fuel = fuel?.includes(GAS) ? GAS : fuel;
          }
        });
      }
    }
  };

  const FuelItem = () => (
    <AppFormItem
      className="manual-label"
      name="fuel"
      {...requiredField}
      hasFeedback={false}
      rules={[
        { required: requiredField.required, message: 'Please select the fuel used' },
        optionalSchemaValidator(requiredField.required, 'fuel', collateral),
      ]}
    >
      <ManualLabel className="placeholder" label="Fuel:" twoLine>
        <Radio.Group
          optionType="button"
          options={[
            { label: 'Gas', value: 'gas' },
            { label: 'Diesel', value: 'diesel' },
          ]}
          disabled={disableField(collateral)}
        />
      </ManualLabel>
    </AppFormItem>
  );

  function showMileage() {
    return (
      <>
        <div className="mobileSpacing" />

        <Col {...largeTwo}>
          <AppFormItem
            name="mileage"
            required
            rules={[
              { required: true, message: 'Please enter the mileage' },
              schemaValidator('mileage', collateral, {
                transform: 'int',
              }),
            ]}
          >
            <PlaceholderLabel label="Mileage (est)">
              <Cleave
                options={{
                  numeral: true,
                  numeralThousandsGroupStyle: 'thousand',
                  numeralDecimalScale: 0,
                  numeralPositiveOnly: true,
                }}
                className="ant-input"
                disabled={disableField(collateral)}
              />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>
      </>
    );
  }

  return (
    <>
      {showSpecificBoatQuestion && (
        <Row>
          <Col {...smallThird}>
            <AppFormItem
              className="manual-label"
              name="specificCollateralKnown"
              required
              hasFeedback={false}
              rules={[
                { required: true, message: 'Please select one' },
                schemaValidator('specificCollateralKnown', collateral),
              ]}
            >
              <ManualLabel label="Do you have a specific boat picked out?">
                <Radio.Group className="narrowButtons">
                  {/* eslint-disable-next-line react/jsx-boolean-value */}
                  <Radio.Button key="Yes" value={true}>
                    Yes
                  </Radio.Button>
                  <Radio.Button key="No" value={false} onClick={() => resetFieldErrors(form)}>
                    No
                  </Radio.Button>
                </Radio.Group>
              </ManualLabel>
            </AppFormItem>
          </Col>
        </Row>
      )}

      <Row {...rowLayout}>
        {userHasNoBoatPickedOut && (
          <Text style={{ display: 'inline-block', marginBottom: '2rem' }}>
            To provide you with a full credit decision, the collateral being financed will be
            required. In the meantime, a sales representative will be in touch to discuss indicative
            rates for potential purchases/loan structures upon completion of the application.
          </Text>
        )}

        <>
          <div className="mobileSpacing" />

          <Col {...largeTwo}>
            <AppFormItem
              name="type"
              {...requiredField}
              rules={[
                { required: requiredField.required, message: 'Please select one' },
                optionalSchemaValidator(requiredField.required, 'type', collateral),
              ]}
              validateTrigger="onChange"
            >
              <PlaceholderLabel label={`Type of ${loan.purpose}`}>
                <Select
                  suffixIcon={<SelectArrowIcon />}
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  onSelect={handleMakesModelsCategory}
                >
                  {collateralOptions.map((s) => (
                    <Select.Option key={s} value={s}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              </PlaceholderLabel>
            </AppFormItem>
          </Col>

          <div className="mobileSpacing" />

          <Col {...largeTwo}>
            <AppFormItem
              name="year"
              {...requiredField}
              rules={[
                { required: requiredField.required, message: 'Please enter the year' },
                optionalSchemaValidator(requiredField.required, 'year', collateral, {
                  message: 'Please enter the year',
                  transform: 'int',
                  minimum: {
                    message: `The oldest year we finance is ${
                      yearRange[yearRange.length - 1]
                    }. We finance up to 15 years old for gas RVs and 20 years for diesel`,
                    value: yearRange[yearRange.length - 1],
                  },
                }),
                yearValidator(config, loan.purpose, collateral.fuel, collateral.type),
              ]}
              validateTrigger="onChange"
            >
              <PlaceholderLabel label="Year">
                <Select
                  suffixIcon={<SelectArrowIcon />}
                  showSearch
                  allowClear
                  disabled={disableYearField(collateral)}
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    String(option?.children).indexOf(input.toLowerCase()) === 0
                  }
                  onSelect={handleMakesModelsYear}
                >
                  {yearRange.map((s) => (
                    <Select.Option key={s} value={s}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              </PlaceholderLabel>
            </AppFormItem>
          </Col>

          <div className="mobileSpacing" />

          <Col {...largeTwo}>
            <AppFormItem
              name="manufacturer"
              {...requiredField}
              rules={[
                { required: requiredField.required, message: 'Please enter the manufacturer' },
                optionalSchemaValidator(requiredField.required, 'manufacturer', collateral),
              ]}
              validateTrigger="onChange"
            >
              <PlaceholderLabel label="Manufacturer">
                {(isLoanPurposeRV || isLoanPurposeBoat) && haveMakesData ? (
                  <Select
                    showSearch
                    allowClear
                    disabled={isManufacturersDisabled}
                    onSelect={handleMake}
                  >
                    {makesOptions.map((m) => (
                      <Select.Option key={m.make} value={m.make}>
                        {m.make}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input disabled={disableManufacturerField(collateral)} />
                )}
              </PlaceholderLabel>
            </AppFormItem>
          </Col>

          {needMakeName && (
            <>
              <div className="mobileSpacing" />

              <Col {...largeTwo}>
                <AppFormItem
                  name="manufacturerName"
                  required
                  rules={[
                    schemaValidator('manufacturer', collateral, {
                      message: 'Please enter the manufacturer',
                    }),
                  ]}
                >
                  <PlaceholderLabel label="Manufacturer Name">
                    <Input />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </>
          )}
        </>

        <>
          {!isLoanPurposeBoat && (
            <>
              <div className="mobileSpacing" />

              <Col {...largeTwo}>
                <AppFormItem
                  name="model"
                  rules={[
                    schemaValidator('model', collateral, {
                      message: 'Please enter the model series',
                    }),
                  ]}
                >
                  <PlaceholderLabel label="Model Series">
                    {showModelSeriesDropdown ? (
                      <Select
                        showSearch={modelSeriesOptions?.length > 7}
                        allowClear
                        disabled={isModelsDisabled}
                        onClear={handleModelSeriesClear}
                        onSelect={handleModelSeries}
                      >
                        {modelSeriesOptions.map((m) => (
                          <Select.Option key={m} value={m}>
                            {m}
                          </Select.Option>
                        ))}
                      </Select>
                    ) : (
                      <Input disabled={disableField(collateral)} />
                    )}
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </>
          )}

          <div className="mobileSpacing" />

          <Col {...largeTwo}>
            <AppFormItem
              name="modelNumber"
              {...requiredField}
              rules={[
                { required: requiredField.required, message: 'Please enter the model' },
                optionalSchemaValidator(requiredField.required, 'modelNumber', collateral),
              ]}
            >
              <PlaceholderLabel label="Model">
                {showModelDropdown ? (
                  <Select
                    showSearch={modelOptions?.length > 7}
                    allowClear
                    disabled={isModelsDisabled}
                    onSelect={handleModel}
                  >
                    {modelOptions.map((m) => (
                      <Select.Option key={m.name} value={m.name}>
                        {m.name}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Input disabled={disableField(collateral)} />
                )}
              </PlaceholderLabel>
            </AppFormItem>
          </Col>

          {needModelName && (
            <>
              <div className="mobileSpacing" />

              <Col {...largeTwo}>
                <AppFormItem
                  name="modelName"
                  required
                  rules={[
                    schemaValidator('modelNumber', collateral, {
                      message: 'Please enter the model',
                    }),
                  ]}
                  validateTrigger="onChange"
                >
                  <PlaceholderLabel label="Model Name">
                    <Input />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </>
          )}

          {collateral.type && showLengthAndFuel && (
            <>
              <div className="mobileSpacing" />

              <Col {...largeTwo}>
                <AppFormItem
                  name="loa"
                  {...requiredField}
                  rules={[
                    { required: requiredField.required, message: 'Please enter the length' },
                    optionalSchemaValidator(requiredField.required, 'loa', collateral),
                  ]}
                >
                  <PlaceholderLabel label="Length">
                    <InputNumber type="number" min={0} disabled={disableField(collateral)} />
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </>
          )}

          {collateralHasRVEngine(collateral.type) && !showLengthAndFuel && showMileage()}
        </>

        {collateralHasRVEngine(collateral.type) && (
          <>
            {showLengthAndFuel && showMileage()}

            {showLengthAndFuel && (
              <>
                <div className="mobileSpacing" />

                <Col {...largeTwo}>
                  <FuelItem />
                </Col>
              </>
            )}
          </>
        )}

        {!isLoanPurposeRV && (
          <>
            <div className="mobileSpacing" />

            <Col {...largeTwo}>
              <AppFormItem
                {...requiredField}
                name="engineManufacturer"
                rules={[
                  {
                    required: requiredField.required,
                    message: 'Please enter the engine manufacturer',
                  },
                  optionalSchemaValidator(requiredField.required, 'engineManufacturer', collateral),
                ]}
              >
                <PlaceholderLabel label="Engine Manufacturer">
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            </Col>

            <div className="mobileSpacing" />

            <Col {...largeTwo}>
              <AppFormItem
                name="horsepower"
                {...requiredField}
                rules={[
                  { required: requiredField.required, message: 'Please enter the horsepower' },
                  optionalSchemaValidator(requiredField.required, 'horsepower', collateral),
                ]}
              >
                <PlaceholderLabel label="Horsepower">
                  <InputNumber type="number" />
                </PlaceholderLabel>
              </AppFormItem>
            </Col>
          </>
        )}
      </Row>
      <Row {...rowLayout}>
        {!isLoanPurposeRV && (
          <>
            {isLoanPurposeBoat && (
              <>
                <div className="mobileSpacing" />

                <Col {...largeThird}>
                  <FuelItem />
                </Col>
              </>
            )}

            <div className="mobileSpacing" />

            <Col {...largeSixteen}>
              <AppFormItem
                className="manual-label"
                name="engineCount"
                {...requiredField}
                hasFeedback={false}
                rules={[
                  {
                    required: requiredField.required,
                    message: 'Please select how many engines it has',
                  },
                  optionalSchemaValidator(requiredField.required, 'engineCount', collateral),
                ]}
              >
                <ManualLabel className="placeholder" label="Engine Count:" twoLine>
                  <Radio.Group
                    optionType="button"
                    options={[
                      { label: 'Single', value: EngineCount.single },
                      { label: 'Twin', value: EngineCount.twin },
                      { label: 'Triple', value: EngineCount.triple },
                      { label: 'Quad', value: EngineCount.quad },
                    ]}
                  />
                </ManualLabel>
              </AppFormItem>
            </Col>
          </>
        )}
        {isLoanPurposeBoat && (
          <>
            <div className="mobileSpacing" />

            <Col {...largeThird}>
              <AppFormItem
                className="manual-label"
                name="trailer"
                {...requiredField}
                hasFeedback={false}
                rules={[
                  { required: requiredField.required, message: 'Does this boat have a trailer?' },
                  optionalSchemaValidator(requiredField.required, 'trailer', collateral),
                ]}
              >
                <ManualLabel className="placeholder" label="Trailer:" twoLine>
                  <Radio.Group
                    optionType="button"
                    options={[
                      { label: 'Yes', value: true },
                      { label: 'No', value: false },
                    ]}
                  />
                </ManualLabel>
              </AppFormItem>
            </Col>
          </>
        )}

        {isLoanPurposeBoat && collateral.trailer && (
          <>
            <div className="mobileSpacing" />

            <Col {...largeTwo}>
              <AppFormItem
                className="manual-label"
                name="trailerAxles"
                required
                hasFeedback={false}
                rules={[
                  {
                    required: true,
                    message: 'Please select how many axles it has',
                  },
                  schemaValidator('trailerAxles', collateral),
                ]}
              >
                <ManualLabel className="placeholder" label="Number of Axles:" twoLine>
                  <Radio.Group
                    optionType="button"
                    options={[
                      { label: 'Single', value: TrailerAxles.single },
                      { label: 'Dual', value: TrailerAxles.dual },
                      { label: 'Triple', value: TrailerAxles.triple },
                    ]}
                  />
                </ManualLabel>
              </AppFormItem>
            </Col>
          </>
        )}
      </Row>
    </>
  );
});

export default CollateralForm;
