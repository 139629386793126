import { Menu } from 'antd';
import { AuditOutlined, LogoutOutlined, SearchOutlined } from '@ant-design/icons';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLoan } from '../../stores/store';

export function ProfileDropdownMenu() {
  const loanStore = useLoan();
  return (
    <Menu>
      {loanStore.userIsAdmin && (
        <Menu.Item key="admin">
          <Link to="/admin">
            <SearchOutlined /> Admin
          </Link>
        </Menu.Item>
      )}
      <Menu.Item key="apps">
        <Link to="/profile/apps">
          <AuditOutlined /> My applications
        </Link>
      </Menu.Item>
      <Menu.Item key="logout">
        <Link to="/logout">
          <LogoutOutlined /> Logout
        </Link>
      </Menu.Item>
    </Menu>
  );
}
