import React, { useEffect, useState } from 'react';
import { useLoan } from '../../stores/store';
import useConfig from '../../stores/config';
import getSource from '../../utils/getSource';

import styles from '../loan.module.scss';
import { Loan } from '../../schema';
import { Row, Space, Typography } from 'antd';
import { useParams } from 'react-router-dom';
import { GOODSAM_SOURCE_ID } from '../../constants';

const GoodSamDisclaimer: React.FC = () => {
  const config = useConfig();
  const loanStore = useLoan();
  let urlParams: any;

  const { loan } = loanStore;
  const { source } = getSource(config, loan as Loan);
  const isGoodSam = source?.id === GOODSAM_SOURCE_ID;

  try {
    // this errors if there's no match
    // eslint-disable-next-line react-hooks/rules-of-hooks
    urlParams = useParams();
  } catch (e) {
    // don't need this
  }

  const [disclaimerSeen] = useState(!!loan?.thirdPartyDisclaimerSeen);

  useEffect(() => {
    const timer = setTimeout(() => {
      loanStore.setValue('thirdPartyDisclaimerSeen', true);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isGoodSam && !disclaimerSeen ? (
    <Row className={styles.sectionThirdPartyDisclaimer} id="third-party-disclaimer">
      <Space direction="horizontal" style={{ width: '100%', justifyContent: 'center' }}>
        <Typography.Text>
          This site is operated by {source.displayName || 'Good Sam Finance Center'} provided
          through Trident Funding LLC. Use of this site is subject to the site's{' '}
          <a href="https://www.tridentfunding.com/terms-of-use/">Terms of Use</a> and{' '}
          <a href="https://www.tridentfunding.com/privacy-policy/">Privacy Policy</a>.
        </Typography.Text>
      </Space>
    </Row>
  ) : null;
};

export default GoodSamDisclaimer;
