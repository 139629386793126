import { betterParseFloat } from '../utils/betterParseFloat';
import { betterParseInt } from '../utils/betterParseInt';
import { runInAction } from 'mobx';

type MinimumValidator = {
  message: string;
  value: number;
};

export type ValidatorOptions = {
  transform?: 'int' | 'float' | ((value: any) => any);
  message?: string;
  required?: boolean;
  minimum?: MinimumValidator;
  custom?: (value: any) => void;
};

export type SchemaValidatorFunction<T> = (
  name: keyof T,
  target: T | undefined | null,
  options: ValidatorOptions
) => { validator: (rule: any, value: any) => Promise<void> };

export function createSchemaValidator(schema: any) {
  return <T>(name: keyof T, target: T | undefined | null, options: ValidatorOptions = {}) => {
    const validatorSchema = schema.pick({ [name]: true });
    return {
      validator: async (rule: any, value: any): Promise<void> => {
        try {
          if (options.transform === 'int') {
            value = betterParseInt(value);
          } else if (options.transform === 'float') {
            value = betterParseFloat(value);
          } else if (options.transform !== undefined) {
            value = options.transform(value);
          }
          if (value === '') value = undefined;
          if (value === null) value = undefined;
          if (options.required && (value === null || value === undefined)) {
            throw new Error('This field is required');
          }
          if (options.minimum !== undefined && value < options.minimum.value) {
            throw new Error(options.minimum.message);
          }

          const result = await validatorSchema.parseAsync({ [name]: value });
          let finalValue = result[name];
          if (finalValue === undefined) finalValue = null;
          if (options.custom) {
            options.custom(finalValue);
          }
          if (target) {
            runInAction(() => {
              target[name] = finalValue;
            });
          }
        } catch (e: any) {
          if ('issues' in e) {
            if (e.issues[0].code === 'invalid_enum_value' || e.issues[0].code === 'invalid_union') {
              throw new Error('Please select one');
            }
            let error = e.issues[0].message;
            if (error === 'Required') {
              error = options.message || 'This field is required';
            }
            throw new Error(error);
          }
          throw new Error(e.message);
        }
      },
    };
  };
}

export function createOptionalSchemaValidator<T>(schemaValidator: SchemaValidatorFunction<T>) {
  return (
    required: boolean,
    name: keyof T,
    target: T | undefined | null,
    options: ValidatorOptions = {}
  ) => {
    let validator = {
      validator: async (_rule: any, value: any) => {
        if (target) {
          runInAction(() => {
            target[name] = value;
          });
        }
      },
    };

    if (required) {
      validator = schemaValidator(name, target, options);
    }

    return validator;
  };
}
