import React from 'react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../stores/store';
import AppFormItem from '../../loan/components/AppFormItem';
import { getUrlCurrentStep } from '../../utils/urls';
import useHistory from '../../hooks/useHistory';
import { pushToDataLayer, getStepLabel } from '../../utils/gtm';

type Props = {
  onNext?: () => void;
  isDisabled?: boolean;
};

const NextButton: React.FC<Props> = observer(({ onNext, isDisabled = false }) => {
  const loanStore = useLoan();
  const history = useHistory();
  const isSubmit = getUrlCurrentStep(history.location.pathname) === 'submit';

  return (
    <AppFormItem name="next" wrapperCol={{ offset: 0, span: 24 }}>
      <Button
        name="next"
        type="primary"
        htmlType="submit"
        disabled={isDisabled}
        onClick={() => {
          loanStore.navDirection = 1;
          if (onNext) onNext();
          const { loan } = loanStore;
          if (loan) {
            pushToDataLayer('e_applicationAction', {
              application_category: 'financing application',
              application_action: isSubmit ? 'submit' : 'next',
              application_label: getStepLabel(loan.currentStep),
              loan_purpose: loan.purpose.toLowerCase(),
            });
          }
          return true;
        }}
      >
        {isSubmit ? 'Submit' : 'Next'}
      </Button>
    </AppFormItem>
  );
});

export default NextButton;
