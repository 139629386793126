/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { usePrequal } from '../../stores/prequalStore';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import MoneyInput from '../components/MoneyInput/MoneyInput';
import { Prequal } from '../../schema';
import { betterParseFloat, formatCurrency, pushToDataLayer } from '../../utils';
import { stepFinished } from '../../prequalNav';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const MIN_PURCHASE_PRICE = 100000;
const MIN_PERCENTAGE = 10;

const DownPaymentStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [labelDownPayment, setLabelDownPayment] = useState('$0.00');
  const [labelPercentage, setLabelPercentage] = useState('0');
  const [form] = useForm();

  const updateValues = (values: any) => {
    prequalStore.setDownPayment(values);
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'Down payment price - Continue',
    });
    stepFinished();
  };

  const calculatePercentage = (purchasePrice: number, downPayment: number) => {
    if (!purchasePrice || !downPayment) {
      return 0;
    }
    const percentage = (downPayment / purchasePrice) * 100;
    return percentage;
  };

  const truncateToTwoDecimals = (number: number) => (Math.floor(number * 100) / 100).toString();

  const setPercentageLabels = (purchasePrice: number, downPayment: number) => {
    const percentage = calculatePercentage(purchasePrice, downPayment);
    setLabelDownPayment(formatCurrency(downPayment));
    setLabelPercentage(truncateToTwoDecimals(percentage));
    return percentage;
  };

  const validator = async (_: any, value: any) => {
    const downPayment = betterParseFloat(value) ?? NaN;
    const valuesFromPrequal = prequal as Prequal;
    const { purchasePrice } = valuesFromPrequal;
    const percentage = setPercentageLabels(purchasePrice, downPayment);

    if (Number.isNaN(downPayment)) {
      setCanContinue(false);
      throw new Error('This field is required');
    }
    if (downPayment >= purchasePrice) {
      setCanContinue(false);
      throw new Error(
        `Down Payment could not be equal or greater than ${formatCurrency(purchasePrice)}`
      );
    }
    if (purchasePrice >= MIN_PURCHASE_PRICE && percentage < MIN_PERCENTAGE) {
      setCanContinue(false);
      throw new Error(`Our lenders require at least 10% down to secure a loan.`);
    }
    setCanContinue(true);
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'down payment',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    let { downPayment } = values;
    const { purchasePrice = 0 } = values;
    if (downPayment === null || downPayment === undefined) {
      downPayment = purchasePrice * 0.2;
    }
    setCanContinue(true);
    form.setFieldsValue({
      downPayment: downPayment.toFixed(2),
    });
    setPercentageLabels(purchasePrice, downPayment);
  }, [prequal, form]);

  const header = {
    title: 'Financing Details',
    subtitle: 'What amount are you planning to use for a down payment?',
    content: "The more you put down, the less you'll pay in interest throughout the loan.",
  };

  return (
    <PreQualFunnelLayout>
      <SectionHeader header={header} />
      <Form {...formItemLayout} form={form} initialValues={prequal} onFinish={updateValues}>
        <MoneyInput
          labelText="Down Payment Price"
          subLabelText={`Down payment (${labelDownPayment}) is ${labelPercentage}% of the price`}
          name="downPayment"
          validator={validator}
          validateTrigger="onChange"
        />
        <NavigationRow canContinue={canContinue} form={form} />
      </Form>
    </PreQualFunnelLayout>
  );
});

export default DownPaymentStep;
