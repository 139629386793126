import React from 'react';
import styles from './ProgressBar.module.scss';

const CheckIcon: React.FC = () => (
  <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M14.12 0L5.33333 8.3949L1.88 5.10828L0 6.90446L5.33333 12L16 1.80892L14.12 0Z"
      fill="white"
    />
  </svg>
);

export default CheckIcon;
