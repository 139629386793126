import React from 'react';
import styles from './StepFooter.module.scss';
import { useLoan } from '../../../stores/store';
import { LoanPurpose } from '../../../schema';

type Props = {
  type: 'info' | 'secure';
};

const SECURE_INFO_ITEMS = [
  "We'll encrypt your information to keep it secure",
  "We won't sell your information",
  "We won't spam you",
];

const PAID_INFO_ITEMS = [
  "We'll compare over 15 lenders to get you the best rate & terms",
  'Customized financing to meet your needs',
  'Over 25 years of marine lending experience',
];

export const StepFooter: React.FC<Props> = ({ type }) => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  let mainIcon;
  let footerItems;

  switch (type) {
    case 'secure':
      mainIcon = 'verified_user';
      footerItems = SECURE_INFO_ITEMS;
      break;
    case 'info':
    default:
      footerItems = PAID_INFO_ITEMS;
      break;
  }

  return loan?.purpose === LoanPurpose.boat ? (
    <div className={styles.pageFooterContainer}>
      {!!mainIcon && (
        <span className={`${styles.pageFooterSymbol} material-symbols-rounded`}>{mainIcon}</span>
      )}
      <div className="prop-text">
        {footerItems.map((item) => (
          <li key={item} className={styles.pageFooterItem}>
            <span className="material-symbols-rounded">done</span>
            {item}
          </li>
        ))}
      </div>
    </div>
  ) : null;
};
