import {
  addressSchema,
  assetSchema,
  borrowerSchema,
  collateralSchema,
  employerSchema,
  financeDetailsSchema,
  liabilitySchema,
  Loan,
  loanSchema,
  otherAssetSchema,
  realEstateSchema,
} from '../../schema';
import { Route } from '../../nav';
import { KameleoonFeatureFlag } from '../../hooks/useFeatureFlag';
import { FullAppOptimizeV1Flag } from '../../featureFlags';

type StepMetaValueOverrideType = {
  field: string;
  path: string;
  omit?: boolean;
};

type OverrideParams = {
  [key in KameleoonFeatureFlag]: boolean;
};

export type StepMetaType = {
  label: string;
  step: Route;
  path: Array<number | string>;
  fields?: string[];
  schema: any;
  isArray?: boolean;
  // used to generate nav header
  sectionHead?: boolean;
  valueOverrides?: (params?: OverrideParams) => StepMetaValueOverrideType[];
};

const combinedBorrowerSchema = loanSchema.pick({ applicantType: true }).merge(borrowerSchema);

export const stepMeta: Array<StepMetaType> = [
  {
    label: 'Loan Details',
    step: 'details',
    schema: loanSchema,
    path: [],
    fields: [
      'purpose',
      'loanType',
      'liveaboard',
      'ownershipType',
      'associatedName',
      'subSource',
      'subSourceName',
      'promocode',
      'member',
    ],
    valueOverrides: () => [
      {
        field: 'ownershipType',
        path: '',
        omit: true,
      },
      {
        field: 'associatedName',
        path: '',
        omit: true,
      },
    ],
  },
  {
    label: 'Marketing Details',
    step: 'marketing',
    schema: loanSchema,
    path: [],
    fields: [
      'source',
      'subSource',
      'member',
      'keycode',
      'promocode',
      'salesForceId',
      'rateQuoteId',
      'id',
      'tracking',
      'utm',
      'marketing',
      'googleClickId',
    ],
    sectionHead: true,
  },
  {
    label: 'Contact',
    step: 'contact',
    schema: combinedBorrowerSchema,
    path: ['borrower'],
    fields: [
      'firstName',
      'middleInitial',
      'lastName',
      'email',
      'homePhone',
      'cellPhone',
      'applicantType',
    ],
    sectionHead: true,
    valueOverrides: (params) => {
      const overrides: StepMetaValueOverrideType[] = [];

      if (!params?.[FullAppOptimizeV1Flag.name]) {
        overrides.push(
          ...[
            {
              field: 'firstName',
              path: '',
              omit: true,
            },
            {
              field: 'middleInitial',
              path: '',
              omit: true,
            },
            {
              field: 'lastName',
              path: '',
              omit: true,
            },
            {
              field: 'applicantType',
              path: '',
              omit: true,
            },
          ]
        );
      }

      return overrides;
    },
  },
  {
    label: 'Borrower',
    step: 'borrower',
    schema: combinedBorrowerSchema,
    path: ['borrower'],
    fields: [
      'firstName',
      'middleInitial',
      'lastName',
      'dateOfBirth',
      'ssn',
      'citizen',
      'relationship',
      'applicantType',
    ],
    valueOverrides: (params) => {
      const overrides: StepMetaValueOverrideType[] = [];

      if (params?.[FullAppOptimizeV1Flag.name]) {
        overrides.push(
          ...[
            {
              field: 'firstName',
              path: '',
              omit: true,
            },
            {
              field: 'middleInitial',
              path: '',
              omit: true,
            },
            {
              field: 'lastName',
              path: '',
              omit: true,
            },
          ]
        );
      }

      return overrides;
    },
  },
  {
    label: 'Address',
    step: 'address',
    schema: addressSchema,
    path: ['borrower', 'currentAddress'],
  },
  {
    label: 'Previous Address',
    step: 'previous-address',
    schema: addressSchema,
    path: ['borrower', 'previousAddress'],
  },
  {
    label: 'Mailing Address',
    step: 'mailing-address',
    schema: addressSchema,
    path: ['borrower', 'mailingAddress'],
  },
  {
    label: 'Seller Details',
    step: 'seller',
    schema: loanSchema,
    path: [],
    fields: [
      'seller',
      'sellerName',
      'sellerRepName',
      'sellerEmail',
      'sellerPhone',
      'hasTrade',
      'sellerCollateralURL',
    ],
    valueOverrides: () => [
      {
        field: 'manufacturer',
        path: 'trade',
        omit: false,
      },
      {
        field: 'year',
        path: 'trade',
        omit: false,
      },
      {
        field: 'model',
        path: 'trade',
        omit: false,
      },
      {
        field: 'modelNumber',
        path: 'trade',
        omit: false,
      },
      {
        field: 'mileage',
        path: 'trade',
        omit: false,
      },
      {
        field: 'loa',
        path: 'trade',
        omit: false,
      },
      {
        field: 'type',
        path: 'trade',
        omit: false,
      },
      {
        field: 'engineManufacturer',
        path: 'trade',
        omit: false,
      },
      {
        field: 'horsepower',
        path: 'trade',
        omit: false,
      },
      {
        field: 'engineCount',
        path: 'trade',
        omit: false,
      },
      {
        field: 'fuel',
        path: 'trade',
        omit: false,
      },
      {
        field: 'trailer',
        path: 'trade',
        omit: false,
      },
      {
        field: 'trailerAxles',
        path: 'trade',
        omit: false,
      },
      {
        field: 'specificCollateralKnown',
        path: 'trade',
        omit: false,
      },
    ],
  },
  {
    label: 'Collateral',
    step: 'collateral',
    path: ['collateral'],
    fields: [
      'manufacturer',
      'year',
      'model',
      'modelNumber',
      'mileage',
      'loa',
      'type',
      'engineManufacturer',
      'horsepower',
      'engineCount',
      'fuel',
      'trailer',
      'trailerAxles',
      'specificCollateralKnown',
    ],
    schema: collateralSchema,
    sectionHead: true,
  },
  {
    label: 'Boating Experience',
    step: 'experience',
    schema: collateralSchema,
    path: ['collateral'],
    fields: ['boatingExperience'],
  },
  {
    label: 'Loan Info',
    step: 'finance',
    path: ['finance'],
    schema: financeDetailsSchema.omit({ currentLender: true }),
    sectionHead: true,
    valueOverrides: () => [
      {
        field: 'ownershipType',
        path: '',
        omit: false,
      },
      {
        field: 'associatedName',
        path: '',
        omit: false,
      },
    ],
  },
  {
    label: 'Refinance Details',
    step: 'refinance',
    path: ['finance'],
    schema: financeDetailsSchema.pick({
      currentLender: true,
      purchasePrice: true,
      currentRate: true,
      term: true,
    }),
    sectionHead: true,
  },
  {
    label: 'Cash Recapture Details',
    step: 'cash-recapture',
    path: ['finance'],
    schema: financeDetailsSchema.pick({
      cashRecaptureAmount: true,
      dateOfPurchase: true,
      term: true,
    }),
    sectionHead: true,
  },
  {
    label: 'Income',
    step: 'income',
    schema: borrowerSchema,
    path: ['borrower'],
    fields: [
      'employmentStatus',
      'retiredYears',
      'primaryIncomeSource',
      'primaryIncomeSourceCustom',
      'primaryIncomeAmount',
      'secondaryIncomeSource',
      'secondaryIncomeSourceCustom',
      'secondaryIncomeAmount',
      'otherIncomeSource',
      'otherIncomeSourceCustom',
      'otherIncomeAmount',
      'other2IncomeSource',
      'other2IncomeSourceCustom',
      'other2IncomeAmount',
      'other3IncomeSource',
      'other3IncomeSourceCustom',
      'other3IncomeAmount',
    ],
    sectionHead: true,
  },
  {
    label: 'Assets',
    step: 'assets',
    schema: assetSchema,
    isArray: true,
    path: ['borrower', 'financialStatement', 'assets'],
  },
  {
    label: 'Other Assets',
    step: 'other-assets',
    schema: otherAssetSchema,
    isArray: true,
    path: ['borrower', 'financialStatement', 'otherAssets'],
  },
  {
    label: 'Real Estate Assets',
    step: 'real-estate',
    isArray: true,
    schema: realEstateSchema,
    path: ['borrower', 'financialStatement', 'realEstate'],
  },
  {
    label: 'Liabilities',
    step: 'liabilities',
    isArray: true,
    schema: liabilitySchema,
    path: ['borrower', 'financialStatement', 'liabilities'],
  },
  {
    label: 'Employer',
    step: 'employer',
    schema: employerSchema,
    path: ['borrower', 'currentEmployer'],
  },
  {
    label: 'Additional Employer',
    step: 'employer2',
    schema: employerSchema,
    path: ['borrower', 'currentEmployer2'],
  },
  {
    label: 'Previous Employer',
    step: 'previous-employer',
    schema: employerSchema,
    path: ['borrower', 'previousEmployer'],
  },
  {
    label: 'Co-Borrower',
    step: 'coborrower',
    path: ['coborrower'],
    schema: borrowerSchema,
    fields: ['firstName', 'middleInitial', 'lastName', 'dateOfBirth', 'ssn', 'citizen'],
    sectionHead: false,
  },
  {
    label: 'Co-Borrower Contact',
    step: 'coborrower-contact',
    schema: borrowerSchema,
    path: ['coborrower'],
    fields: ['email', 'homePhone', 'cellPhone'],
  },
  {
    label: 'Co-Borrower Address',
    step: 'coborrower-address',
    schema: addressSchema,
    path: ['coborrower', 'currentAddress'],
  },
  {
    label: 'Co-Borrower Previous Address',
    step: 'coborrower-previous-address',
    schema: addressSchema,
    path: ['coborrower', 'previousAddress'],
  },
  {
    label: 'Co-Borrower Income',
    step: 'coborrower-income',
    schema: borrowerSchema,
    path: ['coborrower'],
    fields: [
      'employmentStatus',
      'primaryIncomeSource',
      'primaryIncomeAmount',
      'secondaryIncomeSource',
      'secondaryIncomeAmount',
      'otherIncomeSource',
      'otherIncomeAmount',
      'other2IncomeSource',
      'other2IncomeAmount',
      'other3IncomeSource',
      'other3IncomeAmount',
    ],
  },
  {
    label: 'Co-Borrower Employer',
    step: 'coborrower-employer',
    schema: employerSchema,
    path: ['coborrower', 'currentEmployer'],
  },
  {
    label: 'Co-Borrower Additional Employer',
    step: 'coborrower-employer2',
    schema: employerSchema,
    path: ['coborrower', 'currentEmployer2'],
  },
  {
    label: 'Co-Borrower Previous Employer',
    step: 'coborrower-previous-employer',
    schema: employerSchema,
    path: ['coborrower', 'previousEmployer'],
  },
  {
    label: 'Confirm',
    step: 'review',
    schema: null,
    path: ['review'],
    sectionHead: true,
  },
];

export function getMeta(step: Route): StepMetaType {
  for (let i = 0; i < stepMeta.length; i++) {
    const meta = stepMeta[i];
    if (meta.step === step) {
      return meta;
    }
  }
  throw new Error(`invalid step: ${step}`);
}

export function pickFields(step: Route): any {
  const meta = getMeta(step);
  if (!meta.fields) return meta.schema;
  const fields: { [p: string]: boolean } = Object.fromEntries(meta.fields.map((s) => [s, true]));
  return meta.schema.pick(fields);
}

export function getAllFieldsFromMeta(meta: StepMetaType, overrideParams: OverrideParams): string[] {
  const allFields: Set<string> = new Set(
    meta.fields ? [...meta.fields] : Object.keys(meta.schema.shape)
  );
  const valueOverrides = meta?.valueOverrides ? meta.valueOverrides(overrideParams) : [];
  valueOverrides.forEach((override) => {
    if (override.omit) {
      allFields.delete(override.field);
    } else {
      allFields.add(override.field);
    }
  });

  return Array.from(allFields);
}

export function getValueWithMeta({
  meta,
  loan,
  key,
  overrideParams,
}: {
  meta: StepMetaType;
  loan: Loan;
  key?: string;
  overrideParams: OverrideParams;
}): any {
  let obj = loan;

  // Value override for review step
  const valueOverrides = meta?.valueOverrides ? meta.valueOverrides(overrideParams) : [];
  const override = valueOverrides.find((ov) => ov.field === key);
  if (override) {
    if (override?.path) {
      obj = obj[override.path];
    }

    return obj ? obj[override.field] : null;
  }

  for (let i = 0; i < meta.path.length; i++) {
    const path = meta.path[i];
    if (!obj[path]) return null;
    obj = obj[path];
  }
  if (!key) return obj;
  return obj[key];
}
