import React, { FC, useCallback, useState } from 'react';
import BlankLayout from '../../BlankLayout';
import { useForm } from 'antd/es/form/Form';
import { Button, ColProps, Form, FormProps, Input, Row, Space } from 'antd';
import AppFormItem from '../AppFormItem';
import { z } from 'zod';
import PlaceholderLabel from '../../../components/PlaceholderLabel/PlaceholderLabel';
import useHistory from '../../../hooks/useHistory';
import { useParams } from 'react-router-dom';
import Text from 'antd/lib/typography/Text';
import PhoneNumberModal from './PhoneNumberModal';
import styles from './PartnerLoan.module.scss';
import { betterParseInt } from '../../../utils';
import firebase from 'firebase/compat/app';

const formItemLayout: Partial<FormProps> = {
  labelCol: { span: 0 },
  wrapperCol: {
    sm: { span: 24, offset: 0 },
    md: { span: 24, offset: 0 },
    lg: { span: 20, offset: 4 },
  },
};

const formTextWrapperCol: ColProps = {
  sm: { span: 24, offset: 0 },
  md: { span: 24, offset: 0 },
  lg: { span: 16, offset: 4 },
};

const PartnerLoan: FC = () => {
  const [form] = useForm();
  const history = useHistory();
  const params = useParams<{ partnerLoanId: string }>();

  const [verifying, setVerifying] = useState(false);
  const [showRetryMessage, setShowRetryMessage] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [purpose, setPurpose] = useState('Boat');
  const urlSearch = new URLSearchParams(window.location.search);
  const advertisedRate = urlSearch.get('advertisedRate');
  const subSource = betterParseInt(urlSearch.get('subsource'));

  const handleVerifyClick = useCallback(async () => {
    setVerifying(true);
    setShowRetryMessage(false);

    try {
      await form.validateFields(['email', 'ssn']);

      const body = {
        partnerLoanId: params.partnerLoanId,
        email: form.getFieldValue('email'),
        ssn: form.getFieldValue('ssn'),
        advertisedRate,
        subSource,
      };

      // Can't set this outside the component because it will run before initializing firebase
      const functions = firebase.functions();
      const validatePartnerLoan = functions.httpsCallable('validatePartnerLoan');
      const callResults = await validatePartnerLoan(body);
      const validationResults = callResults.data;

      if (validationResults.loanId) {
        history.replace(`/${validationResults.loanId}/details`);
      }
    } catch (e: any) {
      const { details } = e;
      if (details.purpose) {
        setPurpose(details.purpose);
      }

      if (details.canRetry) {
        setShowRetryMessage(true);
      } else {
        setShowPhoneNumber(true);
      }
    } finally {
      setVerifying(false);
    }
  }, [params, form, history, advertisedRate, subSource]);

  const dismissPhoneNumberModal = useCallback(() => {
    setShowPhoneNumber(false);
  }, []);

  return (
    <BlankLayout title="Verify your application">
      <h1>Enter the following information to verify your yourself</h1>
      <Form form={form} {...formItemLayout} className={styles.form}>
        <AppFormItem
          name="email"
          wrapperCol={formTextWrapperCol}
          required
          rules={[
            {
              validator: async (rule, value) => {
                await z.string().email().parseAsync(value);
              },
              message: 'Must be an email',
            },
          ]}
        >
          <PlaceholderLabel label="Email" htmlFor="email">
            <Input id="email" />
          </PlaceholderLabel>
        </AppFormItem>

        <AppFormItem
          name="ssn"
          wrapperCol={formTextWrapperCol}
          required
          rules={[
            {
              validator: async (rule, value) => {
                await z
                  .string()
                  .regex(/^[0-9]{4}$/)
                  .parseAsync(value);
              },
              message: 'Must be exactly 4 numeric digits',
            },
          ]}
        >
          <PlaceholderLabel label="Last 4 digits of SSN" htmlFor="ssn">
            <Input id="ssn" />
          </PlaceholderLabel>
        </AppFormItem>
      </Form>
      <Row justify="center">
        <Space direction="horizontal">
          <Button
            name="verify"
            type="primary"
            htmlType="button"
            onClick={handleVerifyClick}
            disabled={verifying}
          >
            Verify
          </Button>
        </Space>
      </Row>
      {showRetryMessage && (
        <Row justify="center">
          <Text id="retry-message" type="danger">
            The information provided doesn't match, please try again.
          </Text>
        </Row>
      )}

      <PhoneNumberModal
        visible={showPhoneNumber}
        dismiss={dismissPhoneNumberModal}
        purpose={purpose}
      />
    </BlankLayout>
  );
};

export default PartnerLoan;
