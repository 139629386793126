import React from 'react';
import { Col, Row, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import SaveButton from './SaveButton';
import { useLoan } from '../../stores/store';
import NavPreviousButton from './NavPreviousButton';
import NavNextButton from './NavNextButton';
import styles from './buttons.module.scss';
import classNames from 'classnames';

type Props = {
  onNext?: () => void;
  isNextDisabled?: boolean;
};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const ButtonNav: React.FC<Props> = observer(({ onNext, isNextDisabled = false }) => {
  const loanStore = useLoan();
  const { loan } = loanStore;

  if (!loan || loanStore.status === 'pending') {
    return null;
  }

  // if submitted use button versions that navigate without saving
  if (loan.workflow !== 'draft') {
    return (
      <Row justify="center" className={styles.row}>
        <Col {...centerSmallOffset} />
        <Col {...centerSmall}>
          <NavPreviousButton />
          <Col xs={1} sm={1} md={1} lg={1} />
          <Space />
          <NavNextButton />
        </Col>
      </Row>
    );
  }

  return (
    <Row justify="center" className={classNames(styles.row, 'screen-only')}>
      <Col {...centerSmall}>
        <Space direction="horizontal">
          <PreviousButton />
          <SaveButton />
          <NextButton onNext={onNext} isDisabled={isNextDisabled} />
        </Space>
      </Col>
    </Row>
  );
});

export default ButtonNav;
