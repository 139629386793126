// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory as createHistory, History } from 'history';

let history: History<unknown> | null = null;

export default function useHistory(basename: string = '') {
  if (!history) {
    history = createHistory({ basename });
  }
  return history;
}
