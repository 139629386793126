import firebase from 'firebase/compat/app';
import axios from 'axios';
import {
  FeatureFlag,
  Loan,
  Prequal,
  LoanPurpose,
  KameleoonExperiment as Experiment,
} from '../schema';
import { getCookie } from './cookies';
import {
  CUSTOM_DATA_INDICES,
  DEFAULT_KAMELEOON_SITEKEY,
  PROD_KAMELEOON_GCP_FUNCTION_URL,
  STAGE_KAMELEOON_GCP_FUNCTION_URL,
  isProduction,
} from '../constants';

/**
 * Find the active feature flag by key
 * @param featureFlags - Array of feature flags
 * @param key - Key to search for
 * @returns The active feature flag if found, otherwise undefined
 */
export const findFeatureFlagActive = (
  featureFlags: FeatureFlag[],
  key: string
): FeatureFlag | undefined => featureFlags?.find((flag) => flag?.key === key);

/**
 * Loads active experiments by assigning variations and triggering them.
 *
 * @param {Experiment[]} experiments - array of experiments to load
 * @return {void}
 */
const loadActiveExperiments = (experiments: Experiment[]) => {
  experiments.forEach((exp: Experiment) => {
    window.kameleoonQueue.push(['Experiments.assignVariation', exp.id, exp.variation]);
    window.kameleoonQueue.push(['Experiments.trigger', exp.id, false]);
  });
};

const resolveKameleoonSiteKey = () => {
  type SiteKeys = {
    [key: string]: string;
    'www.qa.boattrader.com': string;
    'qa.yachtworld.com': string;
    'www.boattrader.com': string;
    'www.yachtworld.com': string;
    'www.boats.com': string;
    'www.tridentfunding.com': string;
    'stage-trident.web.app': string;
    localhost: string;
  };

  const siteKeys: SiteKeys = {
    'www.qa.boattrader.com': 'jhsglvnltm',
    'qa.yachtworld.com': 'bp82tjtf6x',
    'www.boattrader.com': 'uk33jcty1m',
    'www.yachtworld.com': '6tukvyck0c',
    'www.boats.com': 'h7dtf89mtg',
    'www.tridentfunding.com': 'a3jdz8ss35',
    'stage-trident.web.app': 'jhsglvnltm',
    localhost: 'jhsglvnltm',
  };

  const currentHost: keyof SiteKeys = window.location.hostname;
  return siteKeys[currentHost] || DEFAULT_KAMELEOON_SITEKEY;
};

/**
 * Load Kameleoon data and set up AB tests for the visitor (Full Funnel)
 *
 * @return {Promise<void>} A promise that resolves once the Kameleoon data is loaded and
 * AB tests are set up.
 */
export const loadKameleoonFlagsAndTests = async (loan: Loan | null | undefined): Promise<void> => {
  try {
    const kameleoonGcpFunctionUrl = isProduction()
      ? PROD_KAMELEOON_GCP_FUNCTION_URL
      : STAGE_KAMELEOON_GCP_FUNCTION_URL;
    if (
      (window.__AB_TEST__ ?? null) === null &&
      (loan ?? null) !== null &&
      window.kameleoonDataPending !== true
    ) {
      window.kameleoonDataPending = true;
      const kameleoonCustomData: { [key: number]: any } = {};
      const isMarinePurpose = (loan ?? null) !== null && loan?.purpose === LoanPurpose.boat;
      const visitorCodeFromCookie = getCookie('kameleoonVisitorCode');
      const savedVisitorCode = (loan ?? null) !== null && loan?.visitorCode;
      const clientVisitorCode = savedVisitorCode || visitorCodeFromCookie;
      kameleoonCustomData[CUSTOM_DATA_INDICES.IS_MARINE_PURPOSE] = JSON.stringify(isMarinePurpose);

      const result = await axios.post(kameleoonGcpFunctionUrl, {
        siteCode: resolveKameleoonSiteKey() || DEFAULT_KAMELEOON_SITEKEY,
        domain: window.location.hostname,
        fullUrl: localStorage.getItem('fullUrl') || window.location.href,
        kameleoonCustomData,
        ...(clientVisitorCode && {
          clientVisitorCode,
        }),
      });
      window.__AB_TEST__ = result.data;
      window.kameleoonDataPending = false;
      localStorage.removeItem('fullUrl');

      const experiments = result.data.abTestExperiments || [];
      window.kameleoonQueue = window.kameleoonQueue || [];
      loadActiveExperiments(experiments);
    }
  } catch (e: any) {
    console.error('loadKameleoon | Error:', e);
  }
};

/**
 * Load Kameleoon data and set up AB tests for the visitor (Prequal)
 *
 * @return {Promise<void>} A promise that resolves once the Kameleoon data is loaded and
 * AB tests are set up.
 */
export const loadKameleoonFlagsAndTestsPrequal = async (
  prequal: Partial<Prequal> | null | undefined
): Promise<void> => {
  try {
    const kameleoonGcpFunctionUrl = isProduction()
      ? PROD_KAMELEOON_GCP_FUNCTION_URL
      : STAGE_KAMELEOON_GCP_FUNCTION_URL;
    if (
      (window.__AB_TEST__ ?? null) === null &&
      (prequal ?? null) !== null &&
      window.kameleoonDataPending !== true
    ) {
      window.kameleoonDataPending = true;
      const kameleoonCustomData: { [key: number]: any } = {};

      const result = await axios.post(kameleoonGcpFunctionUrl, {
        siteCode: resolveKameleoonSiteKey() || DEFAULT_KAMELEOON_SITEKEY,
        domain: window.location.hostname,
        fullUrl: localStorage.getItem('fullUrl') || window.location.href,
        kameleoonCustomData,
        ...(prequal &&
          prequal.visitorCode && {
            savedVisitorCode: prequal.visitorCode,
          }),
      });
      window.__AB_TEST__ = result.data;
      window.kameleoonDataPending = false;
      localStorage.removeItem('fullUrl');

      const experiments = result.data.abTestExperiments || [];
      window.kameleoonQueue = window.kameleoonQueue || [];
      loadActiveExperiments(experiments);
    }
  } catch (e: any) {
    console.error('loadKameleoon | Error:', e);
  }
};

export const getABTestConfiguration = () => {
  const { abTestConfiguration } = window.__AB_TEST__
    ? window.__AB_TEST__
    : { abTestConfiguration: [] };

  return abTestConfiguration;
};
