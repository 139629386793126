import React from 'react';
import Cleave from 'cleave.js/react';

const RateInput = ({ ...rest }) => (
  <Cleave
    options={{
      numeral: true,
      numericOnly: true,
      numeralPositiveOnly: true,
      numeralDecimalScale: 2,
    }}
    className="ant-input"
    onBlur={(event) => {
      // work around no event fired but prefix is added and placeholder label doesn't know about it
      rest?.onChange(event);
    }}
    inputMode="decimal"
    {...rest}
  />
);

export default RateInput;
