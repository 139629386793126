/* eslint-disable import/no-cycle */
import firebase from 'firebase/compat/app';
import { makeAutoObservable, toJS } from 'mobx';
import {
  loadKameleoonFlagsAndTestsPrequal,
  getQueryParam,
  isFromBDP,
  getImtId,
  getBoatInfoRaw,
  getPrequal,
  betterParseFloat,
  generateUUID,
} from '../utils';
import {
  Prequal,
  ShoppingSegmentType,
  FullApplicationStatus,
  CreditScoreRangeType,
  Marketing,
  PreQualUTM,
  LeadOrigin,
} from '../schema';
import { BOAT_TRADER_SOURCE_ID } from '../constants';
import { getNumericQueryParam } from '../utils/queryParamHelper';

export class PrequalStore {
  imtId: string | null = null;

  source: number | null = null;

  subsource: number | null = null;

  leadPage: string | null = null;

  boatInfo: string | null = null;

  prequalApplicationId: string | null = null;

  leadUUID: string | null = null;

  leadOrigin: LeadOrigin = LeadOrigin.prequal;

  isBDP: boolean = false;

  prequal: Partial<Prequal> = {};

  navDirection: 1 | -1 | 'other' = 1;

  keyCode: string | null = null;

  promoCode: string | null = null;

  repCode: string | null = null;

  subSourceLabel: string | null = null;

  sourceLabel: string | null = null;

  utm: PreQualUTM = {
    utmSource: null,
    utmMedium: null,
    utmTerm: null,
    utmContent: null,
    utmCampaign: null,
    utmId: null,
    utmName: null,
  };

  marketing: Marketing = {
    adGroup: null,
    adGroupId: null,
    campaignName: null,
    campaignId: null,
  };

  constructor() {
    this.prequalApplicationId = generateUUID();
    this.isBDP = isFromBDP();

    this.boatInfo = this.isBDP ? getBoatInfoRaw() : null;
    this.imtId = this.isBDP ? getImtId() : null;

    this.marketing.adGroupId = getNumericQueryParam({ param: 'adgroupid' });
    this.marketing.campaignId = getNumericQueryParam({ param: 'campaignid' });
    this.source = getNumericQueryParam({ param: 'source' }) || BOAT_TRADER_SOURCE_ID;
    this.subsource = getNumericQueryParam({ param: 'subsource' });

    this.keyCode = getQueryParam('keycode');
    this.leadPage = getQueryParam('leadPage');
    this.marketing.adGroup = getQueryParam('adgroup');
    this.marketing.campaignName = getQueryParam('campaign');
    this.promoCode = getQueryParam('promocode');
    this.repCode = getQueryParam('repid');
    this.leadOrigin = LeadOrigin.prequal;
    this.utm.utmCampaign = getQueryParam('utm_campaign');
    this.utm.utmContent = getQueryParam('utm_content');
    this.utm.utmId = getQueryParam('utm_id');
    this.utm.utmMedium = getQueryParam('utm_medium');
    this.utm.utmName = getQueryParam('utm_name');
    this.utm.utmSource = getQueryParam('utm_source');
    this.utm.utmTerm = getQueryParam('utm_term');
    makeAutoObservable(this);
  }

  setSourceLabel({
    sourceLabel,
    subSourceLabel,
  }: {
    sourceLabel: string | null;
    subSourceLabel: string | null;
  }) {
    this.prequal.sourceLabel = sourceLabel;
    this.prequal.subSourceLabel = subSourceLabel;

    this.sourceLabel = sourceLabel;
    this.subSourceLabel = subSourceLabel;
  }

  setPrequal(): any {
    this.prequal = getPrequal(this);
  }

  setShoppingSegment({ shoppingSegment }: { shoppingSegment: ShoppingSegmentType }): void {
    this.prequal.shoppingSegment = shoppingSegment;
  }

  setPurchasePrice({ purchasePrice }: { purchasePrice: string }): void {
    this.prequal.purchasePrice = betterParseFloat(purchasePrice);
  }

  setDownPayment({ downPayment }: { downPayment: string }): void {
    this.prequal.downPayment = betterParseFloat(downPayment);
    if (
      this.prequal.purchasePrice !== undefined &&
      this.prequal.purchasePrice !== null &&
      this.prequal.downPayment !== undefined &&
      this.prequal.downPayment !== null
    ) {
      this.prequal.loanAmount = this.prequal.purchasePrice - this.prequal.downPayment;
    }
  }

  setFicoScore({ creditScore }: { creditScore: CreditScoreRangeType }): void {
    this.prequal.creditScore = creditScore;
    this.prequal.completedAt = Date.now();
  }

  setAboutYou({
    firstName,
    lastName,
    email,
    phone,
  }: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  }): void {
    this.prequal.firstName = firstName;
    this.prequal.lastName = lastName;
    this.prequal.email = email;
    this.prequal.phone = phone;
  }

  setResults(): void {
    this.prequal.fullApplicationStatus = FullApplicationStatus.started;
    this.prequal.fullApplicationStartedAt = Date.now();

    const imtId = this.prequal?.imtId || 'NONBDP';
    const storageKey = `prequal-${imtId}`;
    localStorage.setItem(storageKey, JSON.stringify(this.prequal));
  }
}

let prequalStore: PrequalStore | null = null;
const w: any = window;

export function usePrequal(): PrequalStore {
  if (prequalStore) {
    return prequalStore;
  }

  prequalStore = new PrequalStore();
  prequalStore.setPrequal();

  loadKameleoonFlagsAndTestsPrequal(prequalStore.prequal);

  w.TF = w.TF || {};
  w.TF.prequalStore = prequalStore;
  return prequalStore;
}

export function getPrequalStore() {
  return prequalStore;
}

/**
 * for tests
 */
export function setPrequalStore(store: PrequalStore | null) {
  prequalStore = store;
}

w.toJS = toJS;
