import React from 'react';
import ReactDOM from 'react-dom';
import firebase from 'firebase/compat/app';
import { getStorage } from 'firebase/storage';
import 'firebase/compat/auth';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import App from './App';
import { prodFirebaseConfig, stageFirebaseConfig } from './config';
import { FirestoreContext } from './hooks/useFirestore';
import { FirestoreStorageContext } from './hooks/useFirestoreStorage';
import { AuthContext } from './hooks/useAuth';
import { isDev, isProduction, isStage } from './constants';

import './index.scss';

async function start() {
  let firebaseApp;
  if (isProduction()) {
    prodFirebaseConfig.authDomain = 'apply.tridentfunding.com';
    firebaseApp = firebase.initializeApp(prodFirebaseConfig);
  } else {
    prodFirebaseConfig.authDomain = 'stage-apply.tridentfunding.com';
    firebaseApp = firebase.initializeApp(stageFirebaseConfig);
  }

  const firestore = firebaseApp.firestore();
  const auth = firebase.auth();
  const functions = firebase.functions();
  const storage = getStorage(firebaseApp);

  if (isDev()) {
    firestore.useEmulator('localhost', 8080);
    auth.useEmulator('http://localhost:9099');
    functions.useEmulator('localhost', 5001);
    if (navigator.userAgent?.match(/CypressTest/)) {
      firestore.settings({ experimentalForceLongPolling: true, merge: true });
    }
  }

  ReactDOM.render(
    <React.StrictMode>
      <FirestoreStorageContext.Provider value={storage}>
        <FirestoreContext.Provider value={firestore}>
          <AuthContext.Provider value={auth}>
            <App />
          </AuthContext.Provider>
        </FirestoreContext.Provider>
      </FirestoreStorageContext.Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
}

start();
