export type TagsSet = {
  title: string;
  description: string;
};

export const getMetaTags = ({
  page,
  sourceName = 'Trident Funding LLC',
}: {
  page: string;
  sourceName: string;
}) => {
  const HEAD_META_TAGS: { [key: string]: TagsSet } = {
    home: {
      title: `Online Credit Application - ${sourceName}`,
      description: `${sourceName} offers multiple programs to purchase or refinance your boat, RV or aircraft. Check out your options and find the best rates available.`,
    },
    boat: {
      title: `Boat Loan Application - ${sourceName}`,
      description:
        'We specialize in boat loans for all types of new and used marine vessels: from ski/wake boats, center consoles, cruisers and more! Get on the water today.',
    },
    aircraft: {
      title: `Aircraft Loan Application - ${sourceName}`,
      description:
        'Apply now to find the best aircraft loans for purchases, refinancing, or upgrades with a variety of fixed rate programs. Let us help get you in the air!',
    },
    rv: {
      title: `RV Loan Application - ${sourceName}`,
      description:
        'Let us help you secure the best RV loan rates. Whether you’re looking for a camper or travel trailer, we can help you with a variety of fixed rate programs.',
    },
    default: {
      title: '',
      description:
        'Let us help you secure the best RV loan rates. Whether you’re looking for a camper or travel trailer, we can help you with a variety of fixed rate programs.',
    },
  };

  return HEAD_META_TAGS[page];
};
