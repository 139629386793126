import React, { useEffect } from 'react';
import { Col, Form, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { Link } from 'react-router-dom';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { getStepUrl, stepFinished } from '../../nav';
import { formatCurrency, pushToDataLayer } from '../../utils';
import { ReactComponent as EditIcon } from '../../icons/edit-icon.svg';

import styles from './financial.module.scss';
import classNames from 'classnames';
import { Borrower, LoanPurpose } from '../../schema';
import { Decimal } from 'decimal.js-light';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING, isProduction } from '../../constants';

type Props = {};

const centerSmallThree = { xs: 8, sm: 8, md: 6, lg: 6, xl: 6 };
const centerSmallOffset = { xs: 2, sm: 2, md: 4, lg: 4, xl: 4 };

const StatementSummaryStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'statement summary page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const borrower = loanStore.loan?.borrower as Borrower;
  const statement = borrower.financialStatement;

  const update = () => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">About You</div>}
      <h1 className="screen-only">
        {loan?.purpose === LoanPurpose.boat
          ? "Here's your financial statement"
          : 'Your Financial Statement'}
      </h1>
      <h1 className="print-only">
        Statement for {loan.borrower?.firstName} {loan.borrower?.lastName}
      </h1>

      <Form form={form} onFinish={update} initialValues={statement || {}}>
        <div
          className={classNames(styles.statementGrid, {
            onlyForBoats: loan?.purpose === LoanPurpose.boat,
          })}
        >
          {statement?.assets?.map((asset) => (
            <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
              <Col {...centerSmallOffset} className={styles.hideForMobile} />
              <Col {...centerSmallThree} className={styles.totalLabel}>
                {asset.name}
              </Col>
              <Col {...centerSmallThree} className={styles.totalAmount}>
                {formatCurrency(asset.value)}
              </Col>
            </Row>
          ))}
          {statement?.otherAssets?.map((asset) => (
            <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
              <Col {...centerSmallOffset} className={styles.hideForMobile} />
              <Col {...centerSmallThree} className={styles.totalLabel}>
                {asset.name}
              </Col>
              <Col {...centerSmallThree} className={styles.totalAmount}>
                {formatCurrency(new Decimal(asset.value).sub(asset.balance || 0))}
              </Col>
            </Row>
          ))}

          <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
            <Col {...centerSmallOffset} className={styles.hideForMobile} />
            <Col {...centerSmallThree} className={styles.totalLabel}>
              <strong>Total Assets:</strong>
            </Col>
            <Col {...centerSmallThree} className={styles.totalAmount}>
              <strong>{formatCurrency(loanStore.totalAssets)}</strong>
            </Col>
            <Col {...centerSmallThree} className={classNames(styles.totalEdit, 'screen-only')}>
              <Link className="editAssetsIconLabel" to={getStepUrl('assets')}>
                <EditIcon className="editAssetsIcon" />
                Edit
              </Link>
            </Col>
          </Row>

          <br />
          {statement?.realEstate?.map((real) => (
            <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
              <Col {...centerSmallOffset} className={styles.hideForMobile} />
              <Col {...centerSmallThree} className={styles.totalLabel}>
                {real.name}
              </Col>
              <Col {...centerSmallThree} className={styles.totalAmount}>
                {formatCurrency(new Decimal(real.value).sub(real.balance || 0))}
              </Col>
            </Row>
          ))}

          <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
            <Col {...centerSmallOffset} className={styles.hideForMobile} />
            <Col {...centerSmallThree} className={styles.totalLabel}>
              <strong>Total Real Estate:</strong>
            </Col>
            <Col {...centerSmallThree} className={styles.totalAmount}>
              <strong>{formatCurrency(loanStore.totalRealEstate)}</strong>
            </Col>
            <Col {...centerSmallThree} className={classNames(styles.totalEdit, 'screen-only')}>
              <Link className="editAssetsIconLabel" to={getStepUrl('real-estate')}>
                <EditIcon className="editAssetsIcon" />
                Edit
              </Link>
            </Col>
          </Row>

          <br />
          {statement?.liabilities?.map((liability) => (
            <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
              <Col {...centerSmallOffset} className={styles.hideForMobile} />
              <Col {...centerSmallThree} className={styles.totalLabel}>
                {liability.name}
              </Col>
              <Col {...centerSmallThree} className={styles.totalAmount}>
                -{formatCurrency(liability.balance)}
              </Col>
            </Row>
          ))}

          <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
            <Col {...centerSmallOffset} className={styles.hideForMobile} />
            <Col {...centerSmallThree} className={styles.totalLabel}>
              <strong>Total Liabilities:</strong>
            </Col>
            <Col {...centerSmallThree} className={styles.totalAmount}>
              <strong>-{formatCurrency(loanStore.totalDebt)}</strong>
            </Col>
            <Col {...centerSmallThree} className={classNames(styles.totalEdit, 'screen-only')}>
              <Link className="editAssetsIconLabel" to={getStepUrl('liabilities')}>
                <EditIcon className="editAssetsIcon" />
                Edit
              </Link>
            </Col>
          </Row>

          <Row className={`${styles.noWrapInMobile} marginBottomSubZero`}>
            <Col {...centerSmallOffset} className={styles.hideForMobile} />
            <Col {...centerSmallThree} className={styles.totalLabel}>
              <strong>Net Worth:</strong>
            </Col>
            <Col {...centerSmallThree} className={styles.totalAmount}>
              <strong>{formatCurrency(loanStore.netWorth)}</strong>
            </Col>
          </Row>
        </div>

        <ButtonNav />
        <StepFooter type="secure" />
      </Form>
    </LoanLayout>
  );
});

export default StatementSummaryStep;
