/* eslint-disable import/no-cycle */
import {
  combineSkip,
  neverSkip,
  skipCoBorrower,
  skipCoEmployer,
  skipEmployer,
  skipIfNoFinancialStatement,
  skipIfNotCashRecapture,
  skipIfNotRefinance,
  skipIfRefinance,
  transition,
} from '../nav';

export const ALL_ROUTES_OPTIMIZE_V2 = [
  'type',
  'details',
  'finance',
  'seller',
  'collateral',
  'contact',
  'address',
  'income',
  'employer',
  'assets',
  'real-estate',
  'other-assets',
  'liabilities',
  'statement-summary',
  'borrower',
  'coborrower-income',
  'coborrower-employer',
  'review',
  'submit',
  'saved',
  'success',
  'TERMINAL',
] as const;

export const processTransitionsOptimizeV2 = () => {
  transition('type', { to: 'details', skip: neverSkip });
  // Finance Step
  transition('details', { to: 'finance', skip: skipIfRefinance });
  transition('finance', { to: 'refinance', skip: skipIfNotRefinance });
  transition('refinance', { to: 'cash-recapture', skip: skipIfNotCashRecapture });
  transition('cash-recapture', { to: 'seller', skip: neverSkip });

  transition('seller', { to: 'collateral', skip: neverSkip });
  transition('collateral', { to: 'contact', skip: skipIfRefinance });

  transition('contact', { to: 'address', skip: neverSkip });
  transition('address', { to: 'income', skip: neverSkip });
  transition('income', { to: 'employer', skip: skipEmployer });
  // Coborrower Step
  transition('employer', {
    to: 'coborrower-income',
    skip: skipCoBorrower,
  });
  transition('coborrower-income', {
    to: 'coborrower-employer',
    skip: combineSkip(skipCoBorrower, skipCoEmployer),
  });
  transition('coborrower-employer', { to: 'borrower', skip: neverSkip });
  transition('borrower', { to: 'review', skip: neverSkip });
  transition('review', { to: 'submit', skip: neverSkip });
  transition('submit', { to: 'TERMINAL', skip: neverSkip });
};
