import React from 'react';
import { Input, Select, Spin } from 'antd';
import { debounce } from 'ts-debounce';
import { action } from 'mobx';
import AppFormItem from '../../loan/components/AppFormItem';
import { Address } from '../../schema';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';

type Props = {
  // current value
  autoComplete?: string | undefined;
  onSelect: (address: Address) => void;
  restrictions?: any | null;

  // allow rest props for Input
  [x: string]: any;
};

const AddressAutocomplete: React.FC<Props> = ({
  onSelect,
  autoComplete,
  restrictions,
  ...rest
}) => {
  const [fetching, setFetching] = React.useState(false);
  const [options, setOptions] = React.useState<any>([]);
  const [sessionToken, setSessionToken] = React.useState(
    new google.maps.places.AutocompleteSessionToken()
  );
  const fetchRef = React.useRef(0);
  const [autocompleteService, setAutoCompleteService] =
    React.useState<google.maps.places.AutocompleteService | null>(null);

  React.useEffect(() => {
    // @ts-ignore
    window.mapsPromise.then(() => {
      setAutoCompleteService(new window.google.maps.places.AutocompleteService());
    });
  }, [setAutoCompleteService]);

  const debounceFetcher = React.useMemo(() => {
    const loadOptions = (value: string) => {
      if (!autocompleteService) return;
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      autocompleteService.getPlacePredictions(
        {
          input: value,
          ...restrictions,
          sessionToken,
        },
        (predictions, status) => {
          setFetching(false);
          if (fetchId !== fetchRef.current) {
            // for fetch callback order
            return;
          }
          if (!predictions) return;
          if (status !== google.maps.places.PlacesServiceStatus.OK) {
            console.error('got status %O', status);
            return;
          }
          setOptions(
            predictions.map((p) => ({
              label: p.description,
              value: p.place_id,
            }))
          );
        }
      );
    };

    return debounce(loadOptions, 200);
  }, [sessionToken, autocompleteService, restrictions]);

  const handleSearch = (value: string) => {
    if (autocompleteService === null) return;
    // noinspection JSIgnoredPromiseFromCall
    debounceFetcher(value);
  };

  // get the place details for the caller
  const selectPlace = (placeId: string) => {
    // @ts-ignore
    const places: PlacesService = new window.google.maps.places.PlacesService(
      // @ts-ignore
      document.getElementById('places-attribution')
    );
    places.getDetails(
      { placeId, fields: ['address_components'], sessionToken },
      action((result: any, status: any) => {
        if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
          console.error('auto complete request failed %O with status %O', result, status);
        }
        // on success we discard this token by creating a new one
        setSessionToken(new google.maps.places.AutocompleteSessionToken());

        // result is https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult

        // make this usable
        // @ts-ignore
        const selected = result.address_components.reduce(
          // @ts-ignore
          (seed: any, { short_name, long_name, types }) => (
            // @ts-ignore
            // eslint-disable-next-line no-sequences
            types.forEach((t) => (seed[t] = short_name || long_name)), seed
          ),
          {}
        );

        onSelect({
          address1: [selected.street_number, selected.route].join(' '),
          city: selected.locality || selected.neighborhood || selected.postal_town,
          county: selected.administrative_area_level_2,
          state: selected.administrative_area_level_1,
          zip: selected.postal_code,
          country: selected.country,
        });
      })
    );
  };

  // trick ts
  const inputRest = {
    autoComplete,
    disabled: rest.disabled,
  };

  return (
    <AppFormItem noStyle {...rest}>
      <Select
        suffixIcon={<SelectArrowIcon />}
        showSearch
        allowClear
        filterOption={false}
        onSearch={handleSearch}
        onSelect={selectPlace}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        options={options}
        {...inputRest}
      />
    </AppFormItem>
  );
};

const AddressWrap: React.FC<Props> = (props) => {
  if (
    window.google?.maps?.places?.AutocompleteSessionToken &&
    window.google?.maps?.places?.AutocompleteService
  ) {
    return <AddressAutocomplete {...props} />;
  }

  return (
    <AppFormItem noStyle {...props}>
      <Input disabled={props.disabled} />
    </AppFormItem>
  );
};

export default AddressWrap;
