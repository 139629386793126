import React, { useEffect } from 'react';
import { Form, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { runInAction } from 'mobx';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import CollateralForm from '../components/CollateralForm';
import { collateralMinYear, pushToDataLayer } from '../../utils';
import { LoanPurpose } from '../../schema';
import useConfig from '../../stores/config';
import { StepFooter } from '../components/StepFooter/StepFooter';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING, isProduction } from '../../constants';

const { Text } = Typography;

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const CollateralStep: React.FC<Props> = observer(() => {
  const config = useConfig();
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'collateral page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  if (!loan || loanStore.status === 'pending' || !config) {
    return <Loading />;
  }
  const { purpose } = loan;
  const collateral = loanStore.getCollateral();
  const currentYear = new Date().getFullYear();
  const maxYear = currentYear + 1;
  const minYear = collateralMinYear(config, purpose, collateral.fuel, collateral.type);
  const yearRange = [];

  for (let i = maxYear; i >= minYear; i--) {
    yearRange.push(i);
  }

  const restrictPassingConditions = (): { isRestricted: boolean; message: string } => {
    const GAS: string = 'gas';
    let isRestricted: boolean = false;
    let message: string = '';

    if (Object.keys(collateral).length && purpose === LoanPurpose.rv) {
      const fuel = collateral?.fuel;
      const year = collateral?.year || 0;
      const minAllowedAge = collateralMinYear(
        config,
        LoanPurpose.rv,
        collateral?.fuel,
        collateral?.type
      );
      const mileage = collateral?.mileage || 0;

      const isOlderThanExpected: boolean = year > 0 && year < minAllowedAge;

      const exceedsMileage: boolean =
        fuel && fuel.includes(GAS) ? mileage > 100_000 : mileage > 150_000;

      if (isOlderThanExpected) {
        isRestricted = true;
        message = 'At this time we do not have a program based on the age of the RV.';
      } else if (exceedsMileage) {
        isRestricted = true;
        message = 'At this time we do not have a program based on the mileage of the RV.';
      }
    }

    return { isRestricted, message };
  };

  const { isRestricted, message } = restrictPassingConditions();

  const update = (values: any) => {
    if (values.model === 'None of these') {
      runInAction(() => {
        collateral.model = null;
      });
    }

    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">Boat info</div>}
      <h1>
        {loan?.purpose === LoanPurpose.boat
          ? `Tell us a little about the boat`
          : `What type of ${purpose} are you financing?`}
      </h1>
      {loan?.purpose === LoanPurpose.boat && <p>Enter the boat details</p>}
      <Form form={form} {...formItemLayout} initialValues={collateral} onFinish={update}>
        <CollateralForm form={form} yearRange={yearRange} collateral={collateral} loan={loan} />

        {message && <Text type="danger">{message}</Text>}

        <ButtonNav isNextDisabled={isRestricted} />
        <StepFooter type="info" />
      </Form>
    </LoanLayout>
  );
});

export default CollateralStep;
