import React, { useEffect } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import TextArea from 'antd/es/input/TextArea';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from './meta';
import AppFormItem from '../components/AppFormItem';
import { pushToDataLayer } from '../../utils';
import { isProduction } from '../../constants';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const schemaValidator = createSchemaValidator(pickFields('experience'));

const ExperienceStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const maxYear = new Date().getFullYear() + 1;

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'experience page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const { purpose, collateral } = loan;

  const update = (values: any) => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <LoanLayout>
      <h1>{purpose} experience</h1>
      <Form form={form} {...formItemLayout} initialValues={collateral || {}} onFinish={update}>
        <AppFormItem
          name="boatingExperience"
          rules={[
            { required: true, message: 'Please enter some text' },
            schemaValidator('boatingExperience', collateral),
          ]}
        >
          <PlaceholderLabel label="Please describe your boating experience and last 2 boats owned">
            <TextArea />
          </PlaceholderLabel>
        </AppFormItem>
        <ButtonNav />
      </Form>
    </LoanLayout>
  );
});

export default ExperienceStep;
