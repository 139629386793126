import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import * as firebaseui from 'firebaseui';
import { useAuth } from '../../hooks/useAuth';
import firebase from 'firebase/compat/app';

import 'firebaseui/dist/firebaseui.css';
import { Spin } from 'antd';
import { useUser } from '../../hooks/useUser';
import useHistory from '../../hooks/useHistory';
import { useLoan } from '../../stores/store';

type Props = {
  onSignedIn: () => void;
};

let ui: null | firebaseui.auth.AuthUI = null;

export default observer(function AuthForm({ onSignedIn }: Props) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const ref = useRef(null);
  const auth = useAuth();
  const history = useHistory();
  const user = useUser();
  const loanStore = useLoan();

  useEffect(() => {
    if (!ui) {
      ui = new firebaseui.auth.AuthUI(auth);
    }

    ui.start('#firebase-auth-ui', {
      callbacks: {
        signInFailure(error) {
          setLoading(true);
          loanStore.setIsUpgradingUser(true);
          const anonUser = auth.currentUser;
          if (!anonUser) {
            setErrorMessage('Missing user! Please try again or refresh the page.');
            setLoading(false);
            return Promise.resolve();
          }
          if (error.code !== 'firebaseui/anonymous-upgrade-merge-conflict') {
            setErrorMessage(
              'Sorry! An unknown error happened. Please try again or refresh the page.'
            );
            setLoading(false);
            return Promise.resolve();
          }
          // The credential the user tried to sign in with.
          const cred = error.credential;

          const functions = firebase.functions();
          const upgradeUser = functions.httpsCallable('upgradeUser');

          const payload = {
            token: cred.idToken || JSON.stringify({ email: cred._email }),
          };

          // idToken is not present unless user uses 3rd party auth
          return upgradeUser(payload)
            .then(() => firebase.auth().signInWithCredential(cred))
            .then(() => {
              setLoading(false);
              loanStore.setIsUpgradingUser(false);
            });
        },
        signInSuccessWithAuthResult() {
          onSignedIn();
          if (history.location.pathname === '/logout') history.push('/');
          return false;
        },
        uiShown() {
          setLoading(false);
        },
      },

      autoUpgradeAnonymousUsers: true,
      signInFlow: 'popup',

      signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          scopes: ['https://www.googleapis.com/auth/userinfo.email'],
        },
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
      ],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth, onSignedIn, history.location.pathname]);

  if (user !== null && !user.isAnonymous) {
    // we logged in
    // https://github.com/firebase/firebaseui-web-react/issues/91
    // can't do during render
    setTimeout(() => {
      onSignedIn();
      if (history.location.pathname === '/logout') history.push('/');
    }, 1);
    return null;
  }

  return (
    <div>
      <h3>
        <b>Sign In</b>
      </h3>
      <h4>Login to save your application or continue where you left off.</h4>
      {errorMessage && <p>{errorMessage}</p>}
      {loading ? (
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      ) : (
        <div id="firebase-auth-ui" ref={ref} />
      )}
    </div>
  );
});
