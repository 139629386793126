import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useHistory from '../hooks/useHistory';
import PurchaseTimeframeStep from './steps/PurchaseTimeframeStep';
import BoatPriceStep from './steps/BoatPriceStep';
import DownPaymentStep from './steps/DownPaymentStep';
import AboutYouStep from './steps/AboutYouStep';
import FicoStep from './steps/FicoStep';
import ResultsStep from './steps/ResultsStep';
import { useUser } from '../hooks/useUser';
import { observer } from 'mobx-react-lite';
import useConfig from '../stores/config';
import { Spin } from 'antd';
import PageNotFound from './components/PageNotFound';
import SplashPage from './SplashPage';

export default observer(function PreQualFunnelIndex() {
  const history = useHistory();
  const user = useUser();
  const location = useLocation();

  // preload sources
  const config = useConfig();
  if (!user || !config) {
    return <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />;
  }

  return (
    <>
      <TransitionGroup>
        <CSSTransition
          key={location.key}
          classNames="fade"
          timeout={250}
          unmountOnExit
          mountOnEnter={false}
        >
          <Router history={history}>
            <div>
              <Switch location={location}>
                <Route path="/pre-qualify/enter" exact>
                  <SplashPage />
                </Route>
                <Route path="/pre-qualify/purchase-timeframe">
                  <PurchaseTimeframeStep />
                </Route>
                <Route path="/pre-qualify/boat-price">
                  <BoatPriceStep />
                </Route>
                <Route path="/pre-qualify/down-payment">
                  <DownPaymentStep />
                </Route>
                <Route path="/pre-qualify/about-you">
                  <AboutYouStep />
                </Route>
                <Route path="/pre-qualify/credit">
                  <FicoStep />
                </Route>
                <Route path="/pre-qualify/results">
                  <ResultsStep />
                </Route>
                <Route path="*">
                  <PageNotFound />
                </Route>
              </Switch>
            </div>
          </Router>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
});
