import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../stores/store';
import useConfig from '../../stores/config';
import { useTheme } from '../../hooks/useTheme';
import { unloadPrequalOverrides } from '../../utils';
import BlankLayout from '../BlankLayout';
import { Link } from 'react-router-dom';

type Props = {};

const PageNotFound: React.FC<Props> = () => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const config = useConfig();
  const theme = useTheme(loanStore, config, true);

  if (theme != null) {
    unloadPrequalOverrides(theme, loan);
  }

  return (
    <BlankLayout title="Page Not Found">
      <p style={{ textAlign: 'center' }}>
        Page Not Found! <Link to="/">Click here to start a new application.</Link>
      </p>
    </BlankLayout>
  );
};

export default PageNotFound;
