import React from 'react';
import { observer } from 'mobx-react-lite';
import { Form } from 'antd';
import styles from './PhoneInput.module.scss';
import { NamePath } from 'antd/lib/form/interface';
import { PhoneInput as BasePhoneInput } from '../../../loan/components/PhoneInput';

type Props = {
  name: NamePath;
  labelText: string;
  subLabelText?: string;
  validator?: (_: any, value: any) => void | Promise<any>;
};

const PhoneInput: React.FC<Props> = observer((props) => {
  const { name, labelText, validator } = props;
  return (
    <>
      <div className={styles.labelContainer}>
        <div className={styles.labelText}>{labelText}</div>
      </div>
      <Form.Item label={name} name={name} rules={[{ validator }]}>
        <BasePhoneInput placeholder={`Enter ${labelText}`} className={styles.phoneInputField} />
      </Form.Item>
    </>
  );
});

export default PhoneInput;
