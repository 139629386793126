import React from 'react';
import classNames from 'classnames';
import { useFormItem } from '../../hooks/useFormItem';

type Props = {
  children?: React.ReactNode;
  className?: string;
  label: React.ReactNode;
  postLabel?: boolean;
  twoLine?: boolean;
};

const ManualLabel: React.FC<Props> = ({
  children,
  className,
  label,
  postLabel = false,
  twoLine = false,
  ...rest
}) => {
  const formItemProps = useFormItem();
  const inputProps: any = rest;
  const { value } = inputProps;
  const hasValue = value !== null && value !== undefined && String(value).length > 0;

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      // mix in new props
      return React.cloneElement(child, inputProps);
    }
    return child;
  });

  const Label = () => (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label className={classNames('manual', twoLine && 'two-line', className)}>
      <span>
        {label}
        <span>
          {formItemProps.required && (
            <span className={classNames(!hasValue && 'required-mark')}> *</span>
          )}
        </span>
      </span>
    </label>
  );

  return (
    <>
      {!postLabel && <Label />} {childrenWithProps} {postLabel && <Label />}
    </>
  );
};

export default ManualLabel;
