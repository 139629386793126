import React from 'react';
import styles from './ResultsTooltip.module.scss';
import { ReactComponent as InfoIcon } from '../../../icons/info.svg';
import { Tooltip } from 'antd';

type Props = {
  tooltip: { title: String; content: JSX.Element | String };
};

export const loanAmountToolTip = {
  title: 'What is loan amount?',
  content: (
    <div className={styles.loanAmount}>
      <div>How do we determine the loan amount?</div>
      <div className={styles.summatory}>
        <div>Purchase Price</div>
        <div>&#8212; Down-payment</div>
        <div>Loan Amount</div>
      </div>
    </div>
  ),
};

export const prequalApprovalTermToolTip = {
  title: 'How do we calculate the term?',
  content:
    'We’ll go over your term options when you apply so you can pick the term options that work best for you.',
};

export const creditScoreToolTip = {
  title: 'How did we get Credit Score?',
  content: 'Based on the information you provided.',
};

export const prequalApprovalRateToolTip = {
  title: 'How do we calculate APR?',
  content: 'We pull in current APR’s based on the information you provided',
};

const ResultsTooltip: React.FC<Props> = (props) => {
  const { tooltip } = props;

  return (
    <Tooltip
      destroyTooltipOnHide
      placement="right"
      overlay={
        <div className={styles.tooltipContent}>
          <div className={styles.tooltipTitle}>{tooltip.title}</div>
          <div className={styles.tooltipText}>{tooltip.content}</div>
        </div>
      }
      overlayClassName={styles.tooltip}
      overlayInnerStyle={{ padding: 0, boxShadow: 'none' }}
      trigger="click"
      color="#FFF"
    >
      <InfoIcon />
    </Tooltip>
  );
};

export default ResultsTooltip;
