import React, { FC, useEffect, useState } from 'react';
import useHistory from '../../../hooks/useHistory';
import { requiredSteps, Route } from '../../../nav';
import { useLoan } from '../../../stores/store';
import { getUrlCurrentStep } from '../../../utils';
import { stepMeta } from '../../steps/meta';
import ProgressBarStep, { ProgressBarStepProps } from './ProgressBarStep';
import styles from './ProgressBar.module.scss';
import { useFeatureFlags } from '../../../hooks/useFeatureFlags';
import { FullAppOptimizeV1Flag } from '../../../featureFlags';

const getStepImage = (history: any) => {
  const step = (getUrlCurrentStep(history.location.pathname) as Route) || 'details';

  switch (step) {
    case 'collateral':
    case 'seller':
    case 'finance':
      return '/static/trident/Progress-Bar-Boat-Info.svg';
    case 'contact':
    case 'address':
    case 'income':
    case 'employer':
    case 'coborrower-income':
    case 'borrower':
      return '/static/trident/Progress-Bar-About-You.svg';
    case 'review':
      return '/static/trident/Progress-Bar-Review.svg';
    case 'submit':
    case 'success':
      return '/static/trident/Progress-Bar-Confirm.svg';
    default:
      return '/static/trident/Progress-Bar-Boat-Info.svg';
  }
};

const ProgressBar: FC = () => {
  const loanStore = useLoan();
  const history = useHistory();

  const [steps, setSteps] = useState<ProgressBarStepProps[]>([]);

  const { loan } = loanStore;
  const activeFeatureFlags = useFeatureFlags();
  const isNewFunnel = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];

  useEffect(() => {
    const newSteps: ProgressBarStepProps[] = [];
    const allSteps = requiredSteps.get();
    if (loan) {
      const current = allSteps.indexOf(
        (getUrlCurrentStep(history.location.pathname) as Route) || 'details'
      );

      let nextSectionHeadIndex = Infinity;
      for (let i = stepMeta.length - 1; i >= 0; i--) {
        const meta = stepMeta[i];
        const metaIndex = allSteps.indexOf(meta.step);

        if (meta.sectionHead && metaIndex > -1) {
          const seen = metaIndex <= current;
          const completed = nextSectionHeadIndex <= current;
          const label = meta.step === 'collateral' ? `${loan?.purpose} Info` : meta.label;
          newSteps.push({
            label,
            position: newSteps.length === 0 ? 'end' : 'middle',
            status: completed ? 'done' : seen ? 'current' : 'pending',
            step: meta.step,
          });
          nextSectionHeadIndex = metaIndex;
        }
      }
      newSteps.reverse();
      newSteps[0].position = 'start';
      setSteps(newSteps);
    }
  }, [loan, history]);

  return isNewFunnel ? (
    <div className={styles.progressBarImageContainer}>
      <img className={styles.progressBarImage} src={getStepImage(history)} alt="BoatInfo" />
    </div>
  ) : (
    <div className={styles.progressBar}>
      {/* another div is required to center the whole bar */}
      <div>
        {steps.map((step) => (
          <ProgressBarStep key={step.step} {...step} />
        ))}
      </div>
    </div>
  );
};

export default ProgressBar;
