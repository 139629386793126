import React from 'react';
import { Col, Input, Row } from 'antd';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { rowLayout } from '../layout';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from '../steps/meta';
import AppFormItem from './AppFormItem';
import { Borrower } from '../../schema';

import styles from './BorrowerForm.module.scss';

const column12 = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };
const column12Mobile = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
const schemaValidator = createSchemaValidator(pickFields('borrower'));

type Props = {
  formItemPrefix?: string;
  borrower: Borrower;
};

export const BorrowerNameForm = ({ borrower, formItemPrefix = '' }: Props) => {
  const composeName = (name: string) => `${formItemPrefix}${name}`;

  return (
    <>
      <Row {...rowLayout} className={styles.mobileSpacing}>
        <Col {...column12Mobile}>
          <AppFormItem
            name={composeName('firstName')}
            required
            initialValue={borrower.firstName}
            hasFeedback
            rules={[
              schemaValidator('firstName', borrower, {
                message: 'Please enter your first name',
              }),
            ]}
          >
            <PlaceholderLabel label="First Name" htmlFor="firstName">
              <Input autoComplete="given-name" />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>
        <Col {...column12Mobile}>
          <AppFormItem
            name={composeName('middleInitial')}
            initialValue={borrower.middleInitial}
            hasFeedback
            rules={[schemaValidator('middleInitial', borrower)]}
          >
            <PlaceholderLabel label="Middle Int." htmlFor="middleInitial">
              <Input maxLength={10} />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>
      </Row>
      <Row {...rowLayout} className={styles.mobileSpacing}>
        <Col {...column12}>
          <AppFormItem
            name={composeName('lastName')}
            required
            initialValue={borrower.lastName}
            rules={[
              schemaValidator('lastName', borrower, {
                message: 'Please enter your last name',
              }),
            ]}
          >
            <PlaceholderLabel label="Last Name" htmlFor="lastName">
              <Input autoComplete="family-name" />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>
      </Row>
    </>
  );
};
