import React from 'react';
import Cleave from 'cleave.js/react';

const MoneyInput = ({ ...rest }) => (
  <Cleave
    options={{
      prefix: '$ ',
      noImmediatePrefix: true,
      rawValueTrimPrefix: true,
      numeral: true,
      numericOnly: true,
      numeralPositiveOnly: true,
      numeralDecimalScale: 2,
    }}
    className="ant-input"
    onBlur={(event) => {
      // work around no event fired but prefix is added and placeholder label doesn't know about it
      rest?.onChange(event);
    }}
    inputMode="decimal"
    {...rest}
  />
);

export default MoneyInput;
