import { ALL_FEATURE_FLAGS, FeatureFlag } from '../featureFlags';
import { findFeatureFlagActive } from '../utils';
import { getABTestConfiguration } from '../utils/kameleoonHelper';
import { KameleoonFeatureFlag } from './useFeatureFlag';

export const useFeatureFlags = () => {
  const abTestConfiguration = getABTestConfiguration();
  const featureFlags: { [flag: KameleoonFeatureFlag]: FeatureFlag } = {};

  ALL_FEATURE_FLAGS.forEach((ff) => {
    const kameleoonFlag = findFeatureFlagActive(abTestConfiguration, ff.name);

    if (kameleoonFlag) {
      if (ff.isActive(kameleoonFlag?.variation)) {
        const featureFlag = new FeatureFlag(ff.name, ff.variations, kameleoonFlag?.variation);
        featureFlags[ff.name] = featureFlag;
      }
    }
  });

  return featureFlags;
};
