import { useCallback, useEffect } from 'react';
import useConfig, { ConfigStore } from '../stores/config';
import { PrequalStore } from '../stores/prequalStore';
import { LoanPurpose, Source } from '../schema';

const getSubSource = ({
  config,
  sourceId,
  subSourceId,
}: {
  config: ConfigStore | null;
  sourceId: number | undefined;
  subSourceId?: number | null | undefined;
}) => {
  if (!config) {
    return {};
  }

  let source;
  let subSource;

  try {
    const hasSourceId = !!sourceId || sourceId === 0;
    const canGetSubSource = !!subSourceId || subSourceId === 0;

    source = hasSourceId
      ? config.getSource(sourceId)
      : config.getSourceFromLocation(window.location.href);

    if (hasSourceId && canGetSubSource) {
      subSource = config.getSubSource(source, subSourceId);
    }
  } catch (e) {
    console.error(e);
  }

  return { source, subSource };
};

export const usePrequalSubsource = (prequalStore: PrequalStore) => {
  const config = useConfig();
  const { prequal } = prequalStore;

  const updateSources = useCallback(() => {
    const { source, subSource } = getSubSource({
      config,
      sourceId: prequal.source,
      subSourceId: prequal.subsource,
    });

    const shouldUseValue =
      subSource && (subSource.isReferral || subSource.products.includes(LoanPurpose.boat));
    const subSourceLabel = shouldUseValue ? subSource.name : prequal.subSourceLabel || null;

    prequalStore.setSourceLabel({ subSourceLabel, sourceLabel: source?.name || null });
  }, [config, prequal.source, prequal.subSourceLabel, prequal.subsource, prequalStore]);

  useEffect(() => {
    updateSources();
  }, [updateSources]);
};
