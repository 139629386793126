import React from 'react';
import { LoanPurpose, Source } from '../../../schema';

function removeDashes(phoneNumber: string | undefined): string | undefined {
  let result;
  if (phoneNumber) {
    result = phoneNumber.replace(/-/g, '');
  }

  return result;
}

export function fetchRepidPhone(repCode: string | null, salesReps: any | null): string | undefined {
  const params = new URLSearchParams(window.location.search);
  const repidParam = repCode || params.get('repid');
  let repidPhone;

  if (repidParam && salesReps[repidParam] && salesReps[repidParam].phone) {
    repidPhone = salesReps[repidParam].phone;
  }

  return repidPhone;
}

export function fetchRepidFax(
  repCode: string | null,
  salesReps: any | null,
  offices: any | null
): string | undefined {
  const params = new URLSearchParams(window.location.search);
  const repidParam = repCode || params.get('repid');
  let repidPhone;

  if (repidParam && salesReps[repidParam]?.officeId) {
    const { officeId } = salesReps[repidParam];

    if (offices[officeId]?.fax) {
      repidPhone = offices[officeId].fax;
    }
  }

  return repidPhone;
}

export function renderNumber({
  number,
  label = 'Ph:',
}: {
  [_key: string]: any;
}): JSX.Element | null {
  const sourceNumber = number?.trim();
  if (!sourceNumber) return null;

  const actionableNumber = removeDashes(sourceNumber);

  return (
    <a href={`tel:${actionableNumber}`}>
      <b>{label}</b> {sourceNumber}
    </a>
  );
}

export function renderMainNumber(
  purpose: LoanPurpose | undefined | null,
  source: Source | null,
  repIdPhone: string | null | undefined,
  label = 'Ph:'
): JSX.Element | null {
  let sourceNumber;

  if (repIdPhone) {
    sourceNumber = repIdPhone;
  } else if (!purpose) {
    sourceNumber = source?.marinePhone;
  } else {
    sourceNumber = purpose === LoanPurpose.rv ? source?.phone : source?.marinePhone;
  }

  return renderNumber({ number: sourceNumber, label });
}

export function renderFaxNumber(
  purpose: LoanPurpose | undefined | null,
  source: Source | null,
  repIdPhone: string | null | undefined
): JSX.Element | null {
  let sourceNumber;

  if (repIdPhone) {
    sourceNumber = repIdPhone;
  } else if (!purpose) {
    sourceNumber = source?.marineFax;
  } else {
    sourceNumber = purpose === LoanPurpose.rv ? source?.fax : source?.marineFax;
  }

  return renderNumber({ number: sourceNumber, label: 'Fax:' });
}
