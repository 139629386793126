/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';
import useHistory from '../../hooks/useHistory';
import { LeadOrigin, Prequal } from '../../schema';
import { usePrequal } from '../../stores/prequalStore';
import { formatCurrency, pushToDataLayer } from '../../utils';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import ResultsTooltip, {
  prequalApprovalRateToolTip,
  prequalApprovalTermToolTip,
  creditScoreToolTip,
  loanAmountToolTip,
} from '../components/ResultsTooltip/ResultsTooltip';
import SectionHeader, { HeaderProps } from '../components/SectionHeader/SectionHeader';
import styles from './ResultsStep.module.scss';
import { gotoStep } from '../../prequalNav';
import PreQualDisclaimer from '../components/Disclaimer/Disclaimer';
import { isBoatTrader, isDev } from '../../constants';

type SummaryType = {
  hasDisclaimer?: boolean;
  name: String;
  tooltip?: JSX.Element | null;
  value?: String | null;
};

const ResultsStep = observer(() => {
  const prequalStore = usePrequal();
  const history = useHistory();
  const {
    prequal,
    boatInfo,
    isBDP,
    source,
    subsource,
    leadPage,
    utm = null,
    marketing = null,
  } = prequalStore;
  const [form] = useForm();
  const [summary, setSummary] = useState<SummaryType[]>([]);
  const [preApproved, setPreApproved] = useState(false);
  const [header, setHeader] = useState<HeaderProps>({ title: '', subtitle: '', content: '' });
  const [applyLink, setApplyLink] = useState('');

  const goToApplyNowLink = () => {
    prequalStore.setResults();
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'Apply now',
    });
    window.open(applyLink, '_blank');
  };

  const goToEditLink = () => {
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'edit_application',
    });
    gotoStep(history, 'purchase-timeframe');
  };

  const getYears = (months: number | null = 0) => (months ?? 0) / 12;

  const approved = prequal?.prequalApprovalStatus === 'Approved';

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'pre-qualify results',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: approved ? 'Pre-qual Approved' : 'Pre-qual Rejected',
      site_section: 'loan application',
    });
  }, [approved]);

  useEffect(() => {
    const {
      purchasePrice,
      downPayment,
      loanAmount,
      creditScore,
      prequalApprovalRate,
      prequalApprovalTerm,
      prequalApprovalMonthlyPayment,
      leadUUID,
      firstName,
      keyCode,
      promoCode,
      leadOrigin,
      repCode,
      imtId,
    } = prequal as Prequal;

    let boatLink =
      `${isBoatTrader() || isDev() ? '/boat-loans' : ''}/apply` +
      `/?purpose=Boat&prequal=true&prequalImtId=${imtId}` +
      `${leadOrigin ? `&leadOrigin=${leadOrigin}` : ''}` +
      `${source ? `&source=${source}` : ''}` +
      `${subsource ? `&subsource=${subsource}` : ''}` +
      `${leadPage ? `&leadPage=${leadPage}` : ''}` +
      `${keyCode ? `&keycode=${keyCode}` : ''}` +
      `${promoCode ? `&promocode=${promoCode}` : ''}` +
      `${repCode ? `&repid=${repCode}` : ''}` +
      `${utm?.utmCampaign ? `&utm_campaign=${utm?.utmCampaign}` : ''}` +
      '&utm_content=pre-qual-funnel-to-app' +
      `${utm?.utmSource ? `&utm_source=${utm?.utmSource}` : ''}` +
      `${utm?.utmMedium ? `&utm_medium=${utm?.utmMedium}` : ''}` +
      `${utm?.utmTerm ? `&utm_term=${utm?.utmTerm}` : ''}` +
      `${utm?.utmId ? `&utm_id=${utm?.utmId}` : ''}` +
      `${utm?.utmName ? `&utm_name=${utm?.utmName}` : ''}` +
      `${marketing?.adGroup ? `&adgroup=${marketing?.adGroup}` : ''}` +
      `${marketing?.adGroupId ? `&adgroupid=${marketing?.adGroupId}` : ''}` +
      `${marketing?.campaignName ? `&campaign=${marketing?.campaignName}` : ''}` +
      `${marketing?.campaignId ? `&campaignid=${marketing?.campaignId}` : ''}`;

    if (isBDP) {
      boatLink += `${boatInfo ? `&boatInfo=${boatInfo}` : ''}`;
    }
    boatLink += `&leadUUID=${leadUUID}`;
    setApplyLink(boatLink);

    const resultsHeader = {
      title: 'Results',
      subtitle: approved
        ? `Great news ${firstName}! Based upon the information you provided, you have some great options for financing your boat.`
        : 'We need a little more information to qualify your loan.',
      content: approved ? (
        <>
          Rate offers can fluctuate over time, so <span onClick={goToApplyNowLink}>Apply Now</span>{' '}
          to lock-in your final rate today.
        </>
      ) : (
        <>Please continue with the loan application.</>
      ),
    };

    const results: SummaryType[] = [
      { name: 'Purchase Price', value: formatCurrency(purchasePrice) },
      { name: 'Down Payment', value: formatCurrency(downPayment) },
      {
        name: 'Loan Amount',
        value: formatCurrency(loanAmount),
        tooltip: <ResultsTooltip tooltip={loanAmountToolTip} />,
      },
      {
        name: 'Credit Score',
        value: `(${creditScore})`,
        tooltip: <ResultsTooltip tooltip={creditScoreToolTip} />,
      },
      {
        name: 'Term in Years',
        value: prequalApprovalTerm ? `${getYears(prequalApprovalTerm)} Years` : '',
        tooltip: <ResultsTooltip tooltip={prequalApprovalTermToolTip} />,
      },
      {
        name: 'Estimated APR',
        value: prequalApprovalRate ? `${prequalApprovalRate?.toString()}%` : '',
        tooltip: <ResultsTooltip tooltip={prequalApprovalRateToolTip} />,
        hasDisclaimer: true,
      },
      {
        name: 'Estimated Monthly Payment',
        value: prequalApprovalMonthlyPayment ? formatCurrency(prequalApprovalMonthlyPayment) : '',
      },
    ];

    if (approved) {
      setSummary(results);
    }
    setPreApproved(approved);
    setHeader(resultsHeader);
  }, [prequal, form, isBDP, source, boatInfo, applyLink]);

  const disclaimerText =
    '*Estimated APR, or Annual Percentage Rate, is the annual cost of the loan, which includes other charges and fees. Actual rate and term can vary depending on credit history, collateral type, age of collateral, loan amount, loan term, loan to value, vessel registration type (LLC/Trust), state of residency and other criteria. Rates and terms may vary and are subject to change without notice. Credit score can vary depending on the credit reporting agency. Payment example: A $76,000 loan for the purchase of a recreational use vessel for 20 years with a fixed rate of 4.12% would have an estimated APR of 4.12% and 240 monthly payments of $467.05.';

  return (
    <PreQualFunnelLayout>
      <SectionHeader header={header} />
      <Form form={form} initialValues={prequal} onFinish={goToApplyNowLink}>
        <div className={styles.resultsContainer}>
          {summary.map((item) => (
            <div key={`result-${item.name}}`} className={styles.resultItem}>
              <div className={styles.resultName}>
                {item.name}
                {item.hasDisclaimer ? <span>*</span> : null}
                {item.tooltip}
              </div>
              <div className={styles.dashedLine} />
              <div className={styles.resultValue}>{item.value}</div>
            </div>
          ))}
        </div>
      </Form>
      {approved && (
        <span className={styles.editLink} onClick={goToEditLink}>
          Edit
        </span>
      )}
      <NavigationRow isLastStep canContinue form={form} />
      <PreQualDisclaimer text={disclaimerText} />
    </PreQualFunnelLayout>
  );
});

export default ResultsStep;
