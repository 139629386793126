import { computedFn } from 'mobx-utils';
import { LoanPurpose } from '../schema';

const parseUrl = computedFn(
  (pathname: string): string | null => {
    const parts = pathname.split('/');
    if (!parts.length) return null;
    return parts[parts.length - 1];
  },
  { keepAlive: true }
);

export function getUrlCurrentStep(pathname: string): string | null {
  return parseUrl(pathname || window.location.pathname);
}

export const isValidLoanPurpose = (purposeParam: string) =>
  Object.entries(LoanPurpose).some((enumOption) => {
    const enumValue = enumOption[1];
    return enumValue?.toLowerCase() === purposeParam?.toLowerCase();
  });
