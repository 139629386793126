import React from 'react';
import { Form, FormItemProps } from 'antd';
import { FormItemContext } from '../../hooks/useFormItem';

const AppFormItem: React.FC<FormItemProps> = (originalProps) => {
  const props = { ...originalProps };
  // eslint-disable-next-line eqeqeq
  if (props.hasFeedback == undefined) {
    props.hasFeedback = true;
  }
  // eslint-disable-next-line eqeqeq
  if (props.hasFeedback && props.validateTrigger == undefined) {
    props.validateTrigger = 'onBlur';
  }

  return (
    <FormItemContext.Provider value={props}>
      <Form.Item data-testid={`item-${props.name}`} {...props} />
    </FormItemContext.Provider>
  );
};

export default AppFormItem;
