import React from 'react';
import firebase from 'firebase/compat/app';

export const AuthContext = React.createContext<null | firebase.auth.Auth>(null);

export function useAuth(): firebase.auth.Auth {
  const auth = React.useContext(AuthContext);
  if (auth === null) throw new Error('missing auth');
  return auth;
}
