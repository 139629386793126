import React, { FC } from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Loan, LoanPurpose, Source } from '../../../schema';
import useConfig from '../../../stores/config';
import { useLoan } from '../../../stores/store';
import getSource from '../../../utils/getSource';
import {
  fetchRepidFax,
  fetchRepidPhone,
  renderFaxNumber,
  renderMainNumber,
  renderNumber,
} from '../AppFooter/PhoneNumbers';

import styles from './PartnerLoan.module.scss';

type PhoneNumberModalProps = {
  visible: boolean;
  dismiss: () => void;
  purpose?: string;
};

const PhoneNumberModal: FC<PhoneNumberModalProps> = ({
  visible,
  dismiss,
  purpose = LoanPurpose.boat,
}) => {
  const config = useConfig();
  const { loan } = useLoan();
  const { source } = getSource(config, loan as Loan);
  const mainPhone = fetchRepidPhone(loan?.repCode, config?.salesReps);

  return (
    <Modal visible={visible} onCancel={dismiss} onOk={dismiss} cancelButtonProps={{ hidden: true }}>
      <div id="phone-numbers-modal" className={styles.body}>
        <p>We couldn't verify you, please contact us to continue with your application.</p>
        <div className={styles.phones}>
          <b>Contact numbers: </b>
          {renderMainNumber(purpose as LoanPurpose, source, mainPhone)}
          {renderNumber({ number: source?.secondaryPhone })}
          {renderNumber({ number: source?.ttyNumber, label: 'TTY' })}
          {renderFaxNumber(
            loan?.purpose,
            source,
            fetchRepidFax(loan?.repCode, config?.salesReps, config?.offices)
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PhoneNumberModal;
