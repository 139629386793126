import { BOAT_TRADER_SOURCE_ID } from '../constants';

export function fetchUnfinishedLoan(): any {
  const url = new URL(window.location.href.replace(/#!/, ''));
  const params = new URLSearchParams(url.search);
  const source = params.get('source');

  return source === BOAT_TRADER_SOURCE_ID.toString()
    ? {
        loanId: localStorage.getItem('loanId'),
        currentStep: null,
      }
    : {
        loanId: params.get('loanId'),
        currentStep: params.get('currentStep'),
      };
}
