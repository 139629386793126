import { VALID_PREQUAL_URL_PARAMS, ValidPrequalUrlParams } from '../schema';

const parseBoatInfo = (info: string | null) => {
  try {
    if (info) {
      return JSON.parse(decodeURIComponent(info));
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

export const getImtId = () => {
  const params = new URLSearchParams(window.location.search);
  const boatInfo = parseBoatInfo(params.get('boatInfo'));
  return boatInfo?.imtId || null;
};

export const isFromBDP = () => !!getImtId();

export const getBoatInfo = () => {
  const params = new URLSearchParams(window.location.search);
  return parseBoatInfo(params.get('boatInfo'));
};

export const getBoatInfoRaw = () => encodeURIComponent(JSON.stringify(getBoatInfo()));

const getParam = (paramName: string) => {
  const params = new URLSearchParams(window.location.search);
  return params?.get(paramName);
};

export const isValidPrequalUrlParam = (param: string) =>
  VALID_PREQUAL_URL_PARAMS.has(param as ValidPrequalUrlParams);

export const getNumericQueryParam = ({
  value: startingParamValue,
  param,
}: {
  value?: string;
  param: string;
}) => {
  let paramValue;
  const initialParamValue = startingParamValue || getParam(param);

  if (isValidPrequalUrlParam(param) && initialParamValue) {
    paramValue = Number(initialParamValue);
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(paramValue)) {
      console.warn(`${param} is NaN`);
      paramValue = null;
    }
  } else {
    paramValue = null;
  }

  return paramValue;
};

export const getQueryParam = (param: string) => {
  const params = new URLSearchParams(window.location.search);
  let paramValue = null;

  if (isValidPrequalUrlParam(param)) {
    paramValue = params.get(param);
  }

  return paramValue;
};
