import React from 'react';
import { Col, FormInstance, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { rowLayout } from '../layout';
import AddressAutocomplete from '../../components/AddressAutocomplete/AddressAutocomplete';
import PlacesAttribution from '../../components/AddressAutocomplete/PlacesAttribution';
import MoneyInput from '../../components/MoneyInput/MoneyInput';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from '../steps/meta';
import AppFormItem from './AppFormItem';
import { Address, Borrower } from '../../schema';
import { disableAutocomplete, isUSState } from '../../utils';
import { RENT_OR_OWN_LABEL_MAP } from '../../constants';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import { US_ABBREV } from '../../utils/countries';

type Props = {
  borrower: Borrower;
  address: Address;
  county?: boolean;
  rentOwn?: boolean;
  askYears?: boolean;
  form: FormInstance;
  disabled?: boolean;
  hideMonthsAtResidence?: boolean;
  type?: string;
  hasFeedback?: boolean;
};

const largeTwo = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };

const schemaValidator = createSchemaValidator(pickFields('address'));

const stateValidator = (type: string, form: FormInstance) => ({
  validator: async (_rule: any, state: string): Promise<void> => {
    const country = form.getFieldValue(`${type}Country`);
    if (country === US_ABBREV && !isUSState(state)) {
      throw new Error('Please enter a valid state abbreviation');
    }
  },
});

const BorrowerAddressForm: React.FC<Props> = observer(
  ({
    form,
    borrower,
    address,
    county = false,
    rentOwn = false,
    askYears = true,
    disabled = false,
    hideMonthsAtResidence = false,
    type = '',
    hasFeedback = true,
  }) => {
    const yearOptions = [];
    for (let i = 0; i < 100; i++) {
      yearOptions.push(i);
    }

    const getFieldRules = (rules: any) => (!disabled ? rules() : undefined);
    const showFeedback = (fieldName: string) => form.getFieldValue(fieldName)?.length;
    const validateFeedbackStatusFor = (fieldName: string): 'success' | undefined =>
      showFeedback(fieldName) ? 'success' : undefined;

    return (
      <Row {...rowLayout}>
        <Col {...largeTwo}>
          <AppFormItem
            validateStatus={validateFeedbackStatusFor(`${type}Address1`)}
            hasFeedback
            required
          >
            <PlaceholderLabel
              label="Home Address"
              htmlFor={`${type}Address1`}
              currentValue={address?.address1 || form.getFieldValue(`${type}Address1`)}
            >
              <AddressAutocomplete
                autoComplete="off"
                name={`${type}Address1`}
                required
                rules={getFieldRules(() => [
                  schemaValidator('address1', address, {
                    message: 'Please enter a street address',
                  }),
                ])}
                onSelect={(selectedAddress: Address) => {
                  const valuesArray = [
                    {
                      name: `${type}Address1`,
                      value: selectedAddress.address1 || '',
                    },
                    { name: `${type}UnitNumber`, value: selectedAddress.unitNumber },
                    { name: `${type}City`, value: selectedAddress.city },
                    { name: `${type}County`, value: selectedAddress.county },
                    { name: `${type}Country`, value: selectedAddress.country },
                    { name: `${type}State`, value: selectedAddress.state },
                    { name: `${type}Zip`, value: selectedAddress.zip },
                  ];

                  const fieldsObject = {};
                  valuesArray.forEach((item) =>
                    Object.assign(fieldsObject, { [item.name]: item.value })
                  );

                  form.setFieldsValue(fieldsObject);
                  form.setFields(valuesArray);
                  form.validateFields(['address1', 'city', 'county', 'country', 'state', 'zip']);
                }}
                disabled={disabled}
              />
            </PlaceholderLabel>
            <PlacesAttribution />
          </AppFormItem>
        </Col>

        <div className="mobileSpacing" />

        <Col {...largeTwo}>
          <AppFormItem
            name={`${type}UnitNumber`}
            hasFeedback={showFeedback(`${type}UnitNumber`)}
            rules={!disabled ? [schemaValidator('unitNumber', address)] : undefined}
          >
            <PlaceholderLabel label="Unit, Suite or Apt Number" htmlFor={`${type}UnitNumber`}>
              <Input autoComplete="address-level2" disabled={disabled} />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>

        <div className="mobileSpacing" />

        <Col {...largeTwo}>
          <AppFormItem
            name={`${type}City`}
            hasFeedback={showFeedback(`${type}City`)}
            required
            rules={getFieldRules(() => [
              schemaValidator('city', address, {
                message: 'Please enter a city',
              }),
            ])}
          >
            <PlaceholderLabel label="City" htmlFor={`${type}City`}>
              <Input autoComplete="address-level2" disabled={disabled} />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>

        <div className="mobileSpacing" />

        {county && (
          <Col {...largeTwo}>
            <AppFormItem
              required
              name={`${type}County`}
              hasFeedback={showFeedback(`${type}County`)}
              rules={getFieldRules(() => [
                { required: true, message: 'Please enter a county' },
                schemaValidator('county', address),
              ])}
            >
              <PlaceholderLabel label="County" htmlFor={`${type}County`}>
                <Input autoComplete="county" maxLength={30} disabled={disabled} />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        )}

        <div className="mobileSpacing" />

        <Col {...largeTwo}>
          <AppFormItem
            required
            name={`${type}State`}
            hasFeedback={showFeedback(`${type}State`)}
            validateTrigger="onBlur"
            rules={getFieldRules(() => [stateValidator(type, form)])}
          >
            <PlaceholderLabel label="State" htmlFor={`${type}State`}>
              <Input
                autoComplete="address-level1"
                onInput={(e: any) => (e.target.value = e.target.value.toUpperCase())}
                maxLength={2}
                disabled={disabled}
              />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>

        <div className="mobileSpacing" />

        <Col {...largeTwo}>
          <AppFormItem
            required
            name={`${type}Zip`}
            hasFeedback={showFeedback(`${type}Zip`)}
            rules={getFieldRules(() => [
              schemaValidator('zip', address, {
                message: 'Please enter a zip code',
              }),
            ])}
          >
            <PlaceholderLabel label="Zip Code" htmlFor={`${type}Zip`}>
              <Input autoComplete="postal-code" disabled={disabled} />
            </PlaceholderLabel>
          </AppFormItem>
        </Col>

        {(askYears || rentOwn) && (
          <>
            <div className="mobileSpacingExtra" />

            {askYears && (
              <>
                <Col {...largeTwo}>
                  <AppFormItem
                    required
                    name={`${type}YearsAtResidence`}
                    hasFeedback
                    rules={getFieldRules(() => [
                      {
                        required: true,
                        message: 'Please enter how long you have lived at this address',
                      },
                      schemaValidator('yearsAtResidence', address, {
                        transform: 'int',
                      }),
                    ])}
                  >
                    <PlaceholderLabel label="Years At Address" htmlFor={`${type}YearsAtResidence`}>
                      <Select
                        suffixIcon={<SelectArrowIcon />}
                        showSearch
                        allowClear
                        optionFilterProp="children"
                        onFocus={disableAutocomplete}
                        filterOption={(input, option) =>
                          String(option?.children || '').indexOf(input) === 0
                        }
                        disabled={disabled}
                      >
                        {yearOptions.map((s) => (
                          <Select.Option key={s} value={s}>
                            {s}
                          </Select.Option>
                        ))}
                      </Select>
                    </PlaceholderLabel>
                  </AppFormItem>
                </Col>

                <div className="mobileSpacing" />

                {!hideMonthsAtResidence && (
                  <Col {...largeTwo}>
                    <AppFormItem
                      required
                      name={`${type}MonthsAtResidence`}
                      hasFeedback
                      rules={getFieldRules(() => [
                        {
                          required: true,
                          message: 'Please enter the number of months',
                        },
                        schemaValidator('monthsAtResidence', address, {
                          transform: 'int',
                        }),
                      ])}
                    >
                      <PlaceholderLabel label="Months" htmlFor={`${type}MonthsAtResidence`}>
                        <Select
                          suffixIcon={<SelectArrowIcon />}
                          showSearch
                          allowClear
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            String(option?.children || '').indexOf(input) === 0
                          }
                          disabled={disabled}
                        >
                          {/* eslint-disable-next-line prefer-spread */}
                          {Array.apply(null, Array(12)).map((s, index) => (
                            // eslint-disable-next-line
                            <Select.Option key={index} value={index}>
                              {index}
                            </Select.Option>
                          ))}
                        </Select>
                      </PlaceholderLabel>
                    </AppFormItem>
                  </Col>
                )}
              </>
            )}

            {rentOwn && (
              <>
                <div className="mobileSpacing" />
                <Col {...largeTwo}>
                  <AppFormItem
                    name={`${type}RentOrOwn`}
                    hasFeedback
                    required
                    rules={getFieldRules(() => [
                      { required: true, message: 'Please choose one' },
                      schemaValidator('rentOrOwn', address),
                    ])}
                  >
                    <PlaceholderLabel label="Residence" htmlFor={`${type}RentOrOwn`}>
                      <Select
                        suffixIcon={<SelectArrowIcon />}
                        allowClear
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={disabled}
                      >
                        <Select.Option key="ownMortgage" value="ownMortgage">
                          {RENT_OR_OWN_LABEL_MAP.ownMortgage}
                        </Select.Option>
                        <Select.Option key="own" value="own">
                          {RENT_OR_OWN_LABEL_MAP.own}
                        </Select.Option>
                        <Select.Option key="rent" value="rent">
                          {RENT_OR_OWN_LABEL_MAP.rent}
                        </Select.Option>
                        <Select.Option key="friend" value="friend">
                          {RENT_OR_OWN_LABEL_MAP.friend}
                        </Select.Option>
                        <Select.Option key="military" value="military">
                          {RENT_OR_OWN_LABEL_MAP.military}
                        </Select.Option>
                      </Select>
                    </PlaceholderLabel>
                  </AppFormItem>
                </Col>

                <div className="mobileSpacing" />

                <Col {...largeTwo}>
                  <AppFormItem
                    required
                    name={`${type}MortgageRentPayment`}
                    hasFeedback
                    rules={getFieldRules(() => [
                      { required: true, message: 'Please enter your payment' },
                      schemaValidator('mortgageRentPayment', address, {
                        transform: 'float',
                      }),
                    ])}
                  >
                    <PlaceholderLabel
                      label="Monthly payment"
                      htmlFor={`${type}MortgageRentPayment`}
                    >
                      <MoneyInput disabled={disabled} />
                    </PlaceholderLabel>
                  </AppFormItem>
                </Col>

                <Col {...largeTwo} hidden>
                  <AppFormItem
                    name={`${type}Country`}
                    hasFeedback
                    rules={getFieldRules(() => [
                      schemaValidator('country', address, {
                        message: 'Please enter a country',
                      }),
                    ])}
                  >
                    <PlaceholderLabel label="Country" htmlFor={`${type}Country`}>
                      <Input autoComplete="address-level2" disabled={disabled} />
                    </PlaceholderLabel>
                  </AppFormItem>
                </Col>
              </>
            )}
          </>
        )}

        <div className="mobileSpacing" />
      </Row>
    );
  }
);

export default BorrowerAddressForm;
