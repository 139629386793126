import {
  Config,
  ConfigSettings,
  LoanPurpose,
  OfficeById,
  SalesRepsByCode,
  Source,
  SubSource,
} from '../schema';
import { makeAutoObservable } from 'mobx';
import { useFirestore } from '../hooks/useFirestore';
import { useEffect, useState } from 'react';
import { BOATWIZARD_SOURCE_ID, DEFAULT_SOURCE_ID } from '../constants';

const sourcesLocationMap: Map<string, Source> = new Map();

export class ConfigStore implements Config {
  nextSourceId: number;

  nextSubSourceId: number;

  offices: OfficeById;

  salesReps: SalesRepsByCode;

  sources: Array<Source>;

  settings: ConfigSettings;

  constructor(data: Config) {
    // arg, typescript
    // this.updateData(data);
    this.nextSourceId = data.nextSourceId;
    this.nextSubSourceId = data.nextSubSourceId;
    this.offices = data.offices;
    this.salesReps = data.salesReps;
    this.sources = data.sources;
    this.settings = data.settings;

    makeAutoObservable(this);
  }

  updateData(data: Config) {
    this.nextSourceId = data.nextSourceId;
    this.nextSubSourceId = data.nextSubSourceId;
    this.offices = data.offices;
    this.salesReps = data.salesReps;
    this.sources = data.sources;
    this.settings = data.settings;
  }

  getSource(sourceId?: number): Source {
    if (sourceId === undefined) sourceId = DEFAULT_SOURCE_ID;
    for (let i = 0; i < this.sources.length; i++) {
      if (this.sources[i].id === sourceId) {
        return this.sources[i];
      }
    }
    throw new Error(`Source not found: ${sourceId}`);
  }

  getValidSourcesFromLocation(location: string): Source[] {
    return this.sources.filter((s) => location.includes(s.hostname));
  }

  getSourceFromLocation(location: string): Source {
    const sourceFromMap = sourcesLocationMap.get(location);
    if (sourceFromMap) {
      return sourceFromMap;
    }

    const possibleSources = [];

    for (let i = 0; i < this.sources.length; i++) {
      if (location.includes(this.sources[i].hostname)) {
        if (this.sources[i].id === DEFAULT_SOURCE_ID) {
          return this.sources[i];
        }
        possibleSources.push(this.sources[i]);
      }
    }
    let source;
    if (possibleSources.length > 0) {
      // This array should only have one entry on an expected scenario,
      // if multiple sources share location it should default on loop above
      [source] = possibleSources;
    } else {
      // return the default source if not found
      source = this.getSource(DEFAULT_SOURCE_ID);
    }
    sourcesLocationMap.set(location, source);

    return source;
  }

  /**
   * find the subsource object for a given source
   */
  getSubSource(source: Source, subSourceId: number): SubSource | null {
    let subSource = null;
    for (let i = 0; i < source.sub.length && !subSource; i++) {
      if (source.sub[i].id === subSourceId) {
        subSource = source.sub[i];
      }
    }

    if (subSource === null) {
      if (source.id === BOATWIZARD_SOURCE_ID) {
        subSource = {
          id: subSourceId,
          name: 'BoatWizard PartyId',
          products: [LoanPurpose.boat],
          isReferral: true,
        };
      } else {
        throw new Error(`Sub source not found: ${subSourceId}`);
      }
    }

    return subSource;
  }
}

let configStore: ConfigStore | null = null;

export default function useConfig(): ConfigStore | null {
  const [store, setStore] = useState<ConfigStore | null>(configStore);
  const firestore = useFirestore();

  useEffect(() => {
    if (!store) {
      const configRef = firestore.collection('features').doc('config');
      configRef.onSnapshot((snapshot) => {
        if (store) {
          console.warn('Updating config from server');
          // Cast to ConfigStore because typescript believes that store is null
          // and doesn't have an updateData method.
          const cstore: ConfigStore = store;
          cstore.updateData(snapshot.data() as Config);
        } else {
          configStore = new ConfigStore(snapshot.data() as Config);
          setStore(configStore);
        }
      });
    }
  }, [firestore, store]);
  return store;
}
