import React from 'react';
import { ReactComponent as ShieldIcon } from '../../../icons/prequal_shield.svg';
import { ReactComponent as CheckIcon } from '../../../icons/prequal_check.svg';
import styles from './FooterCard.module.scss';

type Props = {
  showFirstItem?: Boolean;
};

const FooterCard: React.FC<Props> = (props) => {
  const { showFirstItem = false } = props;

  return (
    <div className={styles.footerContainer}>
      <div className="content">
        <div className="text-container">
          <div className="title">Your security is our top priority</div>
          {showFirstItem && (
            <div className="item">
              <div className="check">
                <CheckIcon />
              </div>
              <div>We'll encrypt your information to keep it secure.</div>
            </div>
          )}
          <div className="item">
            <div className="check">
              <CheckIcon />
            </div>
            <div>
              We won't sell your information. Read our{' '}
              <a
                href="https://www.tridentfunding.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy.
              </a>
            </div>
          </div>
          <div className="item">
            <div className="check">
              <CheckIcon />
            </div>
            <div>No Impact to your credit score!</div>
          </div>
        </div>
        <div className="icon">
          <ShieldIcon />
        </div>
      </div>
    </div>
  );
};

export default FooterCard;
