import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Loan } from '../schema';
import { ConfigStore } from '../stores/config';
import { LoanStore } from '../stores/store';
import getSource from '../utils/getSource';
import { isPrequalFunnel } from '../utils';

import { DEFAULT_THEME, BOAT_TRADER_SOURCE_ID } from '../constants';
import { setThemeProperties } from '../theme/theme';

export const useTheme = (
  loanStore: LoanStore,
  config: ConfigStore | null,
  ignoreStatus: boolean = false
) => {
  const { loan, theme, status } = loanStore;
  const history = useHistory();
  useEffect(() => {
    if (config && (ignoreStatus || status === 'done') && theme == null) {
      let newTheme = DEFAULT_THEME;
      const { source, error } = getSource(config, loan as Loan);

      if (error) {
        history.push(
          isPrequalFunnel() ? `/pre-qualify/notFound/?source=${BOAT_TRADER_SOURCE_ID}` : '/notFound'
        );
      }

      if (source?.theme) {
        newTheme = source.theme;
      }

      setThemeProperties(newTheme);
      loanStore.setTheme(newTheme);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [config, loan]);

  return theme;
};
