import React from 'react';
import { observer } from 'mobx-react-lite';
import BlankLayout from '../BlankLayout';
import { Link } from 'react-router-dom';

type Props = {};

const SavedStep: React.FC<Props> = observer(function MyApps() {
  return (
    <BlankLayout title="Trident Funding">
      <h1>Saved for later</h1>
      <p>
        Your credit app has been saved! You can use your account to return to your credit
        application at any time.
      </p>
      <p>
        <Link to="/profile/apps">Click here to continue</Link>
      </p>
    </BlankLayout>
  );
});

export default SavedStep;
