import React from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import CollateralForm from './CollateralForm';
import { stepFinished } from '../../nav';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const TradeInForm: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const currentYear = new Date().getFullYear();

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const { purpose } = loan;
  const collateral = loanStore.getTrade();
  const maxYear = currentYear;
  const minYear = 1900; // no limit on trade-ins
  const yearRange = [];

  for (let i = maxYear; i >= minYear; i--) {
    yearRange.push(i);
  }

  const update = (_values: any) => {
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  return (
    <>
      <Form form={form} {...formItemLayout} initialValues={collateral} onFinish={update}>
        <h2>Please tell us about your {purpose} trade in:</h2>
        <CollateralForm
          form={form}
          yearRange={yearRange}
          collateral={collateral}
          loan={loan}
          tradeCollateral
        />
      </Form>
    </>
  );
});

export default TradeInForm;
