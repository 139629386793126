export function camelCase(parts: string[]): string {
  if (parts.length === 1) return parts[0];
  if (parts.length === 0) return '';
  return (
    parts[0].replace(/^./, (s) => s.toLowerCase()) +
    parts
      .slice(1)
      .map((a) => a.replace(/^./, (s) => s.toUpperCase()))
      .join('')
  );
}
