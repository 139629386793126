import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import BlankLayout from '../BlankLayout';
import { disableAnonCreation, useUser } from '../../hooks/useUser';
import { useAuth } from '../../hooks/useAuth';

type Props = {};

const LogoutStep: React.FC<Props> = observer(() => {
  const user = useUser();
  const auth = useAuth();

  useEffect(() => {
    disableAnonCreation();
    auth.signOut();
  }, [auth]);

  return (
    <BlankLayout>
      {user && !user.isAnonymous ? (
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      ) : (
        <h1>You have been logged out</h1>
      )}
    </BlankLayout>
  );
});

export default LogoutStep;
