import { TLDs } from './TLDs';

export const emailValidator = (email: string) => {
  const host = email.split('@').pop() ?? '';
  const tld = host.split('.').pop() ?? '';
  const passTld = TLDs.includes(tld.toUpperCase());

  // eslint-disable-next-line no-useless-escape
  const emailRegex = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,6}$/;
  const passRegEx = emailRegex.test(email);

  return passRegEx && passTld;
};
