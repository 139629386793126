import React, { ReactElement } from 'react';
import { runInAction } from 'mobx';
import { Button, Col, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { useLoan } from '../../stores/store';
import MoneyInput from '../../components/MoneyInput/MoneyInput';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from '../steps/meta';
import AppFormItem from './AppFormItem';
import { Borrower, EmploymentStatus, IncomeSource, LoanPurpose } from '../../schema';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import { PlusOutlined } from '@ant-design/icons';

type Props = {
  borrower: Borrower;
};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const INCOME_SOURCES = Object.entries(IncomeSource).map(([key, label]) => ({
  key,
  label,
}));

const schemaValidator = createSchemaValidator(pickFields('income'));

function generateIncomeSourceCustomInput(borrower: any, incomeSourceName: string) {
  const fieldName: string = `${incomeSourceName}Custom`;

  if (borrower[incomeSourceName] !== IncomeSource.other) {
    runInAction(() => {
      delete borrower[fieldName];
    });
  }

  return (
    <>
      {borrower[incomeSourceName] === IncomeSource.other && (
        <AppFormItem
          name={fieldName}
          required
          rules={[
            schemaValidator(fieldName, borrower, {
              message: 'Please enter a source for the income',
              required: true,
            }),
          ]}
        >
          <PlaceholderLabel label="Source of Other Income" htmlFor={fieldName}>
            <Input />
          </PlaceholderLabel>
        </AppFormItem>
      )}
    </>
  );
}

function generateAdditionalIncomeSourceInputs(borrower: any, index: number) {
  const inputCollection: ReactElement[] = [];

  for (let i: number = 1; i <= index; i++) {
    const counter: string = i === 1 ? '' : `${i}`;

    const extraFieldSourceName: string = `other${counter}IncomeSource`;
    const extraFieldAmount: string = `other${counter}IncomeAmount`;

    inputCollection.push(
      <React.Fragment key={extraFieldSourceName}>
        <AppFormItem
          name={extraFieldSourceName}
          rules={[schemaValidator(extraFieldSourceName, borrower)]}
        >
          <PlaceholderLabel label="Other Income Source" htmlFor={extraFieldSourceName}>
            <Select
              suffixIcon={<SelectArrowIcon />}
              showSearch={INCOME_SOURCES.length > 7}
              allowClear
              optionFilterProp="children"
              filterOption={(input, option) =>
                option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {INCOME_SOURCES.map(({ key, label }) => (
                <Select.Option key={key} value={label}>
                  {label}
                </Select.Option>
              ))}
            </Select>
          </PlaceholderLabel>
        </AppFormItem>

        <div className="mobileSpacing" />

        {generateIncomeSourceCustomInput(borrower, extraFieldSourceName)}

        <AppFormItem
          name={extraFieldAmount}
          required
          rules={[
            schemaValidator(extraFieldAmount, borrower, {
              transform: 'float',
            }),
          ]}
        >
          <PlaceholderLabel label="Other Income Gross Annual Amount" htmlFor={extraFieldAmount}>
            <MoneyInput />
          </PlaceholderLabel>
        </AppFormItem>

        <div className="mobileSpacing" />
      </React.Fragment>
    );
  }

  return inputCollection;
}

const IncomeForm: React.FC<Props> = observer(({ borrower }) => {
  const loanStore = useLoan();
  const { loan } = loanStore;

  const yearOptions = [];
  for (let i = 0; i < 100; i++) {
    yearOptions.push(i);
  }

  runInAction(() => {
    if (borrower.employmentStatus === EmploymentStatus.unemployed) {
      const phone = borrower.homePhone || borrower.cellPhone || '00000000000';
      borrower.currentEmployer = {
        name: borrower.employmentStatus,
        position: borrower.employmentStatus,
        years: 0,
        typeOfBusiness: borrower.employmentStatus,
        phone,
      };
    }
  });

  const otherCount = borrower.otherCount || 0;

  return (
    <>
      <Row>
        <Col {...centerSmallOffset} />
        <Col {...centerSmall}>
          <AppFormItem
            name="employmentStatus"
            required
            rules={[schemaValidator('employmentStatus', borrower)]}
          >
            <PlaceholderLabel
              label="What is your current employment status?"
              htmlFor="employmentStatus"
            >
              <Select
                suffixIcon={<SelectArrowIcon />}
                showSearch={Object.values(EmploymentStatus).length > 7}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {Object.entries(EmploymentStatus).map(([name, label]) => (
                  <Select.Option key={name} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </PlaceholderLabel>
          </AppFormItem>

          {borrower?.employmentStatus === EmploymentStatus.retired && (
            <>
              <div className="mobileSpacing" />

              <AppFormItem
                required
                name="retiredYears"
                rules={[
                  {
                    required: true,
                    message: 'Please enter how long you have been retired',
                  },
                  schemaValidator('retiredYears', borrower, { transform: 'int' }),
                ]}
              >
                <PlaceholderLabel
                  label="How many years have you been retired?"
                  htmlFor="retiredYears"
                >
                  <Select
                    suffixIcon={<SelectArrowIcon />}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      String(option?.children || '').indexOf(input) === 0
                    }
                  >
                    {yearOptions.map((s) => (
                      <Select.Option key={s} value={s}>
                        {s}
                      </Select.Option>
                    ))}
                  </Select>
                </PlaceholderLabel>
              </AppFormItem>
            </>
          )}

          <div className="mobileSpacing" />

          <AppFormItem
            name="primaryIncomeSource"
            required
            rules={[
              schemaValidator('primaryIncomeSource', borrower, {
                message: 'Please choose one',
              }),
            ]}
          >
            <PlaceholderLabel label="Primary Income Source" htmlFor="primaryIncomeSource">
              <Select
                suffixIcon={<SelectArrowIcon />}
                showSearch={INCOME_SOURCES.length > 7}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {INCOME_SOURCES.map(({ key, label }) => (
                  <Select.Option key={key} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </PlaceholderLabel>
          </AppFormItem>

          <div className="mobileSpacing" />

          {generateIncomeSourceCustomInput(borrower, 'primaryIncomeSource')}

          <AppFormItem
            name="primaryIncomeAmount"
            required
            rules={[
              schemaValidator('primaryIncomeAmount', borrower, {
                message: 'Please enter an amount',
                transform: 'float',
              }),
            ]}
          >
            <PlaceholderLabel
              label="Primary Income Gross Annual Amount"
              htmlFor="primaryIncomeAmount"
            >
              <MoneyInput />
            </PlaceholderLabel>
          </AppFormItem>

          <div className="mobileSpacing" />

          <AppFormItem
            name="secondaryIncomeSource"
            rules={[schemaValidator('secondaryIncomeSource', borrower)]}
          >
            <PlaceholderLabel
              label={`Secondary Income Source ${
                loan?.purpose === LoanPurpose.boat ? '(optional)' : ''
              }`}
              htmlFor="secondaryIncomeSource"
            >
              <Select
                suffixIcon={<SelectArrowIcon />}
                showSearch={INCOME_SOURCES.length > 7}
                allowClear
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {INCOME_SOURCES.map(({ key, label }) => (
                  <Select.Option key={key} value={label}>
                    {label}
                  </Select.Option>
                ))}
              </Select>
            </PlaceholderLabel>
          </AppFormItem>

          <div className="mobileSpacing" />

          {generateIncomeSourceCustomInput(borrower, 'secondaryIncomeSource')}

          <AppFormItem
            name="secondaryIncomeAmount"
            rules={[
              schemaValidator('secondaryIncomeAmount', borrower, {
                transform: 'float',
              }),
            ]}
          >
            <PlaceholderLabel
              label={`Secondary Income Gross Annual Amount ${
                loan?.purpose === LoanPurpose.boat ? '(optional)' : ''
              }`}
              htmlFor="secondaryIncomeAmount"
            >
              <MoneyInput />
            </PlaceholderLabel>
          </AppFormItem>

          <div className="mobileSpacing" />

          {otherCount >= 0 && generateAdditionalIncomeSourceInputs(borrower, otherCount)}

          {otherCount < 3 && (
            <Button
              htmlType="button"
              type="default"
              style={{ marginTop: '.5rem' }}
              onClick={() => {
                if (borrower) loanStore.addBorrowerIncome(borrower, otherCount + 1);
              }}
            >
              <PlusOutlined />
              Add additional Income
            </Button>
          )}
        </Col>
      </Row>
    </>
  );
});

export default IncomeForm;
