/* eslint-disable import/no-cycle */
import { ConfigStore } from '../stores/config';
import { PrequalStore } from '../stores/prequalStore';
import {
  Prequal,
  SellerType,
  PrequalApprovalStatus,
  FullApplicationStatus,
  LeadOrigin,
} from '../schema';
import { betterParseInt, betterParseFloat } from '.';
import {
  isProduction,
  PROD_TRIDENT_API_URL,
  QA_TRIDENT_API_URL,
  MIN_TIME_TO_CREATE_NEW_GENERIC_LEAD,
  BOAT_TRADER_SOURCE_ID,
  BOATTRADER_LEAD,
  SALESFORCE_LEAD,
} from '../constants';
import { v4 as uuidv4 } from 'uuid';
import firebase from 'firebase/compat/app';
import axios from 'axios';
import { getNumericQueryParam } from './queryParamHelper';

const addBoatDetails = () => {
  const params = new URLSearchParams(window.location.search);
  const boatInfo = params.get('boatInfo');

  if (boatInfo) {
    try {
      const boatDetails = JSON.parse(decodeURIComponent(boatInfo));
      return {
        imtId: boatDetails.imtId,
        partyId: boatDetails.partyId,
        purchasePrice: boatDetails.price,
        boatMake: boatDetails.make,
        boatModel: boatDetails.model,
        boatYear: boatDetails.year,
        boatType: boatDetails.type,
        boatLength: boatDetails.length,
        boatEngineCount: boatDetails.engineCount,
        boatEngineMake: boatDetails.engineMake,
        boatFuelType: boatDetails.fuelType,
        boatTotalPower: boatDetails.totalPower,
        sellerName: boatDetails.sellerName,
        sellerEmail: boatDetails.sellerEmail,
        sellerType: boatDetails.sellerType,
        sellerFinanceAdvantage: boatDetails.sellerFinanceAdvantage,
      };
    } catch (e) {
      console.error('Error parsing boatInfo:', e);
    }
  }
  return {};
};

const clearEmptyProps = (ob: Record<string, any>) => {
  const newOb = { ...ob };
  Object.keys(newOb).forEach((key) => {
    if (newOb[key] === undefined || newOb[key] === null) {
      delete newOb[key];
    }
  });
  return newOb;
};

const isEligibleForNewLead = (prequal: Prequal) => {
  const now = Date.now();
  const leadCreated = prequal.leadCreatedAt || 0;
  const leadTimeElapsed = now - leadCreated;
  return leadTimeElapsed > MIN_TIME_TO_CREATE_NEW_GENERIC_LEAD;
};

const shouldCreateBoattraderLead = (prequal: Prequal) =>
  prequal.prequalApprovalStatus === 'Approved' &&
  (!prequal.leadCreatedAt || prequal.email !== prequal.leadEmail || isEligibleForNewLead(prequal));

const shouldCreateSalesforceLead = (prequal: Prequal) =>
  !prequal.leadCreatedAt ||
  (prequal.leadApprovalStatus === 'Not Approved' && prequal.prequalApprovalStatus === 'Approved') ||
  prequal.email !== prequal.leadEmail ||
  isEligibleForNewLead(prequal);

const shouldCreateLead = (leadType: string, prequal: Prequal) => {
  if (leadType === SALESFORCE_LEAD) {
    return shouldCreateSalesforceLead(prequal);
  }
  return shouldCreateBoattraderLead(prequal);
};

const determineLoanType = (prequal: Prequal) => {
  const currentYear = new Date().getFullYear();
  if (prequal.imtId === 'NONBDP' || betterParseInt(prequal.boatYear) === currentYear) {
    return 'new';
  }

  return 'used';
};

/**
 * Generates a universally unique user ID (UUID).
 *
 * @return {string} The generated UUID.
 */
export const generateUUID = () => uuidv4();

export const isPrequalFunnel = () => window.location.pathname.includes('/pre-qualify');

export const formatEpochDate = (epochTime: number) => {
  const date = new Date(epochTime);
  return date.toLocaleString('en-US', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false,
  });
};

export const getApprovalStatus = (rate: string): PrequalApprovalStatus =>
  rate === '-' || rate.toLowerCase() === 'call'
    ? PrequalApprovalStatus.notApproved
    : PrequalApprovalStatus.approved;

/**
 * Cleans the prequal results if Not Approved or an error occurs
 *
 * @param {Prequal} prequal - The prequal object.
 */
const cleanPrequalApproval = (prequal: Prequal) => {
  delete prequal.prequalApprovalTerm;
  delete prequal.prequalApprovalRate;
  delete prequal.prequalApprovalMonthlyPayment;
};

/**
 * Determines the prequal status by making an API call to the /ratesCalculator endpoint.
 *
 * @param {Prequal} prequal - The prequal object.
 * @return {Promise<void>} A promise that resolves when the get request is complete.
 */
export const determinePrequalStatus = async (prequal: Prequal): Promise<void> => {
  const apiUrl = isProduction()
    ? `${PROD_TRIDENT_API_URL}/rates-calculator/0/0/Boat`
    : `${QA_TRIDENT_API_URL}/rates-calculator/0/0/Boat`;
  try {
    const response = await axios.get(apiUrl, {
      params: {
        loanType: determineLoanType(prequal),
        loanAmount: prequal.loanAmount,
        modelYear: prequal.boatYear || new Date().getFullYear(),
        ficoScoreRange: prequal.creditScore,
      },
    });

    const { monthlyPayment, rate, term }: { monthlyPayment: string; rate: string; term: string } =
      response.data;

    prequal.prequalApprovalStatus = getApprovalStatus(rate);

    if (prequal.prequalApprovalStatus === 'Not Approved') {
      cleanPrequalApproval(prequal);
      return;
    }

    prequal.prequalApprovalTerm = betterParseInt(term);
    prequal.prequalApprovalRate = betterParseFloat(rate);
    prequal.prequalApprovalMonthlyPayment = betterParseFloat(monthlyPayment);
  } catch (error) {
    console.error('Error determining prequal approval status:', error);
    prequal.prequalApprovalStatus = PrequalApprovalStatus.notApproved;
    cleanPrequalApproval(prequal);
  }
};

/**
 * Creates a Salesforce lead by posting the prequal object to the Salesforce API.
 *
 * @param {Prequal} prequal - The prequal object to be posted.
 * @return {Promise<void>} A promise that resolves when the post request is complete.
 */
export const createPrequalSalesforceLead = async (prequal: Prequal): Promise<void> => {
  if (!shouldCreateLead(SALESFORCE_LEAD, prequal)) {
    return;
  }

  const urlLead = isProduction() ? `${PROD_TRIDENT_API_URL}/lead` : `${QA_TRIDENT_API_URL}/lead`;
  const leadUUID = generateUUID();
  prequal.leadUUID = leadUUID;

  const data = {
    // form data
    shoppingSegment: prequal.shoppingSegment,
    purchasePrice: prequal.purchasePrice,
    downPayment: prequal.downPayment,
    loanAmount: prequal.loanAmount,
    firstName: prequal.firstName,
    lastName: prequal.lastName,
    email: prequal.email,
    phone: prequal.phone,
    ficoScore: prequal.creditScore,
    // listing data (if from BDP)
    listingId: prequal.imtId !== 'NONBDP' ? prequal.imtId : undefined,
    leadSource: prequal.source,
    leadSubsource: prequal.subsource ? prequal.subsource.toString() : undefined,
    utmCampaign: prequal?.utm?.utmCampaign,
    utmContent: prequal?.utm?.utmContent,
    leadPage: prequal.leadPage,
    leadType: 'boatPurchase',
    leadOrigin: prequal?.leadOrigin || LeadOrigin.prequal,
    manufacturer: prequal.boatMake,
    model: prequal.boatModel,
    year: prequal.boatYear,
    boatType: prequal.boatType,
    length: prequal.boatLength,
    seller: prequal.sellerName,
    sellerEmail: prequal.sellerEmail,
    sellerType: prequal.sellerType,
    sellerPartyId: prequal.partyId,
    sellerFinanceAdvantage: prequal.sellerFinanceAdvantage,
    // Prequal approval data
    prequalApprovalStatus: prequal.prequalApprovalStatus,
    prequalApprovalTerm: prequal.prequalApprovalTerm,
    prequalApprovalRate: prequal.prequalApprovalRate,
    monthlyPayment: prequal.prequalApprovalMonthlyPayment,
    // Lead data
    prequalApplicationId: prequal.prequalApplicationId,
    leadUUID,
    fullApplicationStatus: FullApplicationStatus.notStarted,
    adGroup: prequal?.marketing?.adGroup,
    adGroupId: prequal?.marketing?.adGroupId,
    campaignId: prequal?.marketing?.campaignId,
    campaignName: prequal?.marketing?.campaignName,
    utmId: prequal?.utm?.utmId,
    utmName: prequal?.utm?.utmName,
    keyCode: prequal?.keyCode,
    promoCode: prequal?.promoCode,
    repCode: prequal?.repCode,
    sourceLabel: prequal?.sourceLabel,
    subSourceLabel: prequal?.subSourceLabel,
    utmMedium: prequal?.utm?.utmMedium,
    utmSource: prequal?.utm?.utmSource,
  };

  const leadData = clearEmptyProps(data);

  try {
    await axios.post(urlLead, leadData);
    prequal.leadEmail = prequal.email;
    prequal.leadApprovalStatus = prequal.prequalApprovalStatus;
    prequal.leadCreatedAt = Date.now();
  } catch (error) {
    console.error('Error creating Salesforce lead:', error);
  }
};

/**
 * Retrieves prequal data from local storage or generates new data based on prequalStore
 *
 * @param {PrequalStore} prequalStore - the PrequalStore object containing prequal data
 * @return {Prequal | {}} the retrieved or generated prequal data
 */
export const getPrequal = (prequalStore: PrequalStore) => {
  const { isBDP } = prequalStore;
  const imtId = prequalStore.imtId || 'NONBDP';
  const storageKey = `prequal-${imtId}`;
  const storedPrequal = localStorage.getItem(storageKey);
  let loadedPrequal: Partial<Prequal>;

  if (storedPrequal) {
    loadedPrequal = JSON.parse(storedPrequal);
  } else {
    if (isBDP) {
      loadedPrequal = addBoatDetails();
    } else {
      loadedPrequal = { imtId: 'NONBDP' };
    }

    loadedPrequal = {
      ...loadedPrequal,
      source: prequalStore.source || BOAT_TRADER_SOURCE_ID,
      subsource: prequalStore.subsource,
      prequalApplicationId: prequalStore.prequalApplicationId,
      utm: {
        utmCampaign: prequalStore?.utm?.utmCampaign,
        utmContent: prequalStore?.utm?.utmContent,
        utmId: prequalStore?.utm.utmId,
        utmMedium: prequalStore?.utm.utmMedium,
        utmName: prequalStore?.utm.utmName,
        utmSource: prequalStore?.utm.utmSource,
        utmTerm: prequalStore?.utm?.utmTerm,
      },
      leadPage: prequalStore.leadPage,
      leadOrigin: prequalStore?.leadOrigin,
      phone: null,
      createdAt: Date.now(),
      updatedAt: Date.now(),
      prequalApprovalStatus: null,
      prequalApprovalTerm: null,
      prequalApprovalRate: null,
      prequalApprovalMonthlyPayment: null,
      fullApplicationId: null,
      fullApplicationStatus: FullApplicationStatus.notStarted,
      fullApplicationStartedAt: null,
      leadUUID: null,
      leadEmail: null,
      leadApprovalStatus: null,
      leadCreatedAt: null,
      completedAt: null,
      marketing: prequalStore?.marketing || null,
      keyCode: prequalStore?.keyCode,
      promoCode: prequalStore?.promoCode,
      repCode: prequalStore?.repCode,
      sourceLabel: prequalStore?.sourceLabel,
      subSourceLabel: prequalStore?.subSourceLabel,
    };

    localStorage.setItem(storageKey, JSON.stringify(loadedPrequal));
  }

  return loadedPrequal;
};

/**
 * @description Handles the submission of the lead
 * @param prequalApplication The prequal data containing the information to be used to create the lead
 * @returns {void}
 */
export const createBoattraderFinanceLead = async (prequalApplication: Prequal) => {
  try {
    const hasImtId = !!prequalApplication?.imtId && prequalApplication?.imtId !== 'NONBDP';
    if (shouldCreateLead(BOATTRADER_LEAD, prequalApplication) && hasImtId) {
      const functions = firebase.functions();
      const submitBoattraderFinanceLead = functions.httpsCallable('submitBoattraderFinanceLead');
      await submitBoattraderFinanceLead({ application: prequalApplication });
    }
  } catch (error) {
    console.log('Error submitting Finance lead', error);
  }
};

/**
 * Saves the prequal data to local storage using the IMT ID as the storage key.
 * IMT ID can be 'NONBDP' if the prequal is not from BDP
 *
 * @param {Prequal} prequal - the prequal data to be saved
 * @return {void}
 */
export const savePrequal = (prequal: Prequal) => {
  const storageKey = `prequal-${prequal.imtId}`;
  prequal.updatedAt = Date.now();
  localStorage.setItem(storageKey, JSON.stringify(prequal));
};

export const camelCaseMarketingParam = (name: string) => {
  let camelCasedName = '';
  const paramName = name.toLowerCase();

  switch (paramName) {
    case 'adgroup':
      camelCasedName = 'adGroup';
      break;
    case 'adgroupid':
      camelCasedName = 'adGroupId';
      break;
    case 'campaignid':
      camelCasedName = 'campaignId';
      break;
    case 'campaign':
      camelCasedName = 'campaignName';
      break;
    default:
      camelCasedName = paramName;
  }

  return camelCasedName;
};

export const getSource = (config: ConfigStore | null, prequal: Partial<Prequal>): number => {
  let sourceId: number | undefined = BOAT_TRADER_SOURCE_ID;

  if (config) {
    const sourceIdParam = getNumericQueryParam({ param: 'source' });

    if (prequal?.source) {
      sourceId = prequal.source;
    } else if (sourceIdParam) {
      sourceId = sourceIdParam;
    } else {
      const source = config.getSourceFromLocation(window.location.href);
      sourceId = source && source.id === 0 ? 0 : source?.id || sourceId;
    }
  }

  return sourceId;
};
