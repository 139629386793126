import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Radio, Row, Select, Typography } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import useConfig from '../../stores/config';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import MoneyInput from '../../components/MoneyInput/MoneyInput';
import { formatCurrency, pushToDataLayer } from '../../utils';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from './meta';
import AppFormItem from '../components/AppFormItem';
import { Loan, LoanPurpose, LoanPurpose as LoanPurposeEnum, OwnershipType } from '../../schema';
import { FF_FULL_APPLICATION_STYLING, GOODSAM_SOURCE_ID, isProduction } from '../../constants';
import styles from './FinanceStep.module.scss';
import { StepFooter } from '../components/StepFooter/StepFooter';
import classNames from 'classnames';
import { AddOnServices } from '../components/AddOnServices';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FullAppOptimizeV1Flag } from '../../featureFlags';

const { Text } = Typography;

const formItemLayout: {} = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const minimalFinancedAmount: { [_key: string]: any } = {
  default: {
    new: {
      rv: 10000,
      boat: 25000,
      aircraft: 50000,
    },
    used: {
      rv: 10000,
      boat: 25000,
      aircraft: 50000,
    },
    refinance: {
      rv: 25000,
      boat: 25000,
      aircraft: 50000,
    },
    cashrecapture: {
      rv: 10000,
      boat: 25000,
      aircraft: 50000,
    },
  },
  goodSam: {
    minAmount: 10000,
  },
};

export function getMinAllowedAmount(loan: Loan): number {
  const GOOD_SAM: string = 'goodSam';
  const provider = loan.source === GOODSAM_SOURCE_ID ? GOOD_SAM : 'default';
  const loanType = (loan?.loanType || '')?.toLowerCase();
  const purpose = loan.purpose.toLowerCase();

  return provider === GOOD_SAM
    ? minimalFinancedAmount.goodSam.minAmount
    : minimalFinancedAmount[provider][loanType][purpose];
}

type Props = {};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const schemaValidator = createSchemaValidator(pickFields('finance'));
const llcSchemaValidator = createSchemaValidator(pickFields('details'));

const FinanceStep: React.FC<Props> = observer(() => {
  const [isLowFinancedAmount, setIsLowFinancedAmount] = useState(false);
  const [minDownPayment, setMinDownPayment] = useState(0);
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const config = useConfig();
  const activeFeatureFlags = useFeatureFlags();
  const isFeatureFlagOn = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  useEffect(() => {
    const { financedAmount } = loanStore;

    if (loan && financedAmount) {
      const checker = financedAmount < getMinAllowedAmount(loan as Loan);
      setIsLowFinancedAmount(checker);
    }
  }, [loan, loanStore, loanStore.financedAmount]);

  useEffect(() => {
    const loanPurpose = loan?.purpose as LoanPurposeEnum;

    if (![LoanPurposeEnum.rv, LoanPurposeEnum.boat].includes(loanPurpose)) {
      setMinDownPayment(0);
      return;
    }

    const purchasePrice = loan?.finance?.purchasePrice ?? 0;
    const maxLoanAmountForZeroDownPayment =
      config?.settings?.downpayment?.maxLoanAmountForZeroDownPayment || 100_000;

    if (purchasePrice >= 200_000) {
      setMinDownPayment(purchasePrice * 0.15);
      return;
    }

    if (purchasePrice > maxLoanAmountForZeroDownPayment) {
      setMinDownPayment(purchasePrice * 0.1);
      return;
    }

    setMinDownPayment(0);
  }, [loan?.finance?.purchasePrice, loan?.purpose, config?.settings?.downpayment]);

  useEffect(() => {
    if (loan) {
      const finance = loanStore.getFinance();
      if (loan.ownershipType === OwnershipType.montanaLlc && finance.salesTax == null) {
        form.setFieldsValue({ salesTax: 0 });
      }
    }
  }, [form, loan, loanStore]);

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'finance page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }

  const finance = loanStore.getFinance();

  const update = (values: any) => {
    loanStore.updateFinance();
    loanStore.setValue('addOnServices', values?.loan?.addOnServices);
    stepFinished();
  };

  const termOptions = loanStore.getTermOptions();

  const getParsedFieldValue = (field: string) => {
    const rawValue = form.getFieldValue(field);
    return parseFloat(((rawValue.toString() as string) || '0').replace(/\D/g, ''));
  };

  const handleOnSubmitCapture: React.FormEventHandler<HTMLFormElement> = (e) => {
    form.validateFields(['downPayment', 'purchasePrice']);
    const downPayment = getParsedFieldValue('downPayment');

    if (minDownPayment > downPayment) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">Boat info</div>}
      <h1>Let's get some details about your loan</h1>
      {loan?.purpose === LoanPurpose.boat && <p>We'll use this to get you the best deal</p>}
      <Row>
        <Col {...centerSmallOffset} />
        <Col {...centerSmall}>
          <Form
            onSubmitCapture={handleOnSubmitCapture}
            form={form}
            {...formItemLayout}
            initialValues={{
              ...finance,
              ownershipType: loan.ownershipType,
              associatedName: loan.associatedName,
            }}
            onFinish={update}
            validateTrigger="blur"
          >
            <AppFormItem
              className="manual-label"
              name="ownershipType"
              required
              rules={[
                llcSchemaValidator('ownershipType', loan, {
                  message: 'Please select one',
                }),
              ]}
            >
              <PlaceholderLabel label="Title Ownership:" htmlFor="ownershipType">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.values(OwnershipType)
                    .map((label) => {
                      if (label === OwnershipType.montanaLlc && loan.purpose !== LoanPurpose.rv)
                        return null;

                      return (
                        <Select.Option key={label} value={label}>
                          {label}
                        </Select.Option>
                      );
                    })
                    .filter((element) => !!element)}
                </Select>
              </PlaceholderLabel>
            </AppFormItem>

            <div className="mobileSpacing" />

            {loan.ownershipType && loan.ownershipType !== OwnershipType.none && (
              <AppFormItem
                name="associatedName"
                rules={[llcSchemaValidator('associatedName', loan)]}
              >
                <PlaceholderLabel
                  label={loan.ownershipType === OwnershipType.trust ? 'Trust name' : 'LLC Name'}
                  htmlFor="associatedName"
                >
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            )}

            <div className="mobileSpacing" />

            <AppFormItem
              name="purchasePrice"
              required
              rules={[
                schemaValidator('purchasePrice', finance, {
                  message: 'Please enter the total price you expect to pay',
                  transform: 'float',
                }),
              ]}
            >
              <PlaceholderLabel label="Purchase Price">
                <MoneyInput />
              </PlaceholderLabel>
            </AppFormItem>

            <div className="mobileSpacing" />

            <AppFormItem
              name="salesTax"
              required
              rules={[
                schemaValidator('salesTax', finance, {
                  message:
                    'Please enter the sales tax, enter 0 if not paying sales tax at purchase',
                  transform: 'float',
                  required: true,
                }),
              ]}
            >
              <PlaceholderLabel label="Sales Tax (in dollars)">
                <MoneyInput />
              </PlaceholderLabel>
            </AppFormItem>

            <div className="mobileSpacing" />

            <AppFormItem
              name="downPayment"
              required
              rules={[
                schemaValidator('downPayment', finance, {
                  message: 'Please enter a down payment amount',
                  transform: 'float',
                  required: true,
                }),
              ]}
            >
              <PlaceholderLabel label="Down Payment (in dollars)">
                <MoneyInput />
              </PlaceholderLabel>
            </AppFormItem>
            {loan.hasTrade && (
              <>
                <div className="mobileSpacing" />

                <AppFormItem
                  name="tradeIn"
                  required
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your trade-in value',
                    },
                    schemaValidator('tradeIn', finance, {
                      transform: 'float',
                      required: true,
                    }),
                  ]}
                >
                  <PlaceholderLabel label="How much are you getting for your trade-in?">
                    <MoneyInput />
                  </PlaceholderLabel>
                </AppFormItem>

                <div className="mobileSpacing" />

                <AppFormItem
                  name="tradeInOwed"
                  required
                  rules={[
                    {
                      required: true,
                      message:
                        'Please enter how much is owed on the trade-in, or 0 if nothing owed',
                    },
                    schemaValidator('tradeInOwed', finance, {
                      transform: 'float',
                    }),
                  ]}
                >
                  <PlaceholderLabel label="How much is owed on your trade-in?">
                    <MoneyInput />
                  </PlaceholderLabel>
                </AppFormItem>
              </>
            )}

            <div className="mobileSpacing" />
            <div
              className={classNames({
                onlyForBoats: loan?.purpose === LoanPurpose.boat,
              })}
            >
              <p className={styles.loanAmount}>
                <b>Loan Amount:</b> {formatCurrency(loanStore.financedAmount)}
                <div className="small">(This is calculated for you)</div>
                {isLowFinancedAmount && (
                  <>
                    <br />
                    <Text type="danger">
                      The minimum loan amount for the loan program you selected is
                      {` ${formatCurrency(getMinAllowedAmount(loan))}`}
                    </Text>
                  </>
                )}
                {loan?.finance?.downPayment !== undefined &&
                  minDownPayment > (loan.finance.downPayment ?? 0) && (
                    <>
                      <br />
                      <Text type="danger">
                        The minimum down payment required is
                        {` ${formatCurrency(minDownPayment)}`}
                      </Text>
                    </>
                  )}
              </p>
            </div>
            <AppFormItem
              name="term"
              required
              hasFeedback={false}
              rules={[
                schemaValidator('term', finance, {
                  message: 'Please select one',
                  custom: (value: number) => {
                    const financedAmount = loanStore.financedAmount || 50000;
                    if (value > 15 && financedAmount < 50000) {
                      throw new Error('Term is invalid for this financed amount');
                    }
                  },
                }),
              ]}
            >
              <ManualLabel label="Desired term (in years):">
                <Radio.Group optionType="button" options={termOptions} />
              </ManualLabel>
            </AppFormItem>
            {!isFeatureFlagOn && <AddOnServices />}
            <ButtonNav
              isNextDisabled={
                isLowFinancedAmount || minDownPayment > (loan?.finance?.downPayment ?? 0)
              }
            />
            <StepFooter type="info" />
          </Form>
        </Col>
      </Row>
    </LoanLayout>
  );
});

export default FinanceStep;
