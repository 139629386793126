import React, { useEffect, useState } from 'react';
import { Button, Modal, Spin, Layout } from 'antd';
import AuthForm from './components/Auth/AuthForm';
import { useLoan } from './stores/store';
import { observer } from 'mobx-react-lite';
import HeadContent from './components/HeadContent/HeadContent';
import useConfig from './stores/config';
import Routes from './Routes';
import { Loan } from './schema';
import getSource from './utils/getSource';
import { restoreTransitions, setFunnelTransitions } from './nav';
import { loadKameleoonFlagsAndTests } from './utils';

declare global {
  interface Window {
    kameleoonHelper: {
      kameleoonProcessGA4: () => void;
    };
    __AB_TEST__: any;
    kameleoonQueue: any[];
    kameleoonSiteKey: string;
    kameleoonDataPending: boolean;
  }
}

export default observer(function App() {
  const loanStore = useLoan();
  const config = useConfig();
  const [transtionsLoaded, setTransitionsLoaded] = useState(false);
  const [abTestConfig, setAbTestConfig] = useState(window.__AB_TEST__?.abTestConfiguration);

  useEffect(() => {
    const fetchTransitions = async () => {
      setTransitionsLoaded(false);
      restoreTransitions();
      await setFunnelTransitions();
      setTransitionsLoaded(true);
    };
    fetchTransitions();
  }, [abTestConfig]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;

    const checkAbTestConfig = async () => {
      if (window.__AB_TEST__?.abTestConfiguration !== abTestConfig) {
        setAbTestConfig(window.__AB_TEST__.abTestConfiguration);
        clearInterval(intervalId);
        restoreTransitions();
        await setFunnelTransitions();

        if (window?.kameleoonHelper?.kameleoonProcessGA4) {
          window.kameleoonHelper.kameleoonProcessGA4();
        }
      } else {
        await loadKameleoonFlagsAndTests(loanStore?.loan);
      }
    };

    intervalId = setInterval(checkAbTestConfig, 500);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!config) {
    return (
      <Layout>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Layout>
    );
  }

  const { source } = getSource(config, loanStore?.loan as Loan);
  let basePath = source?.basePath;
  if (!basePath || basePath.trim().length === 0) {
    basePath = '/apply';
  }

  return (
    <div>
      <HeadContent loan={loanStore.loan} />
      <Modal
        onCancel={() => loanStore.showAuthModal(false, true)}
        footer={
          <Button onClick={() => loanStore.showAuthModal(false, true)} type="default">
            Cancel
          </Button>
        }
        visible={loanStore.authModal}
      >
        <AuthForm onSignedIn={() => loanStore.showAuthModal(false)} />
      </Modal>
      <Modal
        visible={loanStore.error !== null}
        onCancel={() => loanStore.setError(null)}
        footer={
          <Button onClick={() => loanStore.setError(null)} type="default">
            Okay
          </Button>
        }
      >
        {loanStore.error}
      </Modal>
      <Routes transtionsLoaded={transtionsLoaded} basePath={basePath} />
    </div>
  );
});
