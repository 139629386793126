import { IncomeSource } from '../schema';

export function numSalaryIncomeSources(values: any): number {
  let count = 0;
  if (values?.primaryIncomeSource === IncomeSource.salary) {
    count++;
  }
  if (values?.secondaryIncomeSource === IncomeSource.salary) {
    count++;
  }

  return count;
}
