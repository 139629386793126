// https://stackoverflow.com/a/4149393/136453
export function labelize(str: string): string {
  if (str.toLocaleUpperCase() === str) return str;
  return (
    str
      // insert a space before all caps
      .replace(/([A-Z])/g, ' $1')
      // uppercase the first character
      .replace(/^./, (s) => s.toUpperCase())
  );
}
