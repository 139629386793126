import React, { useEffect, useState } from 'react';
import { Col, FormInstance, Input, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { PhoneInput } from './PhoneInput';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from '../steps/meta';
import AppFormItem from './AppFormItem';
import { Employer } from '../../schema';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FullAppOptimizeV1Flag, FullAppStylingFlag } from '../../featureFlags';

type Props = {
  employer: Employer;
  form: FormInstance;
  formItemPrefix?: string;
  isCoBorrower?: boolean;
};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const splitField = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 };
const wholeWidth = { xs: 24, sm: 24, md: 24, lg: 24, xl: 24 };

const schemaValidator = createSchemaValidator(pickFields('employer'));

const EmployerForm: React.FC<Props> = observer(
  ({ employer, form, formItemPrefix = '', isCoBorrower = false }) => {
    const { years } = employer;
    const [monthsHidden, setMonthsHidden] = useState(false);
    const activeFeatureFlags = useFeatureFlags();
    const isNewFunnelStyling = !!activeFeatureFlags[FullAppStylingFlag.name];

    useEffect(() => {
      if (years && years >= 3) {
        setMonthsHidden(true);
        form.setFieldsValue({
          months: 0,
        });
      } else {
        setMonthsHidden(false);
      }
    }, [years, form]);

    const yearOptions = [];
    for (let i = 0; i < 100; i++) {
      yearOptions.push(i);
    }

    const composeName = (name: string) => `${formItemPrefix}${name}`;

    return (
      <>
        <Row>
          <Col {...centerSmallOffset} />
          <Col {...centerSmall}>
            <AppFormItem
              name={composeName('name')}
              required
              initialValue={employer.name}
              rules={[
                schemaValidator('name', employer, {
                  message: 'Please enter the employer name',
                }),
              ]}
            >
              <PlaceholderLabel label="Employer name" htmlFor="name">
                <Input />
              </PlaceholderLabel>
            </AppFormItem>

            <div className="mobileSpacing" />

            <AppFormItem
              name={composeName('position')}
              required
              initialValue={employer.position}
              rules={[
                schemaValidator('position', employer, {
                  message: 'Please enter the position held',
                }),
              ]}
            >
              <PlaceholderLabel label="Position" htmlFor="position">
                <Input />
              </PlaceholderLabel>
            </AppFormItem>

            <div className="mobileSpacing" />

            <AppFormItem
              name={composeName('typeOfBusiness')}
              required
              initialValue={employer.typeOfBusiness}
              rules={[
                schemaValidator('typeOfBusiness', employer, {
                  message: 'Please enter the type of business',
                }),
              ]}
            >
              <PlaceholderLabel label="Type of Business" htmlFor="typeOfBusiness">
                <Input />
              </PlaceholderLabel>
            </AppFormItem>

            <div className="mobileSpacing" />

            <Row>
              <Col {...(monthsHidden ? wholeWidth : splitField)}>
                <AppFormItem
                  name={composeName('years')}
                  required
                  initialValue={employer.years}
                  rules={[
                    schemaValidator('years', employer, {
                      message: 'Please enter the years at this business',
                      transform: 'int',
                    }),
                  ]}
                >
                  <PlaceholderLabel label="Number of Years" htmlFor="years">
                    <Select
                      suffixIcon={<SelectArrowIcon />}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        String(option?.children || '').indexOf(input) === 0
                      }
                    >
                      {yearOptions.map((s) => (
                        <Select.Option key={s} value={s}>
                          {s}
                        </Select.Option>
                      ))}
                    </Select>
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>

              <Col {...splitField} hidden={monthsHidden}>
                <AppFormItem
                  required={!monthsHidden}
                  name={composeName('months')}
                  initialValue={employer.months}
                  rules={[schemaValidator('months', employer, { transform: 'int' })]}
                >
                  <PlaceholderLabel label="Months" htmlFor="months">
                    <Select
                      suffixIcon={<SelectArrowIcon />}
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        String(option?.children || '').indexOf(input) === 0
                      }
                    >
                      {/* eslint-disable-next-line prefer-spread */}
                      {Array.apply(null, Array(12)).map((s, index) => (
                        // eslint-disable-next-line
                        <Select.Option key={index} value={index}>
                          {index}
                        </Select.Option>
                      ))}
                    </Select>
                  </PlaceholderLabel>
                </AppFormItem>
              </Col>
            </Row>

            <AppFormItem
              name={composeName('phone')}
              rules={[
                {
                  required: true,
                  message: 'Please enter a phone number',
                },
                schemaValidator('phone', employer),
              ]}
              required
              initialValue={employer.phone}
            >
              <PlaceholderLabel label="Business Phone" htmlFor="phone">
                <PhoneInput placeholder={isNewFunnelStyling ? 'Enter Phone' : undefined} />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        </Row>
      </>
    );
  }
);

export default EmployerForm;
