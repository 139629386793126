import React from 'react';
import { Spin } from 'antd';
import LoanLayout from './LoanLayout';

export default function Loading() {
  return (
    <LoanLayout>
      <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
    </LoanLayout>
  );
}
