import { ConfigStore } from '../stores/config';
import { Loan, Source } from '../schema';
import { DEFAULT_SOURCE_ID, isProduction, isDev, isTridentFunding, isStage } from '../constants';

export default function getSource(
  config: ConfigStore | null,
  loan: Loan
): { error: any; source: Source | null } {
  let source = null;
  let error = null;

  if (config) {
    const params = new URLSearchParams(window.location.search);
    const sourceIdParam = params.get('source');
    const debug = params.get('debug');

    let sourceId: number | undefined = DEFAULT_SOURCE_ID;

    if (loan?.source) {
      sourceId = loan.source;
    } else if (sourceIdParam) {
      sourceId = parseInt(sourceIdParam, 10);
    } else {
      source = config.getSourceFromLocation(window.location.href);
    }

    try {
      // source may have been initialized from location
      if (!source) {
        source = config.getSource(sourceId);
        const isPrequalFunnel = window.location.pathname.includes('/pre-qualify');

        // check for apply.tridentfunding.com
        const allowSourceMismatch =
          isPrequalFunnel ||
          isDev() ||
          debug === 'true' ||
          (!isProduction() && source.id === DEFAULT_SOURCE_ID) ||
          (isTridentFunding(window.location.host) && isTridentFunding(source.hostname));

        if (!window.location.href.includes(source.hostname) && !allowSourceMismatch) {
          throw new Error('Unauthorized source');
        }
      }
    } catch (e) {
      console.error(e);
      error = e;
      source = config.getSourceFromLocation(window.location.href);
    }
  }

  return {
    error,
    source,
  };
}
