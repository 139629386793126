import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { usePrequal } from '../../stores/prequalStore';
import { pushToDataLayer } from '../../utils';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import RadioButton from '../components/RadioButton/RadioButton';
import { stepFinished } from '../../prequalNav';
import { Prequal, ShoppingSegmentType } from '../../schema';
import { ReactComponent as BoatIcon } from '../../icons/prequal_boat.svg';
import { ReactComponent as MedalIcon } from '../../icons/prequal_medal.svg';
import styles from './PurchaseTimeframeStep.module.scss';
import PreQualDisclaimer from '../components/Disclaimer/Disclaimer';
import { usePrequalSubsource } from '../../hooks/usePrequalSubsource';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

export type FooterCardProps = {
  icon: any;
  title: String;
  text: String;
};

const FooterCard: React.FC<FooterCardProps> = (props) => {
  const { icon, title, text } = props;

  return (
    <div className="card">
      <div className="header">
        <div className="icon"> {icon}</div>
        <div className="title">{title}</div>
      </div>
      <div className="text">{text}</div>
    </div>
  );
};

const shoppingSegmentStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [form] = useForm();
  usePrequalSubsource(prequalStore);

  const updateValues = (values: any) => {
    prequalStore.setShoppingSegment(values);
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'continue - where are you in the process',
    });
    stepFinished();
  };

  const checkshoppingSegment = () => {
    const values = form.getFieldsValue();
    setCanContinue(values.shoppingSegment);
    const shoppingSegmentType = ShoppingSegmentType;
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: values.shoppingSegment,
    });
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'purchase timeframe',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    if (values.shoppingSegment) {
      setCanContinue(true);
    }
  }, [prequal]);

  const header = {
    title: 'Purchase Time Frame',
    subtitle: 'Where are you in the boat buying process?',
    content: "We'll work to find you the right loan to fit your needs.",
  };

  return (
    <PreQualFunnelLayout>
      <SectionHeader header={header} />
      <Form
        {...formItemLayout}
        form={form}
        initialValues={prequal}
        onFinish={updateValues}
        onValuesChange={checkshoppingSegment}
      >
        <RadioButton name="shoppingSegment" type={ShoppingSegmentType} />
        <PreQualDisclaimer text="Subject to loan program requirements and credit approval. Certain fees, closing costs, and restrictions may apply." />
        <NavigationRow isFirstStep canContinue={canContinue} form={form} />
      </Form>

      <div className={styles.cardContainer}>
        <FooterCard
          icon={<BoatIcon />}
          title="We compare so you save"
          text="We'll compare over 15 marine lenders to get you the best rate and terms"
        />
        <FooterCard
          icon={<MedalIcon />}
          title="No Credit Impact"
          text="Get pre-qualified with no impact to your credit score"
        />
      </div>
    </PreQualFunnelLayout>
  );
});

export default shoppingSegmentStep;
