import { Form, Input } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import { observer } from 'mobx-react-lite';
import React from 'react';
import styles from './TextInput.module.scss';

type Props = {
  name: NamePath;
  labelText: string;
  subLabelText?: string;
  validator?: (_: any, value: any) => void | Promise<any>;
};

const TextInput: React.FC<Props> = observer((props) => {
  const { name, labelText, validator } = props;

  return (
    <>
      <div className={styles.labelContainer}>
        <div className={styles.labelText}>{labelText}</div>
      </div>
      <Form.Item className={styles.textInputField} label={name} name={name} rules={[{ validator }]}>
        <Input placeholder={`Enter ${labelText}`} />
      </Form.Item>
    </>
  );
});

export default TextInput;
