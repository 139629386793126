import React from 'react';
import styles from './Disclaimer.module.scss';

type Props = {
  text: string;
};

const PreQualDisclaimer = ({ text }: Props) => (
  <div>
    <p className={styles.pqDisclaimerText}>{text}</p>
  </div>
);

export default PreQualDisclaimer;
