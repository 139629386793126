import React, { useState } from 'react';

import classNames from 'classnames';
import styles from './PlaceholderLabel.module.scss';
import { useFormItem } from '../../hooks/useFormItem';

type Props = {
  children?: React.ReactNode;
  label: React.ReactNode;
  currentValue?: any;
  htmlFor?: string | undefined;
  id?: string | undefined;
};

const PlaceholderLabel: React.FC<Props> = ({
  children,
  label,
  htmlFor,
  id,
  currentValue,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const formItemProps = useFormItem();
  const inputProps: any = rest;
  const value =
    inputProps.value !== null && inputProps.value !== undefined ? inputProps.value : currentValue;
  const hasValue = value !== null && value !== undefined && String(value).length > 0;
  const labelClass = focus || hasValue ? classNames(styles.label, styles.labelFloat) : styles.label;

  // ant passes id as long as it has a name, make sure to replace on the cloned element
  if (!htmlFor) {
    htmlFor = id;
  }
  inputProps.id = id;

  const childrenWithProps = React.Children.map(children, (child) => {
    // Checking isValidElement is the safe way and avoids a typescript error too.
    if (React.isValidElement(child)) {
      // mix in new props
      return React.cloneElement(child, inputProps);
    }
    return child;
  });

  return (
    <div
      className={classNames(styles.placeLabel, focus && styles.focused)}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      {childrenWithProps}
      <label htmlFor={htmlFor} className={labelClass}>
        {label}
        {formItemProps.required && (
          <span className={classNames(!hasValue && 'required-mark')}>*</span>
        )}
      </label>
    </div>
  );
};

export default PlaceholderLabel;
