import React from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import Cleave, { displayName } from 'cleave.js/react';
import { NamePath } from 'antd/lib/form/interface';
import styles from './MoneyInput.module.scss';

type Props = {
  name: NamePath;
  labelText: string;
  subLabelText?: string;
  validateTrigger?: string;
  validator: (_: any, value: any) => void | Promise<any>;
};

const MoneyInput: React.FC<Props> = observer((props) => {
  const { name, labelText, subLabelText, validator, validateTrigger } = props;

  return (
    <>
      <div className={styles.labelContainer}>
        <div className={styles.labelText}>{labelText}</div>
        {subLabelText && <div className={styles.subLabelText}>{subLabelText}</div>}
      </div>
      <Form.Item label={name} name={name} rules={[{ validator }]} validateTrigger={validateTrigger}>
        <Cleave
          options={{
            prefix: '$',
            noImmediatePrefix: true,
            rawValueTrimPrefix: true,
            numeral: true,
            numericOnly: true,
            numeralPositiveOnly: true,
            numeralDecimalScale: 2,
          }}
          className={styles.moneyInputField}
          inputMode="decimal"
        />
      </Form.Item>
    </>
  );
});

export default MoneyInput;
