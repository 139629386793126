import firebase from 'firebase/compat/app';
import { action, makeAutoObservable, observe, toJS, when } from 'mobx';
import { useParams } from 'react-router-dom';
import { Decimal } from 'decimal.js-light';
import { useFirestore } from '../hooks/useFirestore';
import { betterParseFloat, loadKameleoonFlagsAndTests } from '../utils';
import { ReactElement } from 'react';
import {
  Address,
  ApplicantType,
  Asset,
  Borrower,
  Collateral,
  Employer,
  FinanceDetails,
  AddOnService,
  Liability,
  Loan,
  LoanType,
  OtherAsset,
  RealEstate,
  SellerType,
  Theme,
  LoanPurpose,
} from '../schema';
import { isDev } from '../constants';

interface FormValues {
  [key: string]: any;
}

export function filterUndefined<T>(values: FormValues): T {
  Object.entries(values).forEach(([key, value]) => {
    if (value === undefined) {
      values[key] = null;
    } else if (value !== null && typeof value === 'object') {
      values[key] = filterUndefined(value);
    }
  });
  return values as T;
}

enum LoanStatus {
  'init',
  'pending',
  'done',
  'not_found',
}

export class LoanStore {
  id: string | null = null;

  userIsAdmin = false;

  loan: Loan | null = null;

  theme: Theme | null = null;

  status: keyof typeof LoanStatus = 'init';

  ref: firebase.firestore.DocumentReference | null = null;

  private firestore: firebase.firestore.Firestore;

  navDirection: 1 | -1 | 'other' = 1;

  authModal = false;

  authModalCancelled = false;

  onSave: Promise<void>;

  private resolve: null | ((value: PromiseLike<void> | void) => void);

  private observerDisposer: any;

  dirty: boolean = false;

  private snapshotDisposer: (() => void) | undefined;

  submitting = false;

  error: ReactElement | null = null;

  isUpgradingUser: boolean = false;

  constructor(firestore: firebase.firestore.Firestore) {
    this.firestore = firestore;

    this.resolve = null;
    this.onSave = new Promise<void>((resolve) => (this.resolve = resolve));

    makeAutoObservable(this, {
      ref: false,
      submit: false,
      onSave: false,
      setError: action.bound,
      showAuthModal: action.bound,
    });
  }

  /**
   * notify anybody waiting for the onSave promise
   */
  saved() {
    if (this.resolve) {
      this.resolve();
    }
    // setup a new onsave for the next one
    this.onSave = new Promise<void>((resolve) => (this.resolve = resolve));
  }

  updateDetails() {
    if (!this.loan) throw new Error('Missing loan');
    const { loan } = this;
    if (
      (loan.seller && loan.seller !== SellerType.dealer) ||
      loan.loanType === LoanType.refinance
    ) {
      loan.hasTrade = false;
    }
    if (loan.loanType === LoanType.new) loan.seller = SellerType.dealer;
  }

  getCollateral(): Collateral {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.collateral) this.loan.collateral = {} as Collateral;
    return this.loan.collateral;
  }

  getFinance(): FinanceDetails {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.finance) this.loan.finance = {} as FinanceDetails;
    return this.loan.finance;
  }

  getServices(): AddOnService[] {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.addOnServices) this.loan.addOnServices = [] as AddOnService[];
    return this.loan.addOnServices;
  }

  get financedAmount(): number | undefined | null {
    let result: number | undefined | null;
    if (this.loan && this.loan.finance) {
      const { finance } = this.loan;
      if (this.loan.loanType === LoanType.refinance) {
        result = finance.purchasePrice;
      } else if (this.loan.loanType === LoanType.cashRecapture) {
        result = finance?.cashRecaptureAmount;
      } else {
        result = new Decimal(finance.purchasePrice || 0)
          .add(finance.salesTax || 0)
          .sub(finance.downPayment || 0)
          .sub(finance.tradeIn || 0)
          .add(finance.tradeInOwed || 0)
          .toDecimalPlaces(2)
          .toNumber();
      }
    }
    return result;
  }

  updateFinance() {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.finance) throw new Error('Missing finance');
    // eslint-disable-next-line prefer-destructuring
    const finance: Partial<FinanceDetails> = this.loan.finance;
    finance.financedAmount = this.financedAmount || 100000;
    if (finance.financedAmount < 50000 && finance.term === 20) {
      finance.term = undefined;
      finance.term = undefined;
    }
    if (this.loan.loanType === LoanType.cashRecapture) {
      finance.purchasePrice = finance.cashRecaptureAmount || 0;
    }
  }

  getTrade(): Collateral {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.trade) this.loan.trade = {} as Collateral;
    return this.loan.trade;
  }

  getBorrower(): Borrower {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) this.loan.borrower = {} as Borrower;
    return this.loan.borrower;
  }

  setupAssets(borrower: Borrower) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.assets) borrower.financialStatement.assets = [];
  }

  addAsset(borrower: Borrower, asset: Asset, editingCol: number | null) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement?.assets) throw new Error('missing assets');
    if (editingCol === null) {
      borrower.financialStatement.assets.push(filterUndefined(asset));
    } else {
      borrower.financialStatement.assets[editingCol] = filterUndefined(asset);
    }
  }

  setupOtherAssets(borrower: Borrower) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.otherAssets) borrower.financialStatement.otherAssets = [];
  }

  addOtherAsset(borrower: Borrower, asset: OtherAsset, editingCol: number | null) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement?.otherAssets) throw new Error('missing realEstate');
    if (editingCol === null) {
      borrower.financialStatement.otherAssets.push(filterUndefined(asset));
    } else {
      borrower.financialStatement.otherAssets[editingCol] = filterUndefined(asset);
    }
  }

  removeAsset(borrower: Borrower, row: number) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.assets) borrower.financialStatement.assets = [];
    borrower.financialStatement.assets.splice(row, 1);
  }

  setupLiabilities(borrower: Borrower) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.liabilities) borrower.financialStatement.liabilities = [];
  }

  addLiability(borrower: Borrower, liability: Liability, editingCol: number | null) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.liabilities) borrower.financialStatement.liabilities = [];
    if (liability.balance) liability.balance = betterParseFloat(liability.balance) || 0;
    if (liability.payment) liability.payment = betterParseFloat(liability.payment) || 0;
    if (editingCol === null) {
      borrower.financialStatement.liabilities.push(filterUndefined(liability));
    } else {
      borrower.financialStatement.liabilities[editingCol] = filterUndefined(liability);
    }
  }

  removeLiability(borrower: Borrower, row: number) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.liabilities) borrower.financialStatement.liabilities = [];
    borrower.financialStatement.liabilities.splice(row, 1);
  }

  setupRealEstate(borrower: Borrower) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.realEstate) borrower.financialStatement.realEstate = [];
  }

  addRealEstate(borrower: Borrower, realEstate: RealEstate, editingCol: number | null) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement?.realEstate) throw new Error('missing realEstate');
    if (editingCol === null) {
      borrower.financialStatement.realEstate.push(filterUndefined(realEstate));
    } else {
      borrower.financialStatement.realEstate[editingCol] = filterUndefined(realEstate);
    }
  }

  removeRealEstate(borrower: Borrower, row: number) {
    if (!this.loan) throw new Error('Missing loan');
    if (!borrower.financialStatement) borrower.financialStatement = {};
    if (!borrower.financialStatement.realEstate) borrower.financialStatement.realEstate = [];
    borrower.financialStatement.realEstate.splice(row, 1);
  }

  get totalAssets(): Decimal {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) return new Decimal(0);
    if (!this.loan.borrower.financialStatement) return new Decimal(0);
    if (!this.loan.borrower.financialStatement) return new Decimal(0);

    const stmt = this.loan.borrower.financialStatement;
    let total = new Decimal(0);

    if (stmt.assets) {
      for (let i = 0; i < stmt.assets.length; i++) {
        total = total.add(stmt.assets[i].value || 0);
      }
    }
    if (stmt.otherAssets) {
      for (let i = 0; i < stmt.otherAssets.length; i++) {
        const value = new Decimal(stmt.otherAssets[i].value || 0);
        value.sub(stmt.otherAssets[i].balance || 0);
        total = total.add(value);
      }
    }

    return total;
  }

  get totalRealEstate(): Decimal {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) return new Decimal(0);
    if (!this.loan.borrower.financialStatement) return new Decimal(0);

    const stmt = this.loan.borrower.financialStatement;
    let total = new Decimal(0);

    if (stmt.realEstate) {
      for (let i = 0; i < stmt.realEstate.length; i++) {
        const value = new Decimal(stmt.realEstate[i].value || 0);
        value.sub(stmt.realEstate[i].balance || 0);
        total = total.add(value);
      }
    }

    return total;
  }

  get totalDebt(): Decimal {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) return new Decimal(0);
    if (!this.loan.borrower.financialStatement) return new Decimal(0);

    const stmt = this.loan.borrower.financialStatement;
    let total = new Decimal(0);

    if (stmt.liabilities) {
      for (let i = 0; i < stmt.liabilities.length; i++) {
        total = total.add(stmt.liabilities[i].balance || 0);
      }
    }

    return total;
  }

  get netWorth(): number {
    return this.totalAssets
      .add(this.totalRealEstate)
      .sub(this.totalDebt)
      .toDecimalPlaces(2)
      .toNumber();
  }

  getCoBorrower(): Borrower {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.coborrower) this.loan.coborrower = {} as Borrower;
    return this.loan.coborrower;
  }

  getCoBorrowerAddress(): Address {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.coborrower) throw new Error('missing coborrower object');
    if (!this.loan.coborrower.currentAddress) this.loan.coborrower.currentAddress = {} as Address;
    return this.loan.coborrower.currentAddress;
  }

  getCoBorrowerPreviousAddress(): Address {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.coborrower) throw new Error('missing coborrower object');
    if (!this.loan.coborrower.previousAddress) this.loan.coborrower.previousAddress = {} as Address;
    return this.loan.coborrower.previousAddress;
  }

  getCoBorrowerEmployer(): Employer {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.coborrower) throw new Error('missing coborrower object');
    if (!this.loan.coborrower.currentEmployer)
      this.loan.coborrower.currentEmployer = {} as Employer;
    return this.loan.coborrower.currentEmployer;
  }

  getCoBorrowerEmployer2(): Employer {
    if (!this.loan) {
      throw new Error('Missing loan');
    }

    if (!this.loan.coborrower) {
      throw new Error('missing coborrower object');
    }
    if (!this.loan.coborrower.currentEmployer2) {
      this.loan.coborrower.currentEmployer2 = {} as Employer;
    }
    return this.loan.coborrower.currentEmployer2;
  }

  getCoBorrowerPreviousEmployer(): Employer {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.coborrower) throw new Error('missing coborrower object');
    if (!this.loan.coborrower.previousEmployer)
      this.loan.coborrower.previousEmployer = {} as Employer;
    return this.loan.coborrower.previousEmployer;
  }

  getBorrowerAddress(): Address {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) throw new Error('Missing borrower object');
    if (!this.loan.borrower.currentAddress) this.loan.borrower.currentAddress = {} as Address;
    return this.loan.borrower.currentAddress;
  }

  getBorrowerMailingAddress(): Address {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) throw new Error('Missing borrower object');
    if (!this.loan.borrower.mailingAddress) this.loan.borrower.mailingAddress = {} as Address;
    return this.loan.borrower.mailingAddress;
  }

  getBorrowerPreviousAddress(): Address {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) throw new Error('Missing borrower object');
    if (!this.loan.borrower.previousAddress) this.loan.borrower.previousAddress = {} as Address;
    return this.loan.borrower.previousAddress;
  }

  getEmployer(): Employer {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) throw new Error('Missing borrower object');
    if (!this.loan.borrower.currentEmployer) this.loan.borrower.currentEmployer = {} as Employer;
    return this.loan.borrower.currentEmployer;
  }

  getEmployer2(): Employer {
    if (!this.loan) {
      throw new Error('Missing loan');
    }
    if (!this.loan.borrower) {
      throw new Error('Missing borrower object');
    }
    if (!this.loan.borrower.currentEmployer2) {
      this.loan.borrower.currentEmployer2 = {} as Employer;
    }
    return this.loan.borrower.currentEmployer2;
  }

  getPreviousEmployer(): Employer {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) throw new Error('Missing borrower object');
    if (!this.loan.borrower.previousEmployer) this.loan.borrower.previousEmployer = {} as Employer;
    return this.loan.borrower.previousEmployer;
  }

  getTermOptions(): { label: string; value: number }[] {
    let termOptions;

    if (this.financedAmount) {
      termOptions = [
        { label: '12', value: 12 },
        { label: '10', value: 10 },
      ];

      if (this.financedAmount >= 25_000) {
        termOptions = [{ label: '15', value: 15 }, ...termOptions];
      }
      if (this.financedAmount >= 50_000) {
        termOptions = [{ label: '20', value: 20 }, ...termOptions];
      }
    } else {
      termOptions = [
        { label: '20', value: 20 },
        { label: '15', value: 15 },
        { label: '12', value: 12 },
        { label: '10', value: 10 },
      ];
    }

    return termOptions;
  }

  setSignatures(values: any) {
    if (!this.loan) throw new Error('Missing loan');
    if (!this.loan.borrower) throw new Error('Missing borrower object');
    Object.assign(this.loan.borrower, values.borrower);
    if (values.coborrower && this.loan.applicantType === ApplicantType.joint) {
      Object.assign(this.loan.coborrower, values.coborrower);
    }
  }

  addBorrowerIncome(borrower: Borrower, count: number) {
    borrower.otherCount = count;
  }

  setLoanId(loanId: string): any {
    this.id = loanId;
    const ref = this.firestore.collection('loans').doc(loanId);
    this.listenForDoc(ref);
  }

  listenForDoc(ref: firebase.firestore.DocumentReference) {
    this.ref = ref;
    if (this.snapshotDisposer) this.snapshotDisposer();
    this.snapshotDisposer = this.ref.onSnapshot(
      action((doc: firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>) => {
        // this should be fine to do because the forms are generally uncontrolled,
        // acting on their own so whatever step the user is looking at isn't going
        // to be overwritten by these changes.
        if (isDev()) {
          console.log('updating loan from server');
        }
        const loanData = doc.data() as Loan;
        if (!loanData) {
          console.error('Empty loan data');
          this.setStatus('not_found');
          return;
        }
        this.loan = loanData;
        this.status = 'done';
        loadKameleoonFlagsAndTests(this.loan);

        if (this.observerDisposer) this.observerDisposer();
        this.observerDisposer = observe(this.loan, (change) => {
          if (change.name === 'updatedAt' || change.name === 'currentStep') return;
          this.dirty = true;
        });
      }),
      (e) => {
        if (this.isUpgradingUser) {
          this.setIsUpgradingUser(false);
        } else {
          // If this flag is true the error is expected, false means loan was not found or is unauthorized
          console.error(e);
          this.setStatus('not_found');
        }
      }
    );
  }

  *createLoan(loan: Loan): any {
    loan.workflow = 'draft';
    const addedRef = yield this.firestore.collection('loans').add(loan);
    this.id = addedRef.id;
    loan.id = addedRef.id;
    this.loan = loan;
    this.status = 'done';
    this.listenForDoc(addedRef);
    this.updatePrequalSalesforceLead('Started');
    return loan.id;
  }

  async submit(): Promise<any> {
    if (!this.loan) throw new Error('missing loan');

    await this.syncDoc();

    const functions = firebase.functions();
    const submitApp = functions.httpsCallable('submitApp');
    const result = await submitApp({ loanId: this.id });
    return result.data;
  }

  async updatePrequalSalesforceLead(status: String): Promise<any> {
    if (!this.loan) throw new Error('missing loan');
    if (this.loan.prequalLeadUUID) {
      const functions = firebase.functions();
      const updatePrequalFunction = functions.httpsCallable('updatePrequalSalesforceLead');
      await updatePrequalFunction({
        prequalLeadUUID: this.loan.prequalLeadUUID,
        status,
        loanId: this.loan.id,
      });
    }
  }

  /**
   * clear for a future reuse
   */
  reset() {
    if (this.observerDisposer) this.observerDisposer();
    this.observerDisposer = null;
    if (this.snapshotDisposer) this.snapshotDisposer();
    this.snapshotDisposer = undefined;
    this.status = 'init';
    this.loan = null;
    this.id = null;
    this.ref = null;
    this.dirty = false;
    this.onSave = new Promise<void>((resolve) => (this.resolve = resolve));
  }

  setValue(name: any, value: any) {
    if (!this.loan) return;
    this.loan[name] = value;
  }

  setNestedValue(parent: string, child: string, data: any) {
    if (!this.loan) return;

    this.loan = {
      ...this.loan,
      [parent]: {
        ...this.loan[parent],
        [child]: data,
      },
    };
  }

  syncDoc(nextStep?: string | null): Promise<void> {
    if (!this.ref) throw new Error('Cannot update, missing ref');
    if (!this.loan) throw new Error('Cannot update, missing document');
    if (nextStep && nextStep !== 'saved') this.loan.currentStep = nextStep;
    if (this.dirty) {
      this.dirty = false;
      this.loan.updatedAt = Date.now();
    }
    return this.ref.set(filterUndefined(this.loan), { merge: true });
  }

  setError(error: ReactElement | null) {
    this.error = error;
  }

  /**
   * returns a promise that resolves when the modal is closed
   * @param visible
   */
  showAuthModal(visible: boolean = true, canceled: boolean = false): Promise<void> {
    this.authModal = visible;
    this.authModalCancelled = canceled;
    if (!visible) return Promise.resolve();
    return new Promise((resolve, reject) => {
      when(
        () => !this.authModal,
        () => {
          if (this.authModalCancelled) {
            this.authModalCancelled = false;
            reject();
          } else {
            resolve();
          }
        }
      );
    });
  }

  setUserAdmin(v = true) {
    this.userIsAdmin = v;
  }

  setTheme(newTheme: Theme) {
    this.theme = newTheme;
  }

  setStatus(value: keyof typeof LoanStatus) {
    this.status = value;
  }

  setIsUpgradingUser(value: boolean) {
    this.isUpgradingUser = value;
  }
}

let loanStore: LoanStore | null = null;
const w: any = window;

export function useLoan(): LoanStore {
  const firestore = useFirestore();
  let params: any = {};

  try {
    // this errors if there's no match
    // eslint-disable-next-line react-hooks/rules-of-hooks
    params = useParams();
  } catch (e) {
    // don't need this
  }

  if (loanStore) {
    if (params.loanId && params.loanId !== loanStore.id) {
      loanStore.setLoanId(params.loanId);
    }
    return loanStore;
  }

  const loanId = localStorage.getItem('loanId');
  loanStore = new LoanStore(firestore);
  if (params.loanId) {
    loanStore.setLoanId(params.loanId);
  } else if (loanId !== null) {
    loanStore.setLoanId(loanId);
  }

  w.TF = w.TF || {};
  w.TF.store = loanStore;
  return loanStore;
}

export function getLoanStore() {
  return loanStore;
}

/**
 * for tests
 */
export function setLoanStore(store: LoanStore | null) {
  loanStore = store;
}

w.toJS = toJS;
