import React from 'react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../stores/store';
import AppFormItem from '../../loan/components/AppFormItem';
import { getUrlCurrentStep } from '../../utils/urls';
import { getNextStep, gotoNextStep } from '../../nav';
import useHistory from '../../hooks/useHistory';
import { pushToDataLayer, getStepLabel } from '../../utils/gtm';

type Props = {};

const NavNextButton: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const history = useHistory();
  const isSubmit = getUrlCurrentStep(history.location.pathname) === 'submit';
  const disabled = isSubmit && loanStore?.loan?.workflow === 'submitted';

  return (
    <AppFormItem>
      <Button
        style={{ marginLeft: '.5rem' }}
        name="next"
        disabled={disabled}
        type="primary"
        htmlType="submit"
        onClick={() => {
          loanStore.navDirection = 1;
          let nextStep = getNextStep();
          if (getUrlCurrentStep(history.location.pathname) === 'submit') {
            nextStep = 'success';
          }
          const { loan } = loanStore;
          if (loan) {
            pushToDataLayer('e_applicationAction', {
              application_category: 'financing application',
              application_action: isSubmit ? 'submit' : 'next',
              application_label: getStepLabel(loan.currentStep),
              loan_purpose: loan.purpose.toLowerCase(),
            });
          }
          gotoNextStep(history, nextStep);
          return false;
        }}
      >
        {isSubmit ? 'Already submitted' : 'Next'}
      </Button>
    </AppFormItem>
  );
});

export default NavNextButton;
