import { Decimal } from 'decimal.js-light';

let formatter: null | Intl.NumberFormat = null;

export function formatCurrency(v?: Decimal | number | null) {
  if (!v) return '$0.00';
  if (v instanceof Decimal) v = v.toNumber();
  if (!formatter) {
    formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
  }

  return formatter.format(v);
}
