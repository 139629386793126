import React, { FC, ReactElement } from 'react';
import { Helmet } from 'react-helmet';
import { checkStringMultipleIncludes } from '../../utils';
import { getMetaTags, TagsSet } from './metadata';
import { DEFAULT_THEME } from '../../constants';
import getSource from '../../utils/getSource';
import useConfig from '../../stores/config';
import { Loan } from '../../schema';

type Props = {
  loan: any;
};

const HeadContent: FC<Props> = ({ loan }): ReactElement | null => {
  const config = useConfig();
  const { source } = getSource(config, loan as Loan);
  const sourceDisplayName = source?.displayName || 'Trident Funding LLC';

  const { pathname } = window.location;
  const isNotALoan: boolean = checkStringMultipleIncludes(pathname, ['apps', 'profile']);

  const pageName: string =
    isNotALoan || !loan || loan.currentStep === 'type' ? 'home' : loan.purpose.toLowerCase();

  const meta: TagsSet = getMetaTags({ page: pageName, sourceName: sourceDisplayName });

  if (!meta) return null;

  return (
    <Helmet>
      <title>{meta.title}</title>
      <meta name="description" content={meta.description} />
      <link
        rel="icon"
        type="image/ico"
        href={source?.theme?.favicon || DEFAULT_THEME.favicon}
        sizes="16x16"
      />
      <link rel="apple-touch-icon" href={source?.theme?.favicon || DEFAULT_THEME.favicon} />
    </Helmet>
  );
};

export default HeadContent;
