import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { usePrequal } from '../../stores/prequalStore';
import { pushToDataLayer } from '../../utils';
import { betterParseFloat } from '../../utils/betterParseFloat';
import PreQualFunnelLayout from '../PreQualFunnelLayout';
import { NavigationRow } from '../components/NavigationRow/NavigationRow';
import SectionHeader from '../components/SectionHeader/SectionHeader';
import MoneyInput from '../components/MoneyInput/MoneyInput';
import { stepFinished } from '../../prequalNav';
import { Prequal } from '../../schema';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const BoatPriceStep = observer(() => {
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const [canContinue, setCanContinue] = useState(false);
  const [subLabelText, setSubLabelText] = useState('');
  const [form] = useForm();

  const updateValues = (values: any) => {
    prequalStore.setPurchasePrice(values);
    pushToDataLayer('link_click', {
      action_type: 'click',
      action_label: 'Boat Purchase Price - Continue',
    });
    stepFinished();
  };

  const checkPurchasePrice = () => {
    const { purchasePrice } = form.getFieldsValue();
    const parsedPrice = betterParseFloat(purchasePrice) ?? 0;
    setCanContinue(parsedPrice > 0 && parsedPrice >= 25_000);
  };

  const validator = (_: any, value: any) => {
    const purchasePrice = betterParseFloat(value) ?? NaN;
    if (Number.isNaN(purchasePrice)) {
      return Promise.reject(new Error('This field is required'));
    }
    if (purchasePrice < 25000) {
      return Promise.reject(
        new Error('We’re not currently offering loans for boats under $25,000.')
      );
    }
    return Promise.resolve();
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      detailed_page_name: 'boat price',
      site_section: 'loan application',
    });
  }, []);

  useEffect(() => {
    const values = prequal as Prequal;
    if (values.purchasePrice) {
      setCanContinue(true);
      form.setFieldsValue({
        purchasePrice: values.purchasePrice.toFixed(2),
      });
    } else {
      setSubLabelText('We offer loans from $25,000 to $10,000,000');
    }
  }, [form, prequal]);

  const header = {
    title: 'Financing Details',
    subtitle: "What's the estimated purchase price of your new boat?",
    content: 'You can always change this later.',
  };

  return (
    <PreQualFunnelLayout>
      <SectionHeader header={header} />
      <Form
        {...formItemLayout}
        form={form}
        initialValues={prequal}
        onFinish={updateValues}
        onValuesChange={checkPurchasePrice}
      >
        <MoneyInput
          labelText="Boat Purchase Price"
          name="purchasePrice"
          validator={validator}
          validateTrigger="onSubmit"
          subLabelText={subLabelText}
        />
        <NavigationRow canContinue={canContinue} form={form} />
      </Form>
    </PreQualFunnelLayout>
  );
});

export default BoatPriceStep;
