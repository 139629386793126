import React, { useRef } from 'react';

/**
 * make google gods happy
 * @constructor
 */
export default function PlacesAttribution() {
  const ref = useRef(null);
  return <div id="places-attribution" ref={ref} />;
}
