import classNames from 'classnames';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { Route } from '../../../nav';
import CheckIcon from './CheckIcon';
import styles from './ProgressBar.module.scss';

type ProgressBarStepPosition = 'start' | 'middle' | 'end';

type ProgressBarStepStatus = 'pending' | 'current' | 'done';

export type ProgressBarStepProps = {
  step: Route;
  label: string;
  status: ProgressBarStepStatus;
  position: ProgressBarStepPosition;
};

const ProgressBarStep: FC<ProgressBarStepProps> = (props) => (
  <div className={styles.progressBarStep}>
    <div className={styles.progressBarStep__progressContainer}>
      <div
        className={classNames(
          styles.progressBarStep__bar,
          props.position === 'start' && styles['progressBarStep__bar--hidden'],
          ['current', 'done'].includes(props.status) && styles['progressBarStep__bar--active']
        )}
      />
      <div
        className={classNames(
          styles.progressBarStep__center,
          styles[`progressBarStep__center--${props.status}`]
        )}
      >
        {props.status === 'current' && <div className={styles.progressBarStep__dot} />}
        {props.status === 'done' && <CheckIcon />}
      </div>
      <div
        className={classNames(
          styles.progressBarStep__bar,
          props.position === 'end' && styles['progressBarStep__bar--hidden'],
          ['current', 'done'].includes(props.status) && styles['progressBarStep__bar--active']
        )}
      />
    </div>
    <div className={styles.progressBarStep__labelContainer}>
      {props.status === 'done' ? (
        <Link className={styles.progressBar__label} to={props.step}>
          {props.label}
        </Link>
      ) : (
        <span
          className={classNames(
            styles.progressBar__label,
            props.status === 'current' && styles['progressBarStep__label--current']
          )}
        >
          {props.label}
        </span>
      )}
    </div>
  </div>
);

export default ProgressBarStep;
