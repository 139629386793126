import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import { useUser } from '../../hooks/useUser';
import { gotoNextStep } from '../../nav';
import useHistory from '../../hooks/useHistory';
import { Loan, LoanPurpose, SalesRepsByCode } from '../../schema';
import { fetchUnfinishedLoan } from '../../utils/fetchUnfinishedLoan';

type Props = {
  salesReps: SalesRepsByCode;
};

const OldLinkRedirectStep: React.FC<Props> = observer(function OldLinkRedirectStep({ salesReps }) {
  const loanStore = useLoan();
  const user = useUser();
  const history = useHistory();

  React.useEffect(() => {
    const goHome = () => {
      history.push('/');
    };
    const goToUnfinishedApp = (loanId: string, step: string) => {
      history.push(`/${loanId}/${step}`);
    };
    if (!user) {
      console.error('missing user!');
      // wait
      return;
    }

    // god awful bullshit of the old app. what decade were they coding for?
    const params: { [key: string]: string } = {};
    let { hash } = window.location;
    if (!hash) return goHome();

    hash = hash.replace('#!?', '');

    const pairs = hash.split('&');
    for (let p = 0; p < pairs.length; p++) {
      const tup = pairs[p].split('=');
      params[decodeURIComponent(tup[0])] = decodeURIComponent(tup[1]);
    }

    let purpose: LoanPurpose | null = null;
    const ltype = params.type?.toLocaleLowerCase();
    if (ltype === 'marine') purpose = LoanPurpose.boat;
    else if (ltype === 'rv') purpose = LoanPurpose.rv;
    else if (ltype === 'aircraft') purpose = LoanPurpose.aircraft;

    const unfinishedLoan = fetchUnfinishedLoan();
    const loanId = unfinishedLoan?.loanId;
    const currentStep = unfinishedLoan?.currentStep;

    if (loanId && currentStep) {
      return goToUnfinishedApp(loanId, currentStep);
    }

    // create a new app
    const now = Date.now();
    const loan = {
      currentStep: 'type',
      userId: user.uid,
      createdAt: now,
      updatedAt: now,
      source: 0,
      workflow: 'draft',
    } as unknown as Loan;

    if (params.repid) {
      const rep = salesReps[params.repid];
      if (rep) {
        loan.repCode = rep.code;
        if (!purpose) {
          purpose = rep.purpose;
        }
      }
    }

    // http://localhost:3000/Loan/Index#!?type=RV&repid=JSRV
    // http://localhost:3000/Loan/Index#!?type=MARINE&repid=NW3

    loanStore.createLoan(loan).then(() => {
      if (purpose) {
        loanStore.setValue('purpose', purpose);
        loanStore.syncDoc('details').then(() => {
          gotoNextStep(history, 'details');
        });
      } else {
        loanStore.syncDoc('purpose').then(() => {
          gotoNextStep(history, 'purpose');
        });
      }
    });
  }, [history, loanStore, salesReps, user]);

  return <Loading />;
});

export default OldLinkRedirectStep;
