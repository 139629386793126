import React from 'react';
import { Button, Col, Dropdown, Layout, Row, Spin } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { DownOutlined, UserOutlined } from '@ant-design/icons';

import styles from './prequal.module.scss';
import { AppFooter } from './components/AppFooter/AppFooter';
import { useLoan } from '../stores/store';
import useConfig from '../stores/config';
import { useParams } from 'react-router-dom';
import { useTheme } from '../hooks/useTheme';
import { generateUrlParams } from '../utils/generateUrlParams';
import Logo from '../loan/components/Logo';

type Props = {
  children: React.ReactNode;
  title?: string;
  className?: string;
  showLogin?: boolean;
};

export default observer(function BlankLayout({
  children,
  className,
  title,
  showLogin = true,
}: Props) {
  const loanStore = useLoan();
  const config = useConfig();
  let urlParams: any = {};
  const urlQueryParams = generateUrlParams(null);

  try {
    // this errors if there's no match
    // eslint-disable-next-line react-hooks/rules-of-hooks
    urlParams = useParams();
  } catch (e) {
    // don't need this
  }

  const theme = useTheme(loanStore, config, true);

  if (theme == null) {
    return (
      <Layout className={styles.mainLayout}>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Layout>
    );
  }

  return (
    <Layout className={styles.mainLayout}>
      <Header className={styles.header}>
        <Row className={styles.headerRow}>
          <Col>
            <Logo
              imgSrc={theme.logo}
              linkUrl={theme.marketingWebsiteUrl}
              urlQueryParams={urlQueryParams}
            />
          </Col>
        </Row>
      </Header>
      <Content className={classNames(styles.content, className)}>
        <div className="form-container">{children}</div>
      </Content>
      <AppFooter />
    </Layout>
  );
});
