import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Form, message, Radio, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import LoanLayout from '../LoanLayout';
import { getNextStep, gotoNextStep } from '../../nav';
import useHistory from '../../hooks/useHistory';
import { useLoan } from '../../stores/store';
import { useUser } from '../../hooks/useUser';
import AppFormItem from '../components/AppFormItem';
import styles from './PurposeStep.module.scss';
import {
  FinanceDetails,
  Loan,
  LoanPurpose,
  LoanType,
  Source,
  LeadOrigin,
  Prequal,
  KameleoonExperiment as Experiment,
  ValidUTMUrlParams,
  VALID_MARKETING_PARAMS,
  VALID_UTM_PARAMS,
  ValidLoanMarketingUrlParams,
  getEngineCountEntry,
  FullApplicationStatus,
  SellerType,
} from '../../schema';
import {
  fetchUnfinishedLoan,
  pushToDataLayer,
  betterParseInt,
  getCookie,
  loadKameleoonFlagsAndTests,
  formatEpochDate,
} from '../../utils';
import useConfig from '../../stores/config';
import Loading from '../Loading';
import { isProduction, BOAT_TRADER_SOURCE_ID } from '../../constants';
import { camelCaseMarketingParam } from '../../utils/prequalHelper';
import { getNumericQueryParam } from '../../utils/queryParamHelper';
import { isValidLoanPurpose } from '../../utils/urls';
import { sendDataToSFMC } from '../../utils/sendDataToSFMC';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

const parseBoatInfo = (info: string | null) => {
  try {
    if (info) {
      return JSON.parse(decodeURIComponent(info));
    }
  } catch (e) {
    console.log(e);
  }
  return null;
};

type Props = {};

const PurposeStep: React.FC<Props> = observer(() => {
  const history = useHistory();
  const user = useUser();
  const loanStore = useLoan();
  const params = new URLSearchParams(window.location.search);
  const purposeParam = params.get('purpose')?.toLowerCase();
  const repidParam = params.get('repid');
  const canceledLogin = params.get('canceledLogin');
  const isFromPrequal = params.get('prequal') === 'true';
  const prequalImtId = params.get('prequalImtId');
  const leadOrigin = params.get('leadOrigin');
  const isValidPurpose = purposeParam ? isValidLoanPurpose(purposeParam) : false;
  const waitUntilLoanLoaded = params.get('loanId') && loanStore.status === 'init';
  const [isLoanLoading, setLoanLoading] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const config = useConfig();

  const setPrefills = (loan: Loan, boatInfo: any): void => {
    if (boatInfo) {
      loan.collateral = {
        specificCollateralKnown: true,
        ...(boatInfo?.length ? { loa: boatInfo.length } : {}),
        ...(boatInfo?.year ? { year: boatInfo.year } : {}),
        ...(boatInfo?.type ? { type: boatInfo.type } : {}),
        ...(boatInfo?.make ? { manufacturer: boatInfo.make } : {}),
        ...(boatInfo?.model ? { modelNumber: boatInfo.model } : {}),
        ...(getEngineCountEntry(boatInfo?.engineCount)
          ? { engineCount: getEngineCountEntry(boatInfo.engineCount) }
          : {}),
        ...(boatInfo?.fuelType && boatInfo?.fuelType !== 'other'
          ? { fuel: boatInfo.fuelType.replace('gasoline', 'gas') }
          : {}),
        ...(boatInfo?.totalPower ? { horsepower: boatInfo.totalPower } : {}),
        ...(boatInfo?.engineMake ? { engineManufacturer: boatInfo.engineMake } : {}),
        ...(boatInfo?.imtId ? { imtId: boatInfo.imtId } : {}),
      };

      if (boatInfo?.partyId) {
        loan.sellerPartyId = boatInfo.partyId;
      }

      if ((boatInfo?.sellerFinanceAdvantage ?? null) !== null) {
        loan.sellerFinanceAdvantage = boatInfo.sellerFinanceAdvantage;
      }

      if (boatInfo?.sellerName) {
        loan.sellerName = boatInfo.sellerName;
      }

      if (boatInfo?.sellerType) {
        loan.seller =
          boatInfo.sellerType === 'Broker/Dealer' ? SellerType.dealer : SellerType.private;
      }

      if (boatInfo.sellerType === 'Broker/Dealer' && boatInfo?.sellerEmail) {
        loan.sellerEmail = boatInfo.sellerEmail;
      }
    }

    if (isFromPrequal) {
      let loadedPrequal: Partial<Prequal> = {};

      const imtId = boatInfo?.imtId ?? 'NONBDP';
      const storageKey = `prequal-${imtId}`;
      const storedPrequal = localStorage.getItem(storageKey);

      if (storedPrequal) {
        loadedPrequal = JSON.parse(storedPrequal);
      }

      loan.finance = {
        ...(loadedPrequal.purchasePrice
          ? { purchasePrice: loadedPrequal.purchasePrice }
          : boatInfo?.price
          ? { purchasePrice: boatInfo.price }
          : {}),
        ...(loadedPrequal.downPayment ? { downPayment: loadedPrequal.downPayment } : {}),
      } as FinanceDetails;

      loan.shoppingSegment = loadedPrequal.shoppingSegment;

      loan.borrower = {
        ...(loadedPrequal.firstName ? { firstName: loadedPrequal.firstName } : {}),
        ...(loadedPrequal.lastName ? { lastName: loadedPrequal.lastName } : {}),
        ...(loadedPrequal.phone ? { cellPhone: loadedPrequal.phone.replace(/[^0-9]/g, '') } : {}),
        ...(loadedPrequal.email ? { email: loadedPrequal.email } : {}),
      } as any;
    }
  };

  const getDataFromConfig = ({
    sourceId,
    subSourceId,
  }: {
    sourceId: number | undefined;
    subSourceId?: number | undefined;
    rep?: any | undefined;
  }) => {
    if (!config) {
      return {};
    }
    let source;
    let subSource;
    let rep;
    try {
      if (repidParam) {
        rep = config.salesReps[repidParam];
      }
      if (sourceId !== undefined) {
        source = config.getSource(sourceId);
        if (subSourceId !== undefined) {
          subSource = config.getSubSource(source, subSourceId);
        }
      } else {
        source = config.getSourceFromLocation(window.location.href);
      }
    } catch (e) {
      console.error(e);
    }

    return {
      source,
      subSource,
      rep,
    };
  };

  let sourceId = betterParseInt(params.get('source'));
  const boatInfo = parseBoatInfo(params.get('boatInfo'));
  const leadUUID = params.get('leadUUID');

  if (loanStore && loanStore.loan) {
    sourceId = loanStore.loan.source;
    if (loanStore.loan.purpose === LoanPurpose.boat) {
      setPrefills(loanStore.loan, boatInfo);
    }
    if (leadUUID) {
      loanStore.loan.prequalLeadUUID = leadUUID;
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onFinish = async (purpose: LoanPurpose, thirdPartyDisclaimerSeen = false) => {
    try {
      if (!user) {
        // noinspection ExceptionCaughtLocallyJS
        throw new Error('missing user');
      }

      if (!loanStore.loan) {
        // create a new app
        const now = Date.now();

        const loan = {
          currentStep: 'type',
          userId: user.uid,
          createdAt: now,
          updatedAt: now,
          source: 0,
          keycode: (params.get('keycode') || '').substring(0, 100),
          promocode: (params.get('promocode') || '').substring(0, 100),
          promocodeEditable: !(params.get('promocode') || '').substring(0, 100).length,
          member: (params.get('member') || '').substring(0, 100),
          rateQuoteId: (params.get('rqid') || '').substring(0, 100),
          salesForceId: (params.get('sfid') || '').substring(0, 100),
          googleClickId: (params.get('gclid') || '').substring(0, 100),
          workflow: 'draft',
          showMemberField: (loanStore.theme?.showMemberField && !params.get('member')) ?? false,
          advertisedRate: getCookie('advertisedRate'),
          thirdPartyDisclaimerSeen,
        } as unknown as Loan;

        if (purpose === LoanPurpose.boat) {
          setPrefills(loan, boatInfo);
        }
        if (leadUUID) {
          loan.prequalLeadUUID = leadUUID;
        }

        const subSourceId = betterParseInt(params.get('subsource'));
        const { source, subSource, rep } = getDataFromConfig({
          sourceId,
          subSourceId,
        });

        if (subSource) {
          loan.subSource = subSource.id;
        }

        if (source) {
          loan.source = source.id;
        }

        if (rep) {
          loan.repCode = rep.code;
        }

        if (leadOrigin) {
          loan.leadOrigin =
            LeadOrigin[leadOrigin?.toLowerCase() as keyof typeof LeadOrigin] || LeadOrigin.none;
        } else {
          loan.leadOrigin = LeadOrigin.none;
        }

        // save google utm parameters from the url
        try {
          const utm: { [k: string]: string } = {};
          Array.from(params.entries()).forEach(([name, value]) => {
            if (VALID_UTM_PARAMS.has(name as ValidUTMUrlParams)) {
              utm[name.substring(0, 15)] = value.substring(0, 50);
            }
          });
          loan.utm = utm;
        } catch (e) {
          console.error(e);
        }

        try {
          const marketing: { [k: string]: string | number | null } = {};
          Array.from(params.entries()).forEach(([name, value]) => {
            if (VALID_MARKETING_PARAMS.has(name.toLowerCase() as ValidLoanMarketingUrlParams)) {
              const isParamWithNumericValue = name === 'adgroupid' || name === 'campaignid';
              const key = camelCaseMarketingParam(name);

              if (isParamWithNumericValue) {
                marketing[key] = getNumericQueryParam({ value, param: name });
              } else {
                marketing[key] = value;
              }
            }
          });
          loan.marketing = marketing;
        } catch (e) {
          console.error(e);
        }

        let loanType = params.get('type');
        if (loanType) {
          loanType = loanType.toLocaleLowerCase();
          if (Object.keys(LoanType).includes(loanType)) {
            loan.loanType = loanType as LoanType;
          }
        }

        if (subSource && (subSource.isReferral || subSource.products.includes(purpose))) {
          loan.subSource = subSource.id;
          loan.subSourceLabel = subSource.name;
        }

        const newLoanId = await loanStore.createLoan(loan);

        if (loan?.source === BOAT_TRADER_SOURCE_ID) {
          localStorage.setItem('loanId', newLoanId);
        }

        if (isFromPrequal && prequalImtId) {
          let loadedPrequal: Partial<Prequal> = {};
          const storageKey = `prequal-${prequalImtId}`;
          const storedPrequal = localStorage.getItem(storageKey);

          if (storedPrequal) {
            loadedPrequal = JSON.parse(storedPrequal);
            loanStore.setValue('prequalLoanId', loadedPrequal.prequalApplicationId);

            sendDataToSFMC({
              externalKey: isProduction() ? 'TRIDENT-Prequal-PRODUCTION' : 'TRIDENT-Prequal-QA',
              primaryKey: `prequal-id:${loadedPrequal.prequalApplicationId}`,
              payload: {
                'application-id': loan?.id,
                'application-status': loadedPrequal.fullApplicationStatus,
                'full-application-startedAt': loadedPrequal.fullApplicationStartedAt
                  ? formatEpochDate(loadedPrequal.fullApplicationStartedAt)
                  : undefined,
              },
            });
          }
        } else {
          sendDataToSFMC({
            externalKey: isProduction()
              ? 'TRIDENT-FullApplication-PROD'
              : 'TRIDENT-FullApplication-QA',
            primaryKey: `application-id:${loan?.id}`,
            payload: {
              'application-status': FullApplicationStatus.started,
              'started-at': formatEpochDate(Date.now()),
            },
          });
        }
      }

      // in case we went back to type
      loanStore.navDirection = 1;
      const nextStep = getNextStep();
      loanStore.setValue('purpose', purpose);
      await loanStore.syncDoc(nextStep);

      const vehicleType = purpose.toLowerCase();

      pushToDataLayer('e_applicationAction', {
        application_category: 'financing application',
        application_action: 'choose vehicle type',
        application_label: vehicleType,
        loan_purpose: vehicleType,
      });
      gotoNextStep(history, nextStep);
    } catch (e: any) {
      message.error(`Save failed: ${e.message || 'unknown error'}`);
      console.error('failed', e);
    }
  };

  const handlePurposeClick = (purpose: LoanPurpose) => {
    onFinish(purpose, true);
  };

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'choose loan purpose',
      environment: isProduction() ? 'production' : 'qa',
    });
    if (!localStorage.getItem('fullUrl')) {
      localStorage.setItem('fullUrl', window.location.href);
    }
  }, []);

  useEffect(() => {
    const goToUnfinishedApp = (loanId: string, step: string) => {
      const refinedParams = new URLSearchParams(params.toString());
      refinedParams.delete('loanId');
      refinedParams.delete('currentStep');

      history.push(`/${loanId}/${step}?${refinedParams}`);
      setRedirected(true);
    };

    const unfinishedLoan = fetchUnfinishedLoan();
    const loanId = canceledLogin ? null : unfinishedLoan?.loanId;

    // Get the step from the query param or from the loan
    const currentStep = unfinishedLoan?.currentStep || loanStore?.loan?.currentStep;
    const validUnfinishedLoanValues = loanId && currentStep && currentStep !== 'type';

    // Set the loanStore loanId to load up the loan
    if (loanId && loanId !== loanStore.id) {
      loanStore.setLoanId(loanId);
      setLoanLoading(true);
    }

    // Check if the loan values are valid and the loanStore has finished loading
    // the loan
    if (validUnfinishedLoanValues && loanStore.status === 'done' && !redirected && !canceledLogin) {
      return goToUnfinishedApp(loanId, currentStep);
    }

    if (!purposeParam) return;
    if (!user) return;

    if (isValidPurpose && !validUnfinishedLoanValues && !loanStore?.loan && !isLoanLoading) {
      const purpose = purposeParam as 'rv' | 'boat' | 'aircraft';
      onFinish(LoanPurpose[purpose] as LoanPurpose);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onFinish, purposeParam, user, loanStore.id]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const data = useMemo(() => getDataFromConfig({ sourceId }), [sourceId, config]);

  if (
    !config ||
    !loanStore ||
    loanStore.status === 'pending' ||
    loanStore.status === 'not_found' ||
    isValidPurpose ||
    waitUntilLoanLoaded
  ) {
    return <Loading />;
  }

  // Had to do this so that TS doesn't complain about Source being possibly undefined
  let source: Source = null as unknown as Source;

  if (data.source) {
    source = data.source;
  } else {
    source = config.getSource(0);
  }

  return (
    <LoanLayout>
      <h1 style={{ textAlign: 'center' }}>What are you financing?</h1>
      <Form {...formItemLayout} style={{ paddingBottom: '4rem' }}>
        <AppFormItem
          name="loanPurpose"
          className="button-form"
          hasFeedback={false}
          rules={[{ required: true, message: 'Please choose a button' }]}
          label=""
          wrapperCol={{ span: 24, offset: 0 }}
        >
          <Radio.Group className={styles.wide}>
            <Space size="large" direction="vertical" className={styles.wide}>
              {source.products.map((purpose: LoanPurpose) => (
                <Radio.Button
                  onClick={() => handlePurposeClick(purpose)}
                  value={purpose}
                  className={styles.purposeButton}
                  key={purpose}
                >
                  {purpose}
                </Radio.Button>
              ))}
            </Space>
          </Radio.Group>
        </AppFormItem>
      </Form>
    </LoanLayout>
  );
});

export default PurposeStep;
