import React from 'react';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../stores/store';
import { getNextStep, gotoNextStep } from '../../nav';
import AppFormItem from '../../loan/components/AppFormItem';
import useHistory from '../../hooks/useHistory';
import { pushToDataLayer, getStepLabel } from '../../utils/gtm';

const NavPreviousButton: React.FC = observer(() => {
  const loanStore = useLoan();
  const history = useHistory();

  return (
    <AppFormItem>
      <Button
        name="back"
        type="default"
        htmlType="button"
        onClick={() => {
          loanStore.navDirection = -1;
          const nextStep = getNextStep();
          const { loan } = loanStore;
          if (loan) {
            pushToDataLayer('e_applicationAction', {
              application_category: 'financing application',
              application_action: 'back',
              application_label: getStepLabel(loan.currentStep),
              loan_purpose: loan.purpose.toLowerCase(),
            });
          }
          gotoNextStep(history, nextStep);
          return false;
        }}
      >
        Back
      </Button>
    </AppFormItem>
  );
});

export default NavPreviousButton;
