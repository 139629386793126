import React, { ReactElement } from 'react';
import { Col, Radio, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import Cleave from 'cleave.js/react';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { rowLayout } from '../layout';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { pickFields } from '../steps/meta';
import AppFormItem from './AppFormItem';
import {
  ApplicantType,
  Borrower,
  LoanPurpose,
  OwnershipType,
  RelationshipType,
} from '../../schema';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import { useLoan } from '../../stores/store';
import { disableAutocomplete, Country, countries } from '../../utils';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink/PrivacyPolicyLink';
import styles from './BorrowerForm.module.scss';
import { BorrowerNameForm } from './BorrowerNameForm';
import { UNITED_STATES } from '../../utils/countries';
import { FullAppOptimizeV1Flag } from '../../featureFlags';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';

type Props = {
  formItemPrefix?: string;
  borrower: Borrower;
  showApplicationType?: boolean;
  showRelationshipField?: boolean;
  showPrivacyPolicy?: boolean;
  isCoBorrower?: boolean;
};

const column12 = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };
const schemaValidator = createSchemaValidator(pickFields('borrower'));

const createCountryOptions = (countriesArray: Country[]): string[] => {
  const countryOptions = countriesArray
    .map((c: Country) => c.name)
    .filter((name: string) => name !== UNITED_STATES && name !== 'Puerto Rico')
    .sort();

  return [UNITED_STATES, ...countryOptions];
};

const BorrowerForm: React.FC<Props> = observer(
  ({
    formItemPrefix = '',
    borrower,
    showApplicationType,
    showRelationshipField,
    showPrivacyPolicy,
    isCoBorrower = false,
  }) => {
    const activeFeatureFlags = useFeatureFlags();
    const isNewFunnelExperience = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];
    const shouldShowNameForm = !isNewFunnelExperience || isCoBorrower;
    const loanStore = useLoan();
    const { loan } = loanStore;

    const countryOptions = createCountryOptions(countries);

    const applicantTypeOptions = [
      { label: 'Individual', value: ApplicantType.individual },
      { label: 'Joint', value: ApplicantType.joint },
    ];

    const ownershipTypeOptions: ReactElement[] = [];
    Object.values(OwnershipType).forEach((label) => {
      if (label === OwnershipType.montanaLlc && loan?.purpose !== LoanPurpose.rv) return;
      ownershipTypeOptions.push(
        <Select.Option key={label} value={label}>
          {label}
        </Select.Option>
      );
    });

    const composeName = (name: string) => `${formItemPrefix}${name}`;

    return (
      <>
        {shouldShowNameForm && (
          <BorrowerNameForm borrower={borrower} formItemPrefix={formItemPrefix} />
        )}

        <Row {...rowLayout} className={styles.mobileSpacing}>
          <div className="mobileSpacing" />

          <Col {...column12}>
            <AppFormItem
              name={composeName('dateOfBirth')}
              required
              initialValue={borrower.dateOfBirth}
              rules={[
                schemaValidator('dateOfBirth', borrower, {
                  message: 'Please enter a birth day',
                }),
              ]}
            >
              <PlaceholderLabel label="Date of Birth" htmlFor="dateOfBirth">
                <Cleave
                  options={{
                    date: true,
                    delimiter: '/',
                    datePattern: ['m', 'd', 'Y'],
                  }}
                  className="ant-input"
                  autoComplete="bday"
                />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        </Row>

        <Row {...rowLayout} className={styles.mobileSpacing}>
          <Col {...column12}>
            <AppFormItem
              name={composeName('ssn')}
              required
              initialValue={borrower.ssn}
              rules={[schemaValidator('ssn', borrower)]}
            >
              <PlaceholderLabel label="SSN" htmlFor="ssn">
                <Cleave
                  options={{
                    blocks: [3, 2, 4],
                    delimiter: '-',
                    numericOnly: true,
                  }}
                  className="ant-input"
                />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>

          <div className="mobileSpacing" />

          <Col {...column12}>
            <AppFormItem
              hasFeedback
              initialValue={borrower.citizen || UNITED_STATES}
              name={composeName('citizen')}
              required
              rules={[
                { required: true, message: 'Please select one' },
                schemaValidator('citizen', borrower),
              ]}
            >
              <PlaceholderLabel label="Country of Citizenship">
                <Select
                  allowClear
                  defaultValue={UNITED_STATES}
                  onFocus={disableAutocomplete}
                  optionFilterProp="children"
                  showSearch
                  suffixIcon={<SelectArrowIcon />}
                >
                  {countryOptions.map((s) => (
                    <Select.Option key={s} value={s}>
                      {s}
                    </Select.Option>
                  ))}
                </Select>
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        </Row>

        {showRelationshipField && (
          <Row {...rowLayout} className={styles.mobileSpacing}>
            <Col {...column12}>
              <AppFormItem
                name={composeName('relationship')}
                required
                initialValue={borrower.relationship}
                rules={[
                  schemaValidator('relationship', borrower, {
                    message: 'Please select one',
                  }),
                ]}
              >
                <PlaceholderLabel label="Relationship to Borrower" htmlFor="relationship">
                  <Select
                    suffixIcon={<SelectArrowIcon />}
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    onFocus={disableAutocomplete}
                  >
                    {Object.values(RelationshipType).map((s) => (
                      <Select.Option key={s} value={s}>
                        {s}
                      </Select.Option>
                    ))}
                  </Select>
                </PlaceholderLabel>
              </AppFormItem>
            </Col>
            {showPrivacyPolicy && <PrivacyPolicyLink isCoBorrower />}
          </Row>
        )}

        {!showRelationshipField && showPrivacyPolicy && (
          <Row>
            <PrivacyPolicyLink wideColumn />
          </Row>
        )}

        {showApplicationType && !isNewFunnelExperience && (
          <Row {...rowLayout} className={styles.mobileSpacing}>
            <Col {...column12}>
              <AppFormItem
                className="manual-label"
                hasFeedback={false}
                initialValue={loan?.applicantType || ApplicantType.individual}
                name={composeName('applicantType')}
                required
                rules={[
                  schemaValidator('applicantType', loan, {
                    message: 'Please select one',
                  }),
                ]}
              >
                <ManualLabel twoLine label="Application Type:">
                  <Radio.Group
                    className={styles.applicationTypeRadioGroup}
                    defaultValue={ApplicantType.individual}
                    options={applicantTypeOptions}
                    optionType="button"
                  />
                </ManualLabel>
              </AppFormItem>
            </Col>
          </Row>
        )}
      </>
    );
  }
);

export default BorrowerForm;
