import React, { Key } from 'react';
import { Form, Radio, Space } from 'antd';
import { observer } from 'mobx-react-lite';
import styles from './RadioButton.module.scss';
import { NamePath } from 'antd/lib/form/interface';
import { CreditScoreRangeType, ShoppingSegmentType } from '../../../schema';

type Props = {
  name: NamePath;
  type: typeof ShoppingSegmentType | typeof CreditScoreRangeType;
  defaultValue?: String;
  useKeyAsValue?: boolean;
};

const RadioButton: React.FC<Props> = observer((props) => {
  const { name, type, defaultValue, useKeyAsValue = false } = props;

  return (
    <Form.Item
      label={name}
      name={name}
      rules={[{ required: true, message: 'Please make a selection to continue' }]}
    >
      <Radio.Group defaultValue={defaultValue}>
        <Space direction="vertical" className={styles.radioButtonsGroup}>
          {Object.entries(type).map(([key, value]) => (
            <Radio.Button key={key} value={useKeyAsValue ? key : value}>
              {value}
            </Radio.Button>
          ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  );
});

export default RadioButton;
