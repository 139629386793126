import React, { useContext, useEffect, useState } from 'react';
import { Col, FormInstance, Input, Radio, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { PhoneInput } from './PhoneInput';
import { pickFields } from '../steps/meta';
import AppFormItem from './AppFormItem';
import { ApplicantType, Borrower, Loan } from '../../schema';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink/PrivacyPolicyLink';
import { BorrowerNameForm } from './BorrowerNameForm';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import { rowLayout } from '../layout';
import styles from './BorrowerForm.module.scss';
import { useLoan } from '../../stores/store';
import { useFeatureFlags } from '../../hooks/useFeatureFlags';
import { FullAppOptimizeV1Flag, FullAppStylingFlag } from '../../featureFlags';

type Props = {
  borrower: Borrower;
  showPrivacyPolicy?: boolean;
  form: FormInstance;
  formItemPrefix?: string;
  isCoBorrower?: boolean;
};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };
const column12 = { xs: 24, sm: 24, md: 12, lg: 12, xl: 12 };

const schemaValidator = createSchemaValidator(pickFields('contact'));

const BorrowerContactForm: React.FC<Props> = observer(
  ({ borrower, showPrivacyPolicy, form, formItemPrefix, isCoBorrower = false }) => {
    const activeFeatureFlags = useFeatureFlags();
    const isNewFunnelExperience = !!activeFeatureFlags[FullAppOptimizeV1Flag.name];
    const isNewFunnelStyling = !!activeFeatureFlags[FullAppStylingFlag.name];
    const loanStore = useLoan();
    const { loan } = loanStore;

    const [homePhoneFeedback, setHomePhoneFeedback] = useState(false);
    const [cellPhoneFeedback, setCellPhoneFeedback] = useState(false);
    const shouldShowNameForm = isNewFunnelExperience && !isCoBorrower;
    const showApplicationType = isNewFunnelExperience && !isCoBorrower;

    function phoneStatusChecker(phoneName: string, phoneStateSetter: any) {
      const showFeedback = !!(borrower[phoneName] && form.getFieldValue(phoneName)?.length);
      phoneStateSetter(showFeedback);
    }

    useEffect(() => {
      phoneStatusChecker('homePhone', setHomePhoneFeedback);
      phoneStatusChecker('cellPhone', setCellPhoneFeedback);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [borrower?.homePhone, borrower?.cellPhone]);

    // For coborrower
    const composeName = (name: string) => `${formItemPrefix}${name}`;
    const applicantTypeOptions = [
      { label: 'Individual', value: ApplicantType.individual },
      { label: 'Joint', value: ApplicantType.joint },
    ];

    return (
      <>
        {shouldShowNameForm && (
          <BorrowerNameForm borrower={borrower} formItemPrefix={formItemPrefix} />
        )}
        <Row>
          <Col {...centerSmallOffset} />
          <Col {...centerSmall}>
            <AppFormItem
              name="email"
              initialValue={borrower.email}
              required
              hasFeedback
              rules={[schemaValidator('email', borrower)]}
            >
              <PlaceholderLabel label="Email" htmlFor="email">
                <Input inputMode="email" />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        </Row>
        <Row>
          <Col {...centerSmallOffset} />
          {!isNewFunnelExperience && (
            <Col {...centerSmall}>
              <AppFormItem
                name="homePhone"
                initialValue={borrower.homePhone}
                validateTrigger="onBlur"
                hasFeedback={homePhoneFeedback}
                rules={[schemaValidator('homePhone', borrower)]}
              >
                <PlaceholderLabel label="Home Phone Number" htmlFor="homePhone">
                  <PhoneInput placeholder={isNewFunnelStyling ? 'Enter Phone' : undefined} />
                </PlaceholderLabel>
              </AppFormItem>
            </Col>
          )}
        </Row>
        <Row>
          <Col {...centerSmallOffset} />
          <Col {...centerSmall}>
            <AppFormItem
              name="cellPhone"
              required
              initialValue={borrower.cellPhone}
              validateTrigger="onBlur"
              hasFeedback={cellPhoneFeedback}
              rules={[schemaValidator('cellPhone', borrower)]}
              wrapperCol={{ span: 24, offset: 0 }}
            >
              <PlaceholderLabel label="Cell Phone Number" htmlFor="cellPhone">
                <PhoneInput placeholder={isNewFunnelStyling ? 'Enter Phone' : undefined} />
              </PlaceholderLabel>
            </AppFormItem>
          </Col>
        </Row>
        {showPrivacyPolicy && (
          <Row>
            <PrivacyPolicyLink />
          </Row>
        )}

        {showApplicationType && (
          <Row {...rowLayout} className={styles.mobileSpacing}>
            <Col {...column12}>
              <AppFormItem
                className="manual-label"
                hasFeedback={false}
                initialValue={loan?.applicantType || ApplicantType.individual}
                name={composeName('applicantType')}
                required
                rules={[
                  schemaValidator('applicantType', loan, {
                    message: 'Please select one',
                  }),
                ]}
              >
                <ManualLabel twoLine label="Application Type:">
                  <Radio.Group
                    className={styles.applicationTypeRadioGroup}
                    defaultValue={ApplicantType.individual}
                    options={applicantTypeOptions}
                    optionType="button"
                  />
                </ManualLabel>
              </AppFormItem>
            </Col>
          </Row>
        )}
      </>
    );
  }
);

export default BorrowerContactForm;
