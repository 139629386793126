import { z } from 'zod';
import { STATE_AND_TERRITORY_CODES } from '../constants';
import { PREVIOUS_ADDRESS_REQUIRED_YEARS, ApplicantType, addressSchema } from '../schema';

export const isUSState = (state: string) => STATE_AND_TERRITORY_CODES.includes(state);

type AddressType = z.infer<typeof addressSchema>;

export const areAddressesEqual = (
  currentAddress: AddressType,
  previousAddress: AddressType
): boolean => {
  const { address1, city, state } = currentAddress;
  const { address1: previousAddress1, city: previousCity, state: previousState } = previousAddress;

  const currentAddressData = address1 + city + state;
  const previousAddressData = previousAddress1 + previousCity + previousState;

  return currentAddressData === previousAddressData;
};

export const showPreviousAddressBorrower = (
  values: any,
  loan: any,
  loanStore: any,
  yearsAtCurrent: number
): boolean => {
  if (
    values?.borrowerCurrentAddressYearsAtResidence !== null &&
    values?.borrowerCurrentAddressYearsAtResidence !== undefined
  ) {
    loanStore.setNestedValue('borrower', 'currentAddress', {
      address1: values.borrowerCurrentAddressAddress1,
      city: values.borrowerCurrentAddressCity,
      county: values.borrowerCurrentAddressCounty,
      country: values.borrowerCurrentAddressCountry,
      state: values.borrowerCurrentAddressState,
      zip: values.borrowerCurrentAddressZip,
      yearsAtResidence: values.borrowerCurrentAddressYearsAtResidence,
      monthsAtResidence: values.borrowerCurrentAddressMonthsAtResidence,
      mortgageRentPayment: values.borrowerCurrentAddressMortgageRentPayment,
      rentOrOwn: values.borrowerCurrentAddressRentOrOwn,
      unitNumber: values.borrowerCurrentAddressUnitNumber,
    });
  }

  if (
    values?.coborrowerCurrentAddressYearsAtResidence !== null &&
    values?.coborrowerCurrentAddressYearsAtResidence !== undefined
  ) {
    loanStore.setNestedValue('coborrower', 'currentAddress', {
      address1: values.coborrowerCurrentAddressAddress1,
      city: values.coborrowerCurrentAddressCity,
      county: values.coborrowerCurrentAddressCounty,
      country: values.coborrowerCurrentAddressCountry,
      state: values.coborrowerCurrentAddressState,
      zip: values.coborrowerCurrentAddressZip,
      yearsAtResidence: values.coborrowerCurrentAddressYearsAtResidence,
      monthsAtResidence: values.coborrowerCurrentAddressMonthsAtResidence,
      mortgageRentPayment: values.coborrowerCurrentAddressMortgageRentPayment,
      rentOrOwn: values.coborrowerCurrentAddressRentOrOwn,
      unitNumber: values.coborrowerCurrentAddressUnitNumber,
    });
  }

  const yearsAtAddress = yearsAtCurrent ?? PREVIOUS_ADDRESS_REQUIRED_YEARS;
  if (yearsAtAddress === undefined || yearsAtAddress === null) return false;
  return yearsAtAddress < PREVIOUS_ADDRESS_REQUIRED_YEARS;
};

export const showPreviousAddressCoborrower = (
  values: any,
  loan: any,
  loanStore: any,
  yearsAtCurrent: number
): boolean => {
  if (values?.sharesAddress !== null && values?.sharesAddress !== undefined) {
    loanStore.setNestedValue('coborrower', 'sharesAddress', values.sharesAddress);
  }

  const yearsAtAddress = yearsAtCurrent ?? PREVIOUS_ADDRESS_REQUIRED_YEARS;
  const sharesAddress = values?.sharesAddress ?? loan?.coborrower?.sharesAddress ?? false;
  if (yearsAtAddress === undefined || yearsAtAddress === null) return false;
  return (
    loan?.applicantType === ApplicantType.joint &&
    !sharesAddress &&
    yearsAtAddress < PREVIOUS_ADDRESS_REQUIRED_YEARS
  );
};

export const shouldHideMonthsAtResidenceBorrower = (values: any, loan: any): boolean => {
  const yearsAtAddress =
    values?.borrowerCurrentAddressYearsAtResidence ??
    loan?.borrower?.currentAddress?.yearsAtResidence;
  if (yearsAtAddress === undefined || yearsAtAddress === null) return false;
  return yearsAtAddress >= PREVIOUS_ADDRESS_REQUIRED_YEARS;
};

export const shouldHideMonthsAtResidenceCoborrower = (values: any, loan: any): boolean => {
  const yearsAtAddress =
    values?.coborrowerCurrentAddressYearsAtResidence ??
    loan?.coborrower?.currentAddress?.yearsAtResidence;
  if (yearsAtAddress === undefined || yearsAtAddress === null) return false;
  return yearsAtAddress >= PREVIOUS_ADDRESS_REQUIRED_YEARS;
};

export const processAddressUpdates = (
  values: any,
  loanStore: any,
  showBorrowerPreviousAddress: boolean,
  showMailingAddress: boolean,
  showCoborrowerAddress: boolean,
  showCoborrowerPreviousAddress: boolean
) => {
  loanStore.setNestedValue('borrower', 'currentAddress', {
    address1: values.borrowerCurrentAddressAddress1,
    city: values.borrowerCurrentAddressCity,
    county: values.borrowerCurrentAddressCounty,
    country: values.borrowerCurrentAddressCountry,
    state: values.borrowerCurrentAddressState,
    zip: values.borrowerCurrentAddressZip,
    yearsAtResidence: values.borrowerCurrentAddressYearsAtResidence,
    monthsAtResidence: values.borrowerCurrentAddressMonthsAtResidence,
    mortgageRentPayment: values.borrowerCurrentAddressMortgageRentPayment,
    rentOrOwn: values.borrowerCurrentAddressRentOrOwn,
    unitNumber: values.borrowerCurrentAddressUnitNumber,
  });

  loanStore.setNestedValue(
    'borrower',
    'previousAddress',
    showBorrowerPreviousAddress
      ? {
          address1: values.borrowerPreviousAddressAddress1,
          city: values.borrowerPreviousAddressCity,
          county: values.borrowerPreviousAddressCounty,
          country: values.borrowerPreviousAddressCountry,
          state: values.borrowerPreviousAddressState,
          zip: values.borrowerPreviousAddressZip,
          yearsAtResidence: values.borrowerPreviousAddressYearsAtResidence,
          monthsAtResidence: values.borrowerPreviousAddressMonthsAtResidence,
          mortgageRentPayment: values.borrowerPreviousAddressMortgageRentPayment,
          rentOrOwn: values.borrowerPreviousAddressRentOrOwn,
          unitNumber: values.borrowerPreviousAddressUnitNumber,
        }
      : null
  );

  loanStore.setNestedValue(
    'borrower',
    'mailingAddress',
    showMailingAddress
      ? {
          address1: values.borrowerMailingAddressAddress1,
          city: values.borrowerMailingAddressCity,
          country: values.borrowerMailingAddressCountry,
          state: values.borrowerMailingAddressState,
          zip: values.borrowerMailingAddressZip,
          unitNumber: values.borrowerMailingAddressUnitNumber,
        }
      : null
  );

  loanStore.setNestedValue(
    'coborrower',
    'currentAddress',
    showCoborrowerAddress
      ? {
          address1: values.coborrowerCurrentAddressAddress1,
          city: values.coborrowerCurrentAddressCity,
          county: values.coborrowerCurrentAddressCounty,
          country: values.coborrowerCurrentAddressCountry,
          state: values.coborrowerCurrentAddressState,
          zip: values.coborrowerCurrentAddressZip,
          yearsAtResidence: values.coborrowerCurrentAddressYearsAtResidence,
          monthsAtResidence: values.coborrowerCurrentAddressMonthsAtResidence,
          mortgageRentPayment: values.coborrowerCurrentAddressMortgageRentPayment,
          rentOrOwn: values.coborrowerCurrentAddressRentOrOwn,
          unitNumber: values.coborrowerCurrentAddressUnitNumber,
        }
      : null
  );

  loanStore.setNestedValue(
    'coborrower',
    'previousAddress',
    showCoborrowerPreviousAddress
      ? {
          address1: values.coborrowerPreviousAddressAddress1,
          city: values.coborrowerPreviousAddressCity,
          county: values.coborrowerPreviousAddressCounty,
          country: values.coborrowerPreviousAddressCountry,
          state: values.coborrowerPreviousAddressState,
          zip: values.coborrowerPreviousAddressZip,
          yearsAtResidence: values.coborrowerPreviousAddressYearsAtResidence,
          monthsAtResidence: values.coborrowerPreviousAddressMonthsAtResidence,
          mortgageRentPayment: values.coborrowerPreviousAddressMortgageRentPayment,
          rentOrOwn: values.coborrowerPreviousAddressRentOrOwn,
          unitNumber: values.coborrowerPreviousAddressUnitNumber,
        }
      : null
  );

  loanStore.setNestedValue('coborrower', 'sharesAddress', values?.sharesAddress);
};
