/* eslint-disable import/no-cycle */
import {
  combineSkip,
  neverSkip,
  skipBorrowerAdditionalEmployment,
  skipCoAddress,
  skipCoBorrower,
  skipCoBorrowerAdditionalEmployment,
  skipCoEmployer,
  skipCoPreviousAddress,
  skipCoPreviousEmployer,
  skipEmployer,
  skipForIndividual,
  skipIfNoFinancialStatement,
  skipIfNotCashRecapture,
  skipIfNotRefinance,
  skipIfRefinance,
  skipMailingAddress,
  skipPreviousAddress,
  skipPreviousEmployer,
  transition,
} from '../nav';

export const ALL_ROUTES_CONTROL = [
  'type',
  'details',
  'contact',
  'borrower',
  'address',
  'previous-address',
  'mailing-address',
  'seller',
  'collateral',
  'finance',
  'refinance',
  'cash-recapture',
  'experience',
  'employer',
  'employer2',
  'income',
  'assets',
  'other-assets',
  'liabilities',
  'real-estate',
  'statement-summary',
  'previous-employer',
  'coborrower',
  'coborrower-contact',
  'coborrower-address',
  'coborrower-previous-address',
  'coborrower-income',
  'coborrower-employer',
  'coborrower-employer2',
  'coborrower-previous-employer',
  'review',
  'submit',
  'TERMINAL',
  'saved',
  'success',
  'marketing',
] as const;

export const processTransitionsControl = () => {
  transition('type', { to: 'details', skip: neverSkip });
  transition('details', { to: 'contact', skip: neverSkip });
  transition('contact', { to: 'borrower', skip: neverSkip });
  transition('borrower', { to: 'address', skip: neverSkip });
  transition('address', {
    to: 'previous-address',
    skip: skipPreviousAddress,
  });
  transition('previous-address', {
    to: 'mailing-address',
    skip: skipMailingAddress,
  });
  transition('mailing-address', { to: 'collateral', skip: neverSkip });
  transition('collateral', { to: 'seller', skip: skipIfRefinance });
  transition('seller', { to: 'finance', skip: skipIfRefinance });
  transition('finance', { to: 'refinance', skip: skipIfNotRefinance });
  transition('refinance', { to: 'cash-recapture', skip: skipIfNotCashRecapture });
  transition('cash-recapture', { to: 'income', skip: neverSkip });
  transition('income', { to: 'assets', skip: skipIfNoFinancialStatement });
  transition('assets', { to: 'real-estate', skip: skipIfNoFinancialStatement });
  transition('real-estate', {
    to: 'other-assets',
    skip: skipIfNoFinancialStatement,
  });
  transition('other-assets', {
    to: 'liabilities',
    skip: skipIfNoFinancialStatement,
  });
  transition('liabilities', {
    to: 'statement-summary',
    skip: skipIfNoFinancialStatement,
  });
  transition('statement-summary', { to: 'employer', skip: skipEmployer });
  transition('employer', {
    to: 'employer2',
    skip: skipBorrowerAdditionalEmployment,
  });
  transition('employer2', { to: 'previous-employer', skip: skipPreviousEmployer });
  transition('previous-employer', { to: 'coborrower', skip: skipForIndividual });
  transition('coborrower', {
    to: 'coborrower-contact',
    skip: skipForIndividual,
  });
  transition('coborrower-contact', {
    to: 'coborrower-address',
    skip: skipCoAddress,
  });
  transition('coborrower-address', {
    to: 'coborrower-previous-address',
    skip: combineSkip(skipCoBorrower, skipCoPreviousAddress),
  });
  transition('coborrower-previous-address', {
    to: 'coborrower-income',
    skip: skipCoBorrower,
  });
  transition('coborrower-income', {
    to: 'coborrower-employer',
    skip: combineSkip(skipCoBorrower, skipCoEmployer),
  });
  transition('coborrower-employer', {
    to: 'coborrower-employer2',
    skip: skipCoBorrowerAdditionalEmployment,
  });
  transition('coborrower-employer2', {
    to: 'coborrower-previous-employer',
    skip: skipCoPreviousEmployer,
  });
  transition('coborrower-previous-employer', { to: 'review', skip: neverSkip });
  transition('review', { to: 'submit', skip: neverSkip });
  transition('submit', { to: 'TERMINAL', skip: neverSkip });
};
