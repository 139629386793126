import { Loan } from '../schema';
import { GOODSAM_SOURCE_ID } from '../constants';
import { betterParseInt } from './betterParseInt';

export function generateUrlParams(loan: Loan | null): string {
  const params = new URLSearchParams(window.location.search);
  const loanObj = loan || {
    id: params.get('loanId'),
    currentStep: params.get('currentStep'),
    source: params.get('source'),
    subSource: params.get('subsource'),
    keycode: params.get('keycode'),
    promocode: params.get('promocode'),
    member: params.get('member'),
    rateQuoteId: params.get('rqid'),
    salesForceId: params.get('sfid'),
    utm: {
      utm_source: params.get('utm_source'),
      utm_medium: params.get('utm_medium'),
      utm_term: params.get('utm_term'),
      utm_content: params.get('utm_content'),
      utm_campaign: params.get('utm_campaign'),
    },
    googleClickId: params.get('gclid'),
    purpose: params.get('purpose'),
    loanType: params.get('type'),
    repCode: params.get('repid'),
  };

  const {
    id: loanId,
    currentStep,
    source,
    subSource: subsource,
    keycode,
    promocode,
    member,
    rateQuoteId,
    salesForceId,
    utm,
    googleClickId,
    purpose,
    loanType,
    repCode: repid,
  } = loanObj;

  const queryParams = [
    [loanId, 'loanId'],
    [currentStep, 'currentStep'],
    [source, 'source'],
    [subsource, 'subsource'],
    [keycode, 'keycode'],
    [promocode, 'promocode'],
    [member, 'member'],
    [rateQuoteId, 'rqid'],
    [salesForceId, 'sfid'],
    [googleClickId, 'gclid'],
    [purpose, 'purpose'],
    [loanType, 'type'],
    [repid, 'repid'],
  ];

  const goodSamQueryParams = [
    [utm?.utm_source, 'utm_source'],
    [utm?.utm_medium, 'utm_medium'],
    [utm?.utm_term, 'utm_term'],
    [utm?.utm_content, 'utm_content'],
    [utm?.utm_campaign, 'utm_campaign'],
  ];

  let urlParams: string = '';

  queryParams.forEach((item) => {
    if (item[0] !== null && item[0] !== undefined && item[0] !== '') {
      urlParams += `${item[1]}=${item[0]}&`;
    }
  });

  // Add UTM params only to good sam
  if (betterParseInt(source) === GOODSAM_SOURCE_ID) {
    goodSamQueryParams.forEach((item) => {
      if (item[0] !== null && item[0] !== undefined && item[0] !== '') {
        urlParams += `${item[1]}=${item[0]}&`;
      }
    });
  }

  return urlParams === '' ? urlParams : `?${urlParams.replace(/&$/, '')}`;
}
