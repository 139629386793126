import { LoanPurpose, Make } from '../schema';

export type MakesModelsEntry = {
  [index: string]: Array<Make>;
};

export class MakesModelsStore {
  map: Map<string, MakesModelsEntry> = new Map();

  get(purpose: LoanPurpose, year: number): MakesModelsEntry | undefined {
    return this.map.get(`${purpose}-${year}`);
  }

  put(purpose: LoanPurpose, year: number, makesModels: MakesModelsEntry | null) {
    if (makesModels) {
      this.map.set(`${purpose}-${year}`, makesModels);
    }
  }
}

let makesModelsStore: MakesModelsStore | null = null;

export function useMakesModelsStore(): MakesModelsStore {
  if (!makesModelsStore) {
    makesModelsStore = new MakesModelsStore();
  }

  return makesModelsStore;
}
