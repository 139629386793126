import React, { useEffect } from 'react';
import { Col, Form, Input, Radio, Row, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import LoanLayout from '../LoanLayout';
import { useLoan } from '../../stores/store';
import Loading from '../Loading';
import ButtonNav from '../../components/ButtonNav/ButtonNav';
import { stepFinished } from '../../nav';
import PlaceholderLabel from '../../components/PlaceholderLabel/PlaceholderLabel';
import ManualLabel from '../../components/ManualLabel/ManualLabel';
import MoneyInput from '../../components/MoneyInput/MoneyInput';
import AppFormItem from '../components/AppFormItem';
import RateInput from '../../components/RateInput/RateInput';
import { createSchemaValidator } from '../../validators/schemaValidator';
import { formatCurrency, pushToDataLayer } from '../../utils';
import { getMinAllowedAmount } from './FinanceStep';
import { pickFields } from './meta';
import { LoanPurpose, OwnershipType } from '../../schema';
import { ReactComponent as SelectArrowIcon } from '../../icons/select-arrow-icon.svg';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING, isProduction } from '../../constants';

const formItemLayout = {
  labelCol: { span: 0 },
  wrapperCol: { span: 24 },
};

type Props = {};

const centerSmall = { xs: 24, sm: 24, md: 16, lg: 16, xl: 16 };
const centerSmallOffset = { xs: 0, sm: 0, md: 4, lg: 4, xl: 4 };

const schemaValidator = createSchemaValidator(pickFields('refinance'));
const llcSchemaValidator = createSchemaValidator(pickFields('details'));

const RefinanceStep: React.FC<Props> = observer(() => {
  const loanStore = useLoan();
  const { loan } = loanStore;
  const [form] = useForm();
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'refinance page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  if (!loan || loanStore.status === 'pending') {
    return <Loading />;
  }
  const finance = loanStore.getFinance();

  const update = () => {
    loanStore.updateFinance();
    // noinspection JSIgnoredPromiseFromCall
    stepFinished();
  };

  const handleChange = () => {
    loanStore.updateFinance();
    form.setFieldsValue(loan);
  };

  const termOptions = loanStore.getTermOptions();

  return (
    <LoanLayout>
      {isNewFunnel && <div className="step-title">Boat info</div>}
      <h1>Let's get some details about your refinance</h1>
      <Row>
        <Col {...centerSmallOffset} />
        <Col {...centerSmall}>
          <Form
            form={form}
            {...formItemLayout}
            initialValues={{
              ...finance,
              ownershipType: loan.ownershipType,
              associatedName: loan.associatedName,
            }}
            onFinish={update}
            onValuesChange={handleChange}
          >
            <AppFormItem
              className="manual-label"
              name="ownershipType"
              required
              rules={[
                llcSchemaValidator('ownershipType', loan, {
                  message: 'Please select one',
                }),
              ]}
            >
              <PlaceholderLabel label="Title Ownership:" htmlFor="ownershipType">
                <Select
                  suffixIcon={<SelectArrowIcon />}
                  showSearch={Object.keys(OwnershipType).length > 7}
                  allowClear
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {Object.values(OwnershipType)
                    .map((label) => {
                      if (label === OwnershipType.montanaLlc && loan.purpose !== LoanPurpose.rv)
                        return null;

                      return (
                        <Select.Option key={label} value={label}>
                          {label}
                        </Select.Option>
                      );
                    })
                    .filter((element) => !!element)}
                </Select>
              </PlaceholderLabel>
            </AppFormItem>

            {loan.ownershipType && loan.ownershipType !== OwnershipType.none && (
              <AppFormItem
                name="associatedName"
                rules={[llcSchemaValidator('associatedName', loan)]}
              >
                <PlaceholderLabel
                  label={loan.ownershipType === OwnershipType.trust ? 'Trust name' : 'LLC Name'}
                  htmlFor="associatedName"
                >
                  <Input />
                </PlaceholderLabel>
              </AppFormItem>
            )}
            <AppFormItem
              name="purchasePrice"
              required
              rules={[
                schemaValidator('purchasePrice', finance, {
                  message: 'Please enter the amount remaining on your existing loan',
                  transform: 'float',
                  minimum: {
                    message: `The minimum loan amount for the loan program you selected is ${formatCurrency(
                      getMinAllowedAmount(loan)
                    )}`,
                    value: getMinAllowedAmount(loan),
                  },
                }),
              ]}
            >
              <PlaceholderLabel label="Payoff Amount">
                <MoneyInput />
              </PlaceholderLabel>
            </AppFormItem>
            <AppFormItem
              name="currentLender"
              required
              rules={[
                schemaValidator('currentLender', finance, {
                  required: true,
                  message: 'Please enter the name of your current lender',
                }),
              ]}
            >
              <PlaceholderLabel label="Current Lender">
                <Input />
              </PlaceholderLabel>
            </AppFormItem>
            <AppFormItem name="currentRate">
              <PlaceholderLabel label="Current Rate">
                <RateInput />
              </PlaceholderLabel>
            </AppFormItem>
            <AppFormItem
              name="term"
              rules={[
                schemaValidator('term', finance, {
                  message: 'Please select one',
                  custom: (value: number) => {
                    const financedAmount = loanStore.financedAmount || 50000;
                    if (value > 15 && financedAmount < 50000) {
                      throw new Error('Term is invalid for this financed amount');
                    }
                  },
                }),
              ]}
              hasFeedback={false}
            >
              <ManualLabel label="Desired term (in years):">
                <Radio.Group optionType="button" options={termOptions} />
              </ManualLabel>
            </AppFormItem>
            <ButtonNav />
          </Form>
        </Col>
      </Row>
    </LoanLayout>
  );
});

export default RefinanceStep;
