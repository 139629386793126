import { Theme, Source, Loan } from '../schema';
import { betterParseInt } from './betterParseInt';
import { BOAT_TRADER_SOURCE_ID, isDev } from '../constants';
import { ConfigStore } from '../stores/config';
import { action } from 'mobx';

export const loadPrequalOverrides = (theme: Theme, sourceId: number) => {
  if (sourceId === BOAT_TRADER_SOURCE_ID) {
    action('loadPrequalOverrides', () => {
      theme.logo = '/static/trident/boattrader-trident-prequal-logo.svg';
    })();
  }
};

export const unloadPrequalOverrides = (theme: Theme, loan: Loan | null) => {
  const params = new URLSearchParams(window.location.search);
  let sourceId = betterParseInt(params.get('source'));

  if (loan) {
    sourceId = loan.source;
  }

  if (sourceId === BOAT_TRADER_SOURCE_ID) {
    action('unloadPrequalOverrides', () => {
      theme.logo = isDev()
        ? '/static/trident/boattrader-trident-logo.svg'
        : '/static/img/boattrader-trident-logo.svg';
    })();
  }
};
