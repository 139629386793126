/* eslint-disable import/no-cycle */
import { computed } from 'mobx';
import { message } from 'antd';
import { getLoanStore } from './stores/store';
import useHistory from './hooks/useHistory';
import { getUrlCurrentStep, loadKameleoonFlagsAndTests } from './utils';
import {
  ApplicantType,
  borrowerRequiresPreviousEmployer,
  EmploymentStatus,
  Loan,
  loanRequiresFinancialStatement,
  LoanType,
} from './schema';
import { useFeatureFlags as getFeatureFlags } from './hooks/useFeatureFlags';
import { ALL_ROUTES_CONTROL, processTransitionsControl } from './transitions/control';
import { ALL_ROUTES_OPTIMIZE_V1, processTransitionsOptimizeV1 } from './transitions/optimizeV1';
import { ALL_ROUTES_OPTIMIZE_V2, processTransitionsOptimizeV2 } from './transitions/optimizeV2';
import { BOAT_TRADER_SOURCE_ID } from './constants';
import { FullAppOptimizeV1Flag } from './featureFlags';

export type Route =
  | typeof ALL_ROUTES_OPTIMIZE_V1[number]
  | typeof ALL_ROUTES_OPTIMIZE_V2[number]
  | typeof ALL_ROUTES_CONTROL[number];

type Dest = { to: Route; skip: SkipStep };

const states: { [index: string]: Array<Dest> } = {};

type SkipStep = (loan: Loan) => boolean;

// always allow this transition
export const neverSkip = () => false;
export const skipCoBorrower: SkipStep = (loan) => loan.applicantType !== ApplicantType.joint;
export const skipPreviousAddress: SkipStep = (loan) =>
  loan.currentStep !== 'review' || !loan?.borrower?.previousAddress?.address1;
export const skipMailingAddress: SkipStep = (loan) =>
  loan.currentStep !== 'review' || !loan?.borrower?.mailingAddress?.address1;
export const skipForIndividual: SkipStep = (loan) =>
  loan.currentStep !== 'review' || loan.applicantType === ApplicantType.individual;
export const skipBorrowerAdditionalEmployment: SkipStep = (loan) =>
  loan.currentStep !== 'review' || !loan?.borrower?.currentEmployer2;
export const skipCoBorrowerAdditionalEmployment: SkipStep = (loan) =>
  loan.currentStep !== 'review' || !loan?.coborrower?.currentEmployer2;
export const skipEmployer: SkipStep = (loan) =>
  ![EmploymentStatus.employed, EmploymentStatus.selfEmployed].includes(
    loan.borrower?.employmentStatus || EmploymentStatus.employed
  );
export const skipPreviousEmployer: SkipStep = (loan) =>
  !borrowerRequiresPreviousEmployer(loan.borrower);
export const skipCoAddress: SkipStep = (loan) =>
  loan.currentStep !== 'review' ||
  !(loan?.coborrower?.currentAddress?.address1 || loan?.coborrower?.sharesAddress);
export const skipCoPreviousAddress: SkipStep = (loan) =>
  loan.currentStep !== 'review' || !loan?.coborrower?.previousAddress?.address1;
export const skipCoEmployer: SkipStep = (loan) =>
  ![EmploymentStatus.employed, EmploymentStatus.selfEmployed].includes(
    loan.coborrower?.employmentStatus || EmploymentStatus.employed
  );
export const skipCoPreviousEmployer: SkipStep = (loan) =>
  !borrowerRequiresPreviousEmployer(loan.coborrower);
export const skipIfRefinance: SkipStep = (loan) =>
  loan.loanType === LoanType.refinance || loan.loanType === LoanType.cashRecapture;
export const skipIfNotRefinance: SkipStep = (loan) => loan.loanType !== LoanType.refinance;
export const skipIfNotCashRecapture: SkipStep = (loan) => loan.loanType !== LoanType.cashRecapture;
export const skipIfNoFinancialStatement: SkipStep = (loan) => !loanRequiresFinancialStatement(loan);

export const combineSkip = (...skips: Array<SkipStep>) => {
  const combined: SkipStep = (loan) => {
    for (let i = 0; i < skips.length; i++) {
      if (skips[i].apply(this, [loan])) return true;
    }
    return false;
  };
  return combined;
};

export const transition = (from: Route, dest: Dest) => {
  if (states[from]) {
    states[from].push(dest);
  } else {
    states[from] = [dest];
  }
};

export const setFunnelTransitions = async () => {
  try {
    const loanStore = getLoanStore();
    await loadKameleoonFlagsAndTests(loanStore?.loan);
    const activeFeatureFlags = getFeatureFlags();
    const currentVariation =
      activeFeatureFlags[FullAppOptimizeV1Flag.name]?.currentVariation ?? 'off';

    let processTransitions;
    switch (currentVariation) {
      case 'on':
        processTransitions = processTransitionsOptimizeV1;
        break;
      case 'v2':
        processTransitions = processTransitionsOptimizeV2;
        break;
      default:
        processTransitions = processTransitionsControl;
        break;
    }
    processTransitions();
  } catch (e) {
    console.log('Error loading new funnel', e);
    processTransitionsControl();
  }
};

export const restoreTransitions = () => {
  Object.keys(states).forEach((key) => {
    delete states[key];
  });
};

export const requiredSteps = computed((): Route[] => {
  const loanStore = getLoanStore();
  if (!loanStore || !loanStore.loan || loanStore.status === 'pending') return [];

  const steps: Route[] = ['type'];
  const startSteps = Object.keys(states);

  for (let i = 0; i < startSteps.length; i++) {
    const dests = states[startSteps[i]];
    for (let j = 0; j < dests.length; j++) {
      const dest = dests[j];
      if (!dest.skip(loanStore.loan as Loan)) {
        steps.push(dest.to);
      }
    }
  }
  return steps;
});

export function getNextStep(): Route {
  const loanStore = getLoanStore();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const history = useHistory();

  if (!loanStore || !loanStore.loan || loanStore.status === 'pending') {
    throw new Error("Loan hasn't loaded yet");
  }
  const steps = requiredSteps.get();
  const currentStep = (getUrlCurrentStep(history.location.pathname) || 'type') as Route;
  if (loanStore.navDirection === 'other') {
    throw new Error('other direction');
  }
  const nextIndex = steps.indexOf(currentStep) + loanStore.navDirection;

  if (steps[nextIndex]) {
    return steps[nextIndex];
  }
  return 'submit';
}

export function gotoNextStep(history: any, nextStep: string) {
  const loanStore = getLoanStore();
  if (!loanStore) return;
  history.push(`/${loanStore.id}/${nextStep}`);
  window.scroll(0, 0);
}

export function getStepUrl(step: Route) {
  const loanStore = getLoanStore();
  if (!loanStore) return '';
  return `/${loanStore.id}/${step}`;
}

export async function gotoStep(history: any, step: Route) {
  const loanStore = getLoanStore();
  if (!loanStore) return;
  await loanStore.syncDoc(step);
  history.push(`/${loanStore.id}/${step}`);
  window.scroll(0, 0);
}

export async function stepFinished(nextStep?: Route) {
  const loanStore = getLoanStore();
  if (!loanStore) return;
  try {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();

    // trigger promise for any code waiting for save
    loanStore.saved();

    if (loanStore.navDirection === 'other') {
      return;
    }

    nextStep = nextStep || getNextStep();
    // saves the entire loan
    await loanStore.syncDoc(nextStep);
    if (loanStore?.loan?.id && loanStore?.loan?.source === BOAT_TRADER_SOURCE_ID) {
      localStorage.setItem('loanId', loanStore.loan.id);
    }
    gotoNextStep(history, nextStep);
  } catch (e: any) {
    if (loanStore?.loan?.workflow === 'submitted') {
      message.info('Application has already been submitted');
    } else {
      message.error(`Save failed: ${e.message || 'unknown error'}`);
      console.error('failed', e);
    }
  }
}
