import { Redirect, Route, Router, Switch, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import useHistory from '../hooks/useHistory';
import PurposeStep from './steps/PurposeStep';
import DetailsStep from './steps/DetailsStep';
import BorrowerStep from './steps/BorrowerStep';
import EmployerStep from './steps/EmployerStep';
import PreviousEmployerStep from './steps/PreviousEmployerStep';
import BorrowerAddressStep from './steps/BorrowerAddressStep';
import CoBorrowerEmployerStep from './steps/CoBorrowerEmployerStep';
import CoBorrowerPreviousEmployerStep from './steps/CoBorrowerPreviousEmployerStep';
import { useUser } from '../hooks/useUser';
import SellerStep from './steps/SellerStep';
import CollateralStep from './steps/CollateralStep';
import ExperienceStep from './steps/ExperienceStep';
import FinanceStep from './steps/FinanceStep';
import SubmitStep from './steps/SubmitStep';
import IncomeStep from './steps/IncomeStep';
import AssetStep from './steps/AssetStep';
import LiabilityStep from './steps/LiabilityStep';
import RealEstateStep from './steps/RealEstateStep';
import BorrowerContactStep from './steps/BorrowerContactStep';
import CoBorrowerIncomeStep from './steps/CoBorrowerIncomeStep';
import ReviewStep from './steps/ReviewStep';
import SavedStep from './components/SavedStep';
import { observer } from 'mobx-react-lite';
import SuccessStep from './components/SuccessStep';
import StatementSummaryStep from './steps/StatementSummaryStep';
import OldLinkRedirectStep from './components/OldLinkRedirectStep';
import OtherAssetStep from './steps/OtherAssetStep';
import RefinanceStep from './steps/RefinanceStep';
import CashRecaptureStep from './steps/CashRecaptureStep';
import { fetchUnfinishedLoan } from '../utils';
import useConfig from '../stores/config';
import { useLoan } from '../stores/store';
import { Spin } from 'antd';
import PageNotFound from './components/PageNotFound';
import SplashPage from './SplashPage';

type Props = {
  transtionsLoaded?: boolean;
};

export default observer(function LoanIndex({ transtionsLoaded = false }: Props) {
  const history = useHistory();
  const user = useUser();
  const location = useLocation();
  const loanStore = useLoan();

  useEffect(() => {
    const unfinishedLoan = fetchUnfinishedLoan();
    const loanId = unfinishedLoan?.loanId;
    const currentStep = loanStore?.loan?.currentStep;
    const params = new URLSearchParams(window.location.search);
    const source = params.get('source');

    const refinedParams = new URLSearchParams(params.toString());
    refinedParams.delete('loanId');
    refinedParams.delete('currentStep');

    if (currentStep && loanId) {
      history.push(`/${loanId}/${currentStep}?${refinedParams}`);
    } else if (!source) {
      localStorage.removeItem('loanId');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanStore.loan]);

  // preload sources
  const config = useConfig();
  if (!user || !config) {
    return <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />;
  }

  return (
    <>
      {transtionsLoaded && (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            classNames="fade"
            timeout={250}
            unmountOnExit
            mountOnEnter={false}
          >
            <Router history={history}>
              <div>
                <Switch location={location}>
                  <Route path="/" exact>
                    <PurposeStep />
                  </Route>
                  <Route path="/boat-loans/apply" exact>
                    <PurposeStep />
                  </Route>
                  <Route path="/entry" exact>
                    <SplashPage />
                  </Route>
                  <Route path="/:loanId/type">
                    <PurposeStep />
                  </Route>
                  <Route path="/:loanId/details">
                    <DetailsStep />
                  </Route>
                  <Route path="/:loanId/seller">
                    <SellerStep />
                  </Route>
                  <Route path="/:loanId/collateral">
                    <CollateralStep />
                  </Route>
                  <Route path="/:loanId/finance">
                    <FinanceStep />
                  </Route>
                  <Route path="/:loanId/refinance">
                    <RefinanceStep />
                  </Route>
                  <Route path="/:loanId/cash-recapture">
                    <CashRecaptureStep />
                  </Route>
                  <Route path="/:loanId/experience">
                    <ExperienceStep />
                  </Route>
                  <Route path="/:loanId/trade">
                    <Redirect to="collateral" />
                  </Route>
                  <Route path="/:loanId/borrower">
                    <BorrowerStep />
                  </Route>
                  <Route path="/:loanId/contact">
                    <BorrowerContactStep />
                  </Route>
                  <Route path="/:loanId/address">
                    <BorrowerAddressStep />
                  </Route>
                  <Route path="/:loanId/previous-address">
                    <Redirect to="address" />
                  </Route>
                  <Route path="/:loanId/mailing-address">
                    <Redirect to="address" />
                  </Route>
                  <Route path="/:loanId/income">
                    <IncomeStep />
                  </Route>
                  <Route path="/:loanId/assets">
                    <AssetStep />
                  </Route>
                  <Route path="/:loanId/other-assets">
                    <OtherAssetStep />
                  </Route>
                  <Route path="/:loanId/liabilities">
                    <LiabilityStep />
                  </Route>
                  <Route path="/:loanId/real-estate">
                    <RealEstateStep />
                  </Route>
                  <Route path="/:loanId/statement-summary">
                    <StatementSummaryStep />
                  </Route>
                  <Route path="/:loanId/employer">
                    <EmployerStep />
                  </Route>
                  <Route path="/:loanId/employer2">
                    <Redirect to="employer" />
                  </Route>
                  <Route path="/:loanId/previous-employer">
                    <PreviousEmployerStep />
                  </Route>
                  <Route path="/:loanId/coborrower">
                    <Redirect to="borrower" />
                  </Route>
                  <Route path="/:loanId/coborrower-contact">
                    <Redirect to="borrower" />
                  </Route>
                  <Route path="/:loanId/coborrower-address">
                    <Redirect to="address" />
                  </Route>
                  <Route path="/:loanId/coborrower-previous-address">
                    <Redirect to="address" />
                  </Route>
                  <Route path="/:loanId/coborrower-income">
                    <CoBorrowerIncomeStep />
                  </Route>
                  <Route path="/:loanId/coborrower-employer">
                    <CoBorrowerEmployerStep />
                  </Route>
                  <Route path="/:loanId/coborrower-employer2">
                    <Redirect to="coborrower-employer" />
                  </Route>
                  <Route path="/:loanId/coborrower-previous-employer">
                    <CoBorrowerPreviousEmployerStep />
                  </Route>
                  <Route path="/:loanId/review">
                    <ReviewStep sources={config.sources} />
                  </Route>
                  <Route path="/:loanId/submit">
                    <SubmitStep />
                  </Route>
                  <Route path="/:loanId/saved">
                    <SavedStep />
                  </Route>
                  <Route path="/:loanId/success">
                    <SuccessStep salesReps={config.salesReps} />
                  </Route>
                  <Route path="/Loan/Index">
                    <OldLinkRedirectStep salesReps={config.salesReps} />
                  </Route>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </div>
            </Router>
          </CSSTransition>
        </TransitionGroup>
      )}
    </>
  );
});
