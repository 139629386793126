import React from 'react';
import { Footer } from 'antd/es/layout/layout';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../../stores/store';
import {
  fetchRepidFax,
  fetchRepidPhone,
  renderFaxNumber,
  renderMainNumber,
  renderNumber,
} from './PhoneNumbers';

import styles from './AppFooter.module.scss';
import LockIcon from '../../../icons/LockIcon';
import { Loan, LoanPurpose, Source } from '../../../schema';
import { generateUrlParams } from '../../../utils';
import useConfig from '../../../stores/config';
import getSource from '../../../utils/getSource';
import { OPERATION_HOURS_DEFAULT, BOAT_TRADER_SOURCE_ID } from '../../../constants';

export const AppFooter = observer(function AppFooter() {
  const config = useConfig();
  const loanStore = useLoan();
  const { loan } = loanStore;
  const urlQueryParams = generateUrlParams(null);

  const { source } = getSource(config, loan as Loan);

  const mainPhone = fetchRepidPhone(loan?.repCode, config?.salesReps);

  const operationHours = source?.operationHoursMarine || OPERATION_HOURS_DEFAULT;

  const year = new Date().getFullYear();

  return (
    <Footer className={styles.footer}>
      <div className={styles.footerWrap}>
        <div className={styles.columnsWrap}>
          <div className={styles.left}>
            <p>
              <span className={styles.secured}>
                <LockIcon width="0.8em" height="0.8em" fill="#2566B0" />
                Secured Connection
              </span>
              <span className={styles.copy} id="footer-copy">
                © {year} {source?.displayName || 'Trident Funding LLC'}
              </span>
            </p>
            <p>
              <a
                href={`https://www.tridentfunding.com/about-us/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="about-us"
              >
                About
              </a>
              <a
                href={`https://www.tridentfunding.com/faq-marine/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="faq"
              >
                FAQ
              </a>
              <a
                href="https://www.tridentfunding.com/wp-content/uploads/2022/03/Trident-Funding-Privacy-Policy-Boats-Group-12-2021.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Consumer Privacy Policy
              </a>
              <a
                href={`https://www.tridentfunding.com/privacy-policy/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="privacy-policy"
              >
                Privacy Policy
              </a>
              <a
                href={`https://www.tridentfunding.com/terms-of-use/${urlQueryParams}`}
                target="_blank"
                rel="noreferrer"
                data-testid="terms-of-use"
              >
                Terms of Use
              </a>
            </p>
          </div>
          <div className={styles.space} />
          <div className={styles.right}>
            <div className={styles.needHelpTop}>
              <span className={styles.needHelp}>Need Help?</span>
              <div className={styles.phoneContainer}>
                <div className={styles.phones}>
                  {renderMainNumber(loan?.purpose, source, mainPhone)}
                  {renderNumber({ number: source?.secondaryPhone })}
                  {renderNumber({ number: source?.ttyNumber, label: 'TTY' })}
                  {renderFaxNumber(
                    loan?.purpose,
                    source,
                    fetchRepidFax(loan?.repCode, config?.salesReps, config?.offices)
                  )}
                </div>
              </div>
            </div>
            <p>{operationHours}</p>
          </div>
        </div>
      </div>
    </Footer>
  );
});
