import React, { useContext } from 'react';
import {
  getDownloadURL,
  listAll,
  getMetadata,
  FirebaseStorage,
  StorageReference,
  FullMetadata,
} from 'firebase/storage';

export const FirestoreStorageContext = React.createContext<null | FirebaseStorage>(null);

export type TridentStorageObject = {
  downloadUrl: string;
  storedObject: StorageReference;
  metadata: FullMetadata;
};

const getFirestoreStorageObjects = async ({
  filter = null,
  storageRefLocation,
}: {
  filter?: ((metadata: FullMetadata) => boolean) | null;
  storageRefLocation: StorageReference;
}) => {
  try {
    const allStoredObjects = await listAll(storageRefLocation);
    const relevantObjects: TridentStorageObject[] = [];
    let evaluator = (_metadata: FullMetadata) => true;

    if (filter) {
      evaluator = filter;
    }

    await Promise.all(
      allStoredObjects.items.map(async (storedObject) => {
        const metadata = await getMetadata(storedObject);
        if (evaluator(metadata)) {
          const url = await getDownloadURL(storedObject);
          relevantObjects.push({ downloadUrl: url, storedObject, metadata });
        }
      })
    );

    return relevantObjects;
  } catch (e) {
    console.log('Unable to fetch stored objects', e);
  }
};

export function useFirestoreStorage() {
  const firestoreStorage = useContext(FirestoreStorageContext);
  if (firestoreStorage === null) throw new Error('missing firestore storage');
  return {
    storage: firestoreStorage,
    getFirestoreStorageObjects,
  };
}
