import React from 'react';
import { FormItemProps } from 'antd';

export const FormItemContext = React.createContext<null | FormItemProps>(null);

export function useFormItem(): FormItemProps {
  const formItem = React.useContext(FormItemContext);
  if (formItem === null) throw new Error('missing form item');
  return formItem;
}
