import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import BlankLayout from '../BlankLayout';
import { useLoan } from '../../stores/store';
import { Loan, LoanType, SalesRep, SalesRepsByCode, Source } from '../../schema';
import useConfig from '../../stores/config';
import getSource from '../../utils/getSource';
import styles from '../step.module.scss';
import { useFeatureFlag } from '../../hooks/useFeatureFlag';
import { FF_FULL_APPLICATION_STYLING, isProduction } from '../../constants';
import { pushToDataLayer } from '../../utils';

type Props = {
  salesReps: SalesRepsByCode;
};

const purchaseAgreementRVLink =
  'https://www.tridentfunding.com/wp-content/uploads/2022/03/RV-Purchase-Agreement.pdf';

const purchaseAgreementMarineLink =
  'https://www.tridentfunding.com/wp-content/uploads/2022/03/Marine-Purchase-Agreement.pdf';

const getBody = ({
  loan,
  isPurchase,
  salesRep,
  source,
}: {
  loan: Loan | null;
  isPurchase: boolean;
  salesRep: SalesRep | null;
  source: Source | null;
}) => (
  <>
    <p>
      Thank you for submitting your application for credit to{' '}
      {!isPurchase ? 'refinance' : 'purchase'} your {loan?.collateral?.year}{' '}
      {loan?.collateral?.manufacturer} {loan?.collateral?.model} {loan?.collateral?.modelNumber}.
    </p>
    <p>Your online session ID: {loan?.id}</p>
    {salesRep && (
      <p>
        Your application has been assigned to: {salesRep.first} {salesRep.last}
        <br />
        Phone: {salesRep.phone}
        <br />
        Email: {salesRep.email}
      </p>
    )}
    <h2>Next Steps:</h2>
    <p>
      Only upon request, please be prepared to provide the following supporting documents to verify
      income:
    </p>
    <ul>
      <li>
        If wage employee, year to date pay stub and/or most recent W-2 & two (2) years 1040 tax
        returns with all schedules and statements.
      </li>
      <li>
        If self-employed, most recent two (2) years personal federal tax returns including all
        schedules & two (2) years business returns including all schedules & K-1's, if applicable.
      </li>
      <li>
        If retired, Social Security, Retirement, and/or Disability Benefit Award letter, current
        pension statement, annuity statement, investment and/or deposit account statements.
      </li>
      <li>Completed Personal Financial Statement</li>
    </ul>
    <h2>Documentation needed prior to loan closing:</h2>
    {isPurchase && (
      <ul>
        <li>Copy of your current driver’s license</li>
        <li>
          Copy of purchase agreement (
          <a
            className={styles.link}
            href={purchaseAgreementRVLink}
            target="_blank"
            rel="noreferrer"
          >
            click here to download a generic template
          </a>
          )
        </li>
        <li>Copy of down payment check (if applicable)</li>
        <li>Proof of insurance binder</li>
        <li>Sellers pay off letter (if applicable)</li>
        <li>Copy of seller’s current title and/or registration</li>
      </ul>
    )}
    {!isPurchase && (
      <ul>
        <li>Copy of your current driver’s license</li>
        <li>Copy of your current registration</li>
        <li>Proof of insurance required prior to loan funding</li>
        <li>Payoff letter from current lender</li>
      </ul>
    )}
    <h3>An inspection maybe required:</h3>
    <p>
      Trident Funding will arrange for an inspection through an independent 3rd party vendor who
      will contact the seller to set a date and time to complete a visual non-mechanical inspection.
    </p>
    <h3>At Loan Closing:</h3>
    <p>Trident Funding will email closing documents</p>
    <ul>
      <li>Print</li>
      <li>Sign and notarize</li>
      <li>Return via prepaid label</li>
      <li>Loan funded within 24 - 48 hours of receiving complete loan package</li>
    </ul>
    {isPurchase && (
      <>
        <h3>Post Funding:</h3>
        <p>
          Trident Funding will complete title and registration through an independent 3rd party
          vendor for private party and out of state dealer transactions.
        </p>
        <ul>
          <li>Original title to be sent to Trident Funding</li>
          <li>Additional documents to complete registration process</li>
        </ul>
      </>
    )}
    <p>
      Thank you for choosing {source?.displayName || 'Trident Funding LLC'} for your {loan?.purpose}{' '}
      financing needs.
    </p>

    <p>{source?.displayName || 'Trident Funding LLC'}</p>
  </>
);

const getMarineBody = () => (
  <div className={styles.successStepMarineBodyContainer}>
    <h1 className={styles.successStepMarineHeader}>
      Congratulations! Your application has been submitted
    </h1>
    <div>
      <p className={styles.successStepMarineSubHeader}>Next Steps:</p>
      <ol className={styles.successStepNextList}>
        <li>
          One of our seasoned loan officers will review your application and determine which of our
          lenders best meets your needs.{' '}
        </li>
        <li>
          A loan officer on our team will reach out to you within the next 2 business days to
          discuss your final rate and terms.
        </li>
        <li>We'll let you know which documents are required to secure your loan.</li>
        <li>
          We'll determine if an inspection is needed. If so, we can easily arrange an inspection
          through a trusted 3rd party inspector for you or you can provide the necessary inspection
          documentation.
        </li>
      </ol>
      <p className={styles.successStepMarineFooterText}>
        Rest assured, we’re here to make this process smooth sailing.
      </p>
    </div>
  </div>
);

const SuccessStep: React.FC<Props> = observer(function MyApps({ salesReps }) {
  const loanStore = useLoan();
  const config = useConfig();
  const { loan } = loanStore;
  const { source } = getSource(config, loanStore.loan as Loan);
  const isPurchase = loan?.loanType !== LoanType.refinance;
  const isNewFunnel = useFeatureFlag({
    flag: FF_FULL_APPLICATION_STYLING,
  });

  let salesRep: SalesRep | null = null;
  if (loan?.repCode) {
    salesRep = salesReps[loan.repCode];
  }

  useEffect(() => {
    pushToDataLayer('pageview', {
      portal: 'trident funding',
      site_section: 'loan application',
      detailed_page_name: 'success page',
      environment: isProduction() ? 'production' : 'qa',
    });
  }, []);

  return (
    <BlankLayout title={loan?.purpose === 'Boat' ? "What's Next" : "We're on it"}>
      {isNewFunnel && <div className="step-title">All done</div>}
      {loan?.purpose === 'Boat' ? getMarineBody() : getBody({ loan, isPurchase, salesRep, source })}
    </BlankLayout>
  );
});

export default SuccessStep;
