import React from 'react';
import { Col, Layout, Row, Spin, Space, Button } from 'antd';
import { Content, Header } from 'antd/es/layout/layout';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../stores/store';
import { usePrequal } from '../stores/prequalStore';
import { DownOutlined, UserOutlined, ClockCircleOutlined, LockOutlined } from '@ant-design/icons';

import styles from './prequal.module.scss';
import { generateUrlParams, getPrequal, getPrequalSource, loadPrequalOverrides } from '../utils';
import { AppFooter } from './components/AppFooter/AppFooter';
import useConfig from '../stores/config';
import { useTheme } from '../hooks/useTheme';
import Logo from '../loan/components/Logo';

type Props = {
  children: React.ReactNode;
  className?: string;
};

export default observer(function PrequalLayout({ children, className }: Props) {
  const loanStore = useLoan();
  const config = useConfig();
  const prequalStore = usePrequal();
  const { prequal } = prequalStore;
  const urlQueryParams = generateUrlParams(null);
  const theme = useTheme(loanStore, config, true);

  if (theme == null) {
    return (
      <Layout className={styles.mainLayout}>
        <Spin size="large" style={{ marginTop: '2rem', display: 'block' }} />
      </Layout>
    );
  }

  const sourceId = getPrequalSource(config, prequal);
  loadPrequalOverrides(theme, sourceId);

  return (
    <Layout className={styles.mainLayout}>
      <Header className={styles.header}>
        <Row className={styles.headerRow}>
          <Col>
            <Logo
              imgSrc={theme.logo}
              linkUrl={theme.marketingWebsiteUrl}
              urlQueryParams={urlQueryParams}
            />
          </Col>
        </Row>
      </Header>
      <Header className={styles.subheader}>
        <Row className={styles.subheaderRow}>
          <Col className={styles.subheaderCol}>
            <Space>
              <ClockCircleOutlined className={styles.subheaderIcon} />
              <span>Quick 3 min process</span>
            </Space>
          </Col>
          <Col className={styles.subheaderCol}>
            <Space>
              <img src="/static/trident/shield-icon.svg" className={styles.subheaderIcon} alt="" />
              <span>No spam from lenders</span>
            </Space>
          </Col>
          <Col className={styles.subheaderCol}>
            <Space>
              <LockOutlined className={styles.subheaderIcon} />
              <span>Secure Encryption</span>
            </Space>
          </Col>
        </Row>
      </Header>
      <Content className={classNames(styles.content, className)}>
        <div className="form-container">{children}</div>
      </Content>
      <AppFooter />
    </Layout>
  );
});
