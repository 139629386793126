import { DEFAULT_THEME, THEME_CSS_VARS_RELATION } from '../constants';
import { Theme, ThemeColors } from '../schema';
import './theme.module.scss';

export const setThemeProperties = (theme: Theme) => {
  THEME_CSS_VARS_RELATION.forEach(([cssVarName, themeVarName]) => {
    document.documentElement.style.setProperty(
      `--${cssVarName}`,
      theme.colors[themeVarName as keyof ThemeColors] ||
        DEFAULT_THEME.colors[themeVarName as keyof ThemeColors]
    );
  });
};
