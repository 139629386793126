import firebase from 'firebase/compat/app';
import { useEffect, useState } from 'react';
import { useAuth } from './useAuth';
import { useLoan } from '../stores/store';
import useHistory from './useHistory';

let createdAnon = false;

export function useUser() {
  const [user, setUser] = useState<null | firebase.User>(null);
  const auth = useAuth();
  const loanStore = useLoan();
  const history = useHistory();

  useEffect(() => {
    auth.onAuthStateChanged((cuser: firebase.User | null) => {
      if (!cuser && history.location.pathname !== '/logout') {
        if (createdAnon) return;
        createdAnon = true;
        auth.signInAnonymously();
        loanStore.setUserAdmin(false);
      } else {
        setUser(cuser);
        cuser?.getIdTokenResult().then((value) => {
          loanStore.setUserAdmin(value.claims.admin === true); // may be undefined
        });
      }
    });
  }, [auth, loanStore, history.location.pathname]);

  return user;
}

export function disableAnonCreation() {
  createdAnon = true;
}
