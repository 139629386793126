import React, { useEffect, useState } from 'react';
import firebase from 'firebase/compat/app';
import { Button } from 'antd';
import { observer } from 'mobx-react-lite';
import { useLoan } from '../../stores/store';
import AppFormItem from '../../loan/components/AppFormItem';
import { useUser } from '../../hooks/useUser';
import { gotoStep, stepFinished } from '../../nav';
import useHistory from '../../hooks/useHistory';
import { pushToDataLayer, getStepLabel, getCookie } from '../../utils';
import { useAuth } from '../../hooks/useAuth';

const SaveButton: React.FC = observer(() => {
  const loanStore = useLoan();
  const user = useUser();
  const history = useHistory();
  const auth = useAuth();

  useEffect(() => {
    auth.onAuthStateChanged((cuser: firebase.User | null) => {
      if (cuser) {
        loanStore.setValue('userId', cuser.uid);
        cuser?.getIdTokenResult().then((value) => {
          loanStore.setUserAdmin(value.claims.admin === true);
        });
      }
    });
  }, [auth, loanStore]);

  return (
    <AppFormItem name="save" wrapperCol={{ offset: 0, span: 24 }}>
      <Button
        name="save"
        type="default"
        htmlType="button"
        onClick={() => {
          loanStore.navDirection = 'other';
          const kameleoonVisitorCode = getCookie('kameleoonVisitorCode');
          if (kameleoonVisitorCode) {
            loanStore.setValue('visitorCode', kameleoonVisitorCode);
          }
          loanStore.onSave.then(async () => {
            if (user?.isAnonymous) {
              await loanStore.showAuthModal();
            }
            const { loan } = loanStore;
            if (loan) {
              pushToDataLayer('e_applicationAction', {
                application_category: 'financing application',
                application_action: 'save',
                application_label: getStepLabel(loan.currentStep),
                loan_purpose: loan.purpose.toLowerCase(),
              });
            }
            await gotoStep(history, 'saved');
          });
          stepFinished();
          return true;
        }}
      >
        Save for Later
      </Button>
    </AppFormItem>
  );
});

export default SaveButton;
